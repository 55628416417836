import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from "react-redux";
import {closeSnackbar} from '../../features/login/loginSlice'
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
export default function CustomizedSnackbars(props) {
  console.log('rajesh')
  // const dispatch = useDispatch();
  const [openSnackBar, setOpenSnackBar] = React.useState(false)
  React.useEffect(() => {
    setOpenSnackBar(props.condition)
  },[props.condition])
  React.useEffect(() => {
    if(props.condition){
      setTimeout(() => {
        setOpenSnackBar(false)
        // dispatch(closeSnackbar(false))
      },3000)
    }
  },[props.condition])
    {console.log('snackprops',props)}
    return (

        <>
          <Snackbar open={openSnackBar} autoHideDuration={3000} onClose={()=>console.log('test')}>
             <Alert onClose={()=>setOpenSnackBar(false)} severity={props.type} sx={{ width: '100%' }}>
                 {props.message}
             </Alert>
         </Snackbar></>
    )
}