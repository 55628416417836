import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import '../common-dashboard-panel/dashboard-panel.css';

const DashboardPanel = (props) => {
    return(
        <>
            <div>
                <div>
                    <Card className='brd-rad ds-box-shadow cursor-pointer' style={{background : `${props.panelColor}`}} sx={{ minWidth: 300 }} onClick = {props.clickEvent}>
                        <CardContent className='icn-color icn-size panel-alignment' style={{height : "170px"}}>
                            {props.icon}
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <p className='ds-text-content text-capitalize'>{props.panelDescription}</p>
                </div>
            </div>
        </>
    )
}

export default DashboardPanel