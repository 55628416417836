import React, { useEffect, useState } from 'react';

import { Routes, BrowserRouter, Route, useLocation } from 'react-router-dom';
import Login from './Container/loginContainer/LoginContainer'
import Dashboard from './Container/DashboardContainer/Dashboard';
import TeamManagement from './Container/TeamManagementContainer/TeamManagement';
import Teams from './Container/TeamContainer/Teams';
import LayoutWrapper from './components/common-layout-wrapper/LayoutWrapper';
import IssueSurvey from './Container/IssueSurveyContainer/IssueSurvey';
import Settings from './Container/settingsContainer/Settings';
import Preference from './Container/PreferenceContainer/Preference';
import Status from './Container/StatusContainer/statusContainer'
import Questionnaire from './Container/QuestionnaireContainer/Questionnaire';
import SurveyQuestionContainer from './Container/SurveyQuestionContainer/SurveyQuestions';
import CompletedSurveyContainer from './Container/CompletedSurveyContainer/CompletedSurveyContainer';
import ForGotPsd from './Container/forgetPsdContainer/forgetPsd'
export default function App() {
  return (

    <BrowserRouter>
      <LayoutWrapper>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/teamManagement" element={<TeamManagement/>} />
          <Route path="/teams" element={<Teams/>}/>
          <Route path="/issuesurvey" element={<IssueSurvey/>} />
          <Route path="/settings" element={<Settings/>}/>
          <Route path="/preference" element={<Preference/>}/>
          <Route path="/status" element={<Status/>}/>
          <Route path="/surveyForm" element = {<Questionnaire/>}/>
          <Route path='/surveyQuestions' element = {<SurveyQuestionContainer/>}/>
          <Route path="/surveyCompleted" element={<CompletedSurveyContainer/>}/>
          {/* <Route path="/pdf" element = {<PdfReport/>}/> */}
          <Route path="/forgotPsd" element = {<ForGotPsd/>}/>
        </Routes>
      </LayoutWrapper>
    </BrowserRouter>

  )
}