import React from 'react';
import DialogLayoutWrapper from '../Dialog-layout-wrapper/DialogLayoutWrapper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from "react-redux";
import { firstName,surName,email,addNewMemServer,tableCheckedId } from '../../features/teamManagement/teamManagementSlice';
import { teamsMemServer } from '../../features/teams/teamsSlice';
const AddTeamDialog = (props) => {
    const dispatch = useDispatch();
    const { usertoken } = useSelector((store) => store.login);
    const {firstNameValue,surNameValue,emailValue,tableCheckedId} = useSelector((store)=>store.teamManageMent)
    console.log('store.teamManageMent',useSelector((store)=>store.teamManageMent))
    return (
        <>
            <DialogLayoutWrapper  open={props.open} title={props.title} btnNameOne={props.btnNameOne} clickBtnNameOne={() =>dispatch(addNewMemServer({firstNameValue,surNameValue,emailValue,usertoken,tableCheckedId})).then(()=>{
                dispatch(teamsMemServer({ usertoken, tableCheckedId })).then(() => {
                    props.close()
                })
                
            })} clickBtnNameTwo={props.clickBtnNameTwo} btnNameTwo={props.btnNameTwo} close={props.close}>
                <Grid container rowSpacing={3}>
                    {/* <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                        <label for = "id" className="text-capitalize">team id</label>
                                    </Grid>
                                    <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                        <TextField id="id" label="team-id" variant="outlined" />   
                                    </Grid>
                                </Grid>
                            </Grid> */}
                    <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                <label for="name" className="text-capitalize">First Name</label>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
                                <TextField size="small" value={firstNameValue} onChange={(x) => dispatch(firstName(x.target.value))}
                                    className='ipt-size' id="name" label="" variant="outlined" />
                            </Grid>
                            <Grid style={{marginTop:15}}  item xs={12} sm={12} md={4} lg={3} xl={2}>
                                <label for="name" className="text-capitalize">Surname</label>
                            </Grid>
                            <Grid style={{marginTop:15}} item xs={12} sm={12} md={8} lg={9} xl={10}>
                                <TextField size="small" value={surNameValue} onChange={(x) => dispatch(surName(x.target.value))}
                                    className='ipt-size' id="name" label="" variant="outlined" />
                            </Grid>
                            <Grid style={{marginTop:15}} item xs={12} sm={12} md={4} lg={3} xl={2}>
                                <label for="name" className="text-capitalize">Email</label>
                            </Grid>
                            <Grid style={{marginTop:15}} item xs={12} sm={12} md={8} lg={9} xl={10}>
                                <TextField size="small" value={emailValue} onChange={(x) => dispatch(email(x.target.value))}
                                    className='ipt-size' id="name" label="" variant="outlined" />
                            </Grid>
                        </Grid>
                    </Grid>
                  
                </Grid>
            </DialogLayoutWrapper>
        </>
    )
}

export default AddTeamDialog