import React, {useState} from 'react';
import PasswordChecklist from "react-password-checklist"
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import DialogLayoutWrapper from '../../components/Dialog-layout-wrapper/DialogLayoutWrapper';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from "react-redux";

const ChangePasswordDialog = (props) => {
    const { email,name,accInforUserName,lastLoggedOn } = useSelector((store) => store.login);

    const [password, setPassword] = useState("")
	const [passwordAgain, setPasswordAgain] = useState("")
    const [values, setValues] = React.useState({
        oldPassword:'',
        showOldPassword:false,
        password: '',
        showPassword: false,
        againPassword: '',
        showAgainPassword : false
    });
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
    };
    const handleClickShowOldPassword = () => {
        setValues({
          ...values,
          showOldPassword: !values.showOldPassword,
        });
    };

    const handleClickShowAgainPassword = () => {
        setValues({
            ...values,
            showAgainPassword : !values.showAgainPassword
        })
    }
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    return(
        <>
                            <DialogLayoutWrapper open={props.open} title = {"change password"} show = {true} btnNameOne = {"update"} clickBtnNameOne = {()=>props.clickBtnNameOne(values.oldPassword,values.password,values.againPassword)} clickBtnNameTwo = {props.clickBtnNameTwo} btnNameTwo = {"cancel"} close={props.close}>
                            <Grid container rowSpacing={3}>
                                <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {3}>
                                            <label for = "id" className="text-capitalize">user name*</label>
                                        </Grid>
                                        <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {9}>
                                            <TextField value={name} size="small" className='ipt-size txt-height' autoComplete='off' id="id"  variant="outlined" />   
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {3}>
                                            <label for = "name" className="text-capitalize">Old password*</label>
                                        </Grid>
                                        <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {9}>
                                            <FormControl className='ipt-size' variant="outlined">
                                            <OutlinedInput
                                                size = "small"
                                                // className="inpt-height rm-brdr"
                                                id="outlined-adornment-password"
                                                type={values.showOldPassword ? 'text' : 'password'}
                                                value={values.oldPassword}
                                                onChange={handleChange('oldPassword')}
                                                autoComplete='off'
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowOldPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {values.showOldPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                            />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                    <Grid container alignItems={"start"}>
                                        <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {3}>
                                            <label for = "name" className="text-capitalize">New password*</label>
                                        </Grid>
                                        <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {9}>
                                            <FormControl className='ipt-size' variant="outlined">
                                            <OutlinedInput
                                                size = "small"
                                                // className="inpt-height rm-brdr"
                                                id="outlined-adornment-password"
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                onChange={handleChange('password')}
                                                autoComplete='off'
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                            />
                                            </FormControl>
                                            <PasswordChecklist
                                                rules={["minLength","specialChar","number","capital","match"]}
                                                minLength={5}
                                                value={values.password}
                                                valueAgain={values.againPassword}
                                                onChange={(isValid) => {}}
                                            />  
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {3}>
                                            <label for = "description" className="text-capitalize">Confirm password*</label>
                                        </Grid>
                                        <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {9}>
                                            <FormControl className='ipt-size' variant="outlined">
                                            <OutlinedInput
                                                autoComplete='off'
                                                size = "small"
                                                // className="inpt-height rm-brdr"
                                                id="outlined-adornment-password"
                                                type={values.showAgainPassword ? 'text' : 'password'}
                                                value={values.againPassword}
                                                onChange={handleChange('againPassword')}
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowAgainPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {values.showAgainPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                            />
                                            </FormControl>
                                            {/* <PasswordChecklist
                                                rules={["minLength","specialChar","number","capital","match"]}
                                                minLength={5}
                                                value={values.password}
                                                valueAgain={values.againPassword}
                                                onChange={(isValid) => {}}
                                            /> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                </DialogLayoutWrapper>
        </>
    )
}

export default ChangePasswordDialog