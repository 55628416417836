import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    templateName : '',
    mailSubject : '',
    mailMessage : '',
    templateActive : 'Y',
    isLoading : false,
    messageCondition : false,
    message : '',
    messageType : '',
    criteria : '%',
    allTemplate : [],
    editTemplateName : '',
    editTemplateSubject : '',
    editTemplateMessage : '',
    editTemplateActive : 'Y'
}

export const addTemplateServer = createAsyncThunk(
    "settings/addTemplateServer",
    async(params) => {
        try{
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/AddTemplateJSON?Token='+params.usertoken+'&Name='+params.templateName+'&Subject='+params.mailSubject+'&Active='+params.status+'&Message='+params.mailMessage)
            const data = await response.json()
            return data
        }catch(err){
            return err
        }
    }   
)
export const changePasswordServer = createAsyncThunk(
    "settings/changePasswordServer",
    async(params) => {
        try{
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/ChangePasswordJSON?Token='+params.usertoken+'&UserName='+params.name+'&CurrentPassword='+params.oldPsd+'&NewPassword='+params.newPsd)
            const data = await response.json()
            return data
        }catch(err){
            return err
        }
    }   
)

export const updateTemplateServer = createAsyncThunk(
    "settings/updateTemplateServer",
    async(params)=>{
        try{
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/UpdateTemplateJSON?Token='+params.usertoken+'&Name='+params.editTemplateName+'&Subject='+params.editTemplateSubject+'&Active='+params.status+'&Message='+params.editTemplateMessage)
            const data = await response.json()
            return data
        }catch(err){
            return err
        }
    }
)

export const listAllTemplatesServer = createAsyncThunk(
    "settings/listAllTemplateServer",
    async(params)=>{
        try{
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/TemplatesJSON?Token='+params.usertoken+'&Criteria='+(params.criteria == '' ? '%' : params.criteria));
            const data = await response.json()
            return data   
        }
        catch(err){
            return err
        }
    }
)

const settingsSlice = createSlice({
    name : 'settings',
    initialState,
    reducers : {
        setTemplateName : (state, action) => {
            state.templateName = action.payload
        },
        requiredPsdField:(state,action)=>{
            state.messageCondition=true
            state.message=action.payload
            state.messageType='error'
            
        },
        closeSnackBar:(state,action)=>{
            state.messageCondition=false
            state.message=''
            state.messageType=''
        },
        setMailSubject : (state, action) => {
            state.mailSubject = action.payload
        },
        setMailMessage : (state, action) => {
            state.mailMessage = action.payload
        },
        setTemplateActive : (state, action) => {
            state.templateActive = action.payload
        },
        setCriteria : (state, action) => {
            state.criteria = action.payload
        },
        setEditTemplateName : (state, action) => {
            state.editTemplateName = action.payload
        },
        setEditTemplateSubject : (state, action) => {
            state.editTemplateSubject = action.payload
        },
        setEditTemplateMessage : (state, action) => {
            // let message = action.payload.replace(/\n\r?/g, '<br />');
            state.editTemplateMessage = action.payload
        },
        setEditTemplateActive : (state, action) => {
            state.editTemplateActive = action.payload
        }
    },
    extraReducers : {
        [addTemplateServer.pending] : (state, action) => {
            state.isLoading = true
            state.messageCondition = false
            state.messageType = ''
            state.message = ''
        },
        [addTemplateServer.fulfilled] : (state, action) => {
            console.log("action for payload",action, Object.keys(action.payload))
            state.isLoading = false
            console.log()
            if(Object.keys(action.payload)[0] == 'Result'){
                state.messageCondition = true
                state.messageType = 'success'
                state.message = 'Template added successfully'
            }
            else{
                state.messageCondition = true
                state.messageType = 'error'
                state.message = 'Failed to add template '
            }
            console.log("action to check from template",action)
        },
        [addTemplateServer.rejected] : (state, action) => {
            state.isLoading = false
            state.messageCondition = true
            state.messageType = 'error'
            state.message = 'Failed to add template'
        },
        [updateTemplateServer.pending] : (state, action) => {
            state.isLoading = true
        },
        [updateTemplateServer.fulfilled] : (state, action) => {
            state.isLoading = false
            state.messageCondition = true
            state.messageType = 'success'
            state.message = action.payload.Result
            setTimeout(() => {
                state.messageCondition = false
            },1000)
        },
        [updateTemplateServer.rejected] : (state, action) => {
            state.isLoading = false
            state.messageCondition = true
            state.messageType = 'error'
            state.message = action.payload.Result
            setTimeout(() => {
                state.messageCondition = false
            },1000)
        },

        [changePasswordServer.pending] : (state, action) => {
            state.messageCondition=false
        },
        [changePasswordServer.fulfilled] : (state, action) => {
            state.messageCondition=true
            state.messageType='success'
            state.message=action.payload.Result
        },
        [changePasswordServer.rejected] : (state, action) => {
            state.messageCondition=false
        },
        [listAllTemplatesServer.pending] : (state, action) => {
            state.isLoading = true
        },
        [listAllTemplatesServer.fulfilled] : (state, action) => {
            state.isLoading = false
            state.allTemplate = action.payload['Result']['Templates'].map((x,index)=>{
                return(
                    {
                        id : index,
                        templateId : x['Template_Id'],
                        subject : x['Subject'],
                        subjectMessage : x['Email_Message'],
                        active : x['Active']    
                    }
                )
            })
            console.log("state.allTemplate",state.allTemplate)
        },
        [listAllTemplatesServer.rejected] : (state, action) => {
            state.isLoading = false
        }  
        }
})

export const {closeSnackBar,requiredPsdField,setTemplateName, setMailSubject, setMailMessage, setTemplateActive, setCriteria, setEditTemplateName, setEditTemplateSubject, setEditTemplateMessage, setEditTemplateActive} = settingsSlice.actions
export default settingsSlice.reducer