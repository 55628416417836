import React, { useState, useEffect } from 'react';
import CommonTabs from '../../components/common-tabs/CommonTabs';
import Container from '@mui/material/Container';
import BreadCrumb from '../../components/common-breadcrumb-section/BreadCrumb';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import IconBtn from '../../components/common-icon-btn/IconBtn';
import SearchIcon from '@mui/icons-material/Search';
import CommonDataGrid from '../../components/common-data-grid/CommonDataGrid';
import '../settingsContainer/settings.css'
import BtnContent from '../../components/common-content-btn/BtnContent';
import LinkNavigator from '../../components/common-link-navigator/LinkNavigator';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DialogLayoutWrapper from '../../components/Dialog-layout-wrapper/DialogLayoutWrapper';
import Preference from '../PreferenceContainer/Preference';
import TeamManagement from '../TeamManagementContainer/TeamManagement';
import Divider from '@mui/material/Divider';
import { addTemplateServer, setTemplateName, setMailSubject, setMailMessage, setTemplateActive, setCriteria, listAllTemplatesServer, setEditTemplateName, setEditTemplateSubject, setEditTemplateMessage, setEditTemplateActive } from '../../features/settings/SettingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BackDropLoader from '../../components/common-backdrop-loader/BackdropLoader';
import SnackBar from '../../components/snackBar-layout-wrapper/snackBar-layout-wrapper';
import EditTemplateDialog from '../../components/edit-template-dialog/EditTemplateDialog';
import SwitchButton from '../../components/common-switch-button/SwitchButton';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const Testing = () => {
    const [isActive, setIsActive] = useState(true)
    const dispatch = useDispatch()
    const {templateName, mailSubject, mailMessage, templateActive, isLoading, messageCondition, message, messageType, criteria, allTemplate} = useSelector((store) => store.settings)
    const {usertoken} = useSelector((store) => store.login)
    const [routeData, setRouteData] = useState(0)
    const [open, setOpen] = useState(false)
    const [value, setValue] = React.useState(1);

    useEffect(() => {
        console.log('checkcriteria',criteria)
        dispatch(listAllTemplatesServer({usertoken,criteria})).then((res) => {
            console.log("fetched successfully",res)
        }).catch((err) => {
            console.log("error in list all template",err)
        })
    },[criteria])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const columns = [
        // { field: 'id', headerName: 'ID', minWidth: 90,
        // flex: 1 },
        {
            field: 'templateId',
            headerName: 'Template ID',
            // minWidth: 150,
            flex: 1,
            editable: false
        },
        {
            field: 'active',
            headerName: 'Active',
            // minWidth: 150,
            flex: 1,
            editable: false
        },
        {
            field:'Action',
            headerName: 'Action',
            // minWidth: 30,
            flex: 'auto',
            editable: false,
            renderCell : (params) => {
                console.log("params",params)
                return(
                    <div>
                        <span id = {"edit"+params.row.id} onClick = {() => {
                            setOpen(true)
                            dispatch(setEditTemplateName(params.row['templateId']))
                            dispatch(setEditTemplateSubject(params.row.subject))
                            dispatch(setEditTemplateMessage(params.row.subjectMessage))
                            dispatch(setEditTemplateActive(params.row.active))
                            }}>
                            <EditOutlinedIcon/>
                        </span>
                    </div>
                )
            }
        }
    
    ];
    const rows = [
        { id: 1, Name: 'Template A',Action: "edit"},
        { id: 2, Name: 'Template B',Action:"edit" },
        { id: 3, Name: 'Template C',Action:"edit" },
        { id: 4, Name: 'Template D',Action:"edit" },
        { id: 5, Name: 'Template E',Action:"edit" },
        { id: 6, Name: 'Template F',Action:"edit" }
    ];

    const handleSubmit = () => {
        window.alert("submit triggered")
    }

    const ComponentA = () => {
        return(
            <> 
                    <form>
                        <Grid container rowSpacing={3}>
                                <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                            <label for = "id" className="text-capitalize">template name</label>
                                        </Grid>
                                        <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                            <TextField size="small" value = {templateName} onChange = {(event) => dispatch(setTemplateName(event.target.value))} className='ipt-size' autoComplete = "off" id="id" variant="outlined" />   
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                            <label for = "name" className="text-capitalize">subject</label>
                                        </Grid>
                                        <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                            <TextField value = {mailSubject} size="small" onChange = {(event) => dispatch(setMailSubject(event.target.value))} className='ipt-size' autoComplete = "off" id="name" variant="outlined" />   
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                            <label for = "description" className="text-capitalize">message</label>
                                        </Grid>
                                        <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                            <TextareaAutosize
                                            value = {mailMessage}
                                            onChange = {(event) => dispatch(setMailMessage(event.target.value))}
                                            className='ipt-size'
                                            aria-label="minimum height"
                                            autoComplete = "off"
                                            id = "description"
                                            minRows={10}
                                            placeholder=""
                                            />  
                                        </Grid>
                                    </Grid>
                                </Grid>
                        </Grid>
                        <Grid  
                            mt={1}
                            container
                            justifyContent={"flex-end"}
                            alignItems="center"
                            rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg : 4, xl : 5 }}
                            className = "text-center">
                                <Grid item xs={12} sm = {12} md = {8} lg = {12} xl = {12} sx = {{display : "flex",justifyContent : "end"}}>
                                        <div className='mar-r-10'>
                                            <BtnContent color = {"primary"} click = {() => {
                                                dispatch(addTemplateServer({usertoken,templateName,templateActive,mailSubject,mailMessage})).then((res)=>{
                                                    console.log("res for template message",res)
                                                    dispatch(setTemplateName(''))
                                                    dispatch(setMailSubject(''))
                                                    dispatch(setMailMessage(''))
                                                }).catch((err) => {
                                                    console.log("err for template message",err)
                                                })
                                                }}>
                                                <span>add</span>
                                            </BtnContent>
                                        </div>
                                        <div>
                                            <BtnContent color = {"primary"} click = {() => {
                                                dispatch(setTemplateName(''))
                                                dispatch(setMailSubject(''))
                                                dispatch(setMailMessage(''))
                                            }}>
                                                <span>cancel</span>
                                            </BtnContent>
                                        </div>
                                </Grid>
                        </Grid>
                    </form>
            </>
        )
    }

    const ComponentB = () => {
        return(
            <>
                        <Grid container rowSpacing={3}>
                            <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                  <form style={{width : "100%"}}>
                                  <Grid container alignItems={"center"}>
                                      <Grid item xs={12} sm = {12} md = {4} lg = {2} xl = {3} className = "pd-r-15px">
                                          <TextField size="small" className='ipt-size' id="name" label="Template Name" autoComplete = "off" variant="outlined" />
                                      </Grid>
                                      <Grid item xs={12} sm = {12} md = {8} lg = {10} xl = {9} sx = {{display : "flex",textAlign : "start"}}>
                                          <IconBtn icon = {<SearchIcon/>}/>
                                      </Grid>
                                  </Grid>
                                  </form>
                            </Grid>
                            <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                      <CommonDataGrid columns = {columns} rows = {rows} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
            </>
        )
    }

    const arr = [
        {
            id : 0,
            tabName : 'Add',
            component : <ComponentA/>
        },
        {
            id : 1,
            tabName : 'Edit',
            component : <ComponentB/>
        }
    ]

    const linkNavigatorArr = [
        {
            id : 0,
            linkName : "Email Templates",
            component : <CommonTabs arr = {arr}/>
        },
        {
            id : 1,
            linkName : "Teams",
            component : <h1>Teams</h1>
        },
        {
            id : 2,
            linkName : "Preference",
            component : <Preference/>
        }
    ]

    const click = (data) => {
        setRouteData(data)
        setRouteData(data)
    }

    const clickBtnNameOne = () => {
        window.alert("triggered 1")
    }

    const clickBtnNameTwo = () => {
        setOpen(!open)
    }

    const outputReturn = (data) => {
        let datas = data.split('/n')
        return datas.join('\n')
    }

    return(
        <div>
            <Container maxWidth="xl">
                <Grid  
                    justifyContent={{xs: 'flex-start', sm: 'flex-start', md : 'flex-start', lg : 'flex-start', xl : 'flex-start'}}
                    alignItems="center"
                    rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg : 4, xl : 5 }}
                    className = "text-center">
                        <Stack 
                        direction={{ xs: 'column', sm: 'row', md : 'row', lg : 'row', xl : 'row' }} 
                        spacing={2} 
                        justifyContent={{xs: 'flex-start', sm: 'flex-start', md : 'flex-start', lg : 'flex-start', xl : 'flex-start'}}
                        alignItems="start" mb={1}>
                            <BreadCrumb breadCrumbName = {"Settings"}/>
                        </Stack>
                </Grid>
                <Divider/>
                <Grid  
                    style = {{marginTop : '-20px'}}
                    container
                    direction = {"row"}
                    rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg : 3, xl : 3 }}>
                        {/* <Grid item xs={12} sm = {12} md = {3} lg = {3} xl = {3}>
                            <LinkNavigator linkData = {linkNavigatorArr} click = {click}/>
                        </Grid> */}
                        <Grid item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                            {/* <CommonTabs arr = {arr}/> */}
                            {/* {linkNavigatorArr.map((x,index) => {
                                if(routeData == index){
                                    return( 
                                        <>
                                            {x.component}       
                                        </>
                                    )   
                                }
                            })} */}
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Add" {...a11yProps(0)} />
                                    <Tab label="Edit" {...a11yProps(1)} />
                                </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    {/* Item One */}
                                    <form>
                                        <Grid container rowSpacing={3}>
                                                <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2} className="padingpb">
                                                            <label for = "id" className="text-capitalize">template name</label>
                                                        </Grid>
                                                        <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                                            <TextField size="small" value = {templateName} onChange = {(event) => dispatch(setTemplateName(event.target.value))} className='ipt-size' autoComplete = "off" id="id" variant="outlined" />   
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                                            <label for = "name" className="text-capitalize">subject</label>
                                                        </Grid>
                                                        <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                                            <TextField value = {mailSubject} size="small" onChange = {(event) => dispatch(setMailSubject(event.target.value))} className='ipt-size' autoComplete = "off" id="name" variant="outlined" />   
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                                            <label for = "description" className="text-capitalize">message</label>
                                                        </Grid>
                                                        <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                                            <TextareaAutosize
                                                            value = {outputReturn(mailMessage)}
                                                            onChange = {(event) => {
                                                                let mailMess = event.target.value.split('\n')
                                                                dispatch(setMailMessage(mailMess.join('/n')))
                                                            }}
                                                            className='ipt-size'
                                                            aria-label="minimum height"
                                                            autoComplete = "off"
                                                            id = "description"
                                                            minRows={10}
                                                            placeholder=""
                                                            />  
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                                            <Grid container alignItems={"center"}>
                                                                <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                                                    {/* <label for = "description" className="text-capitalize">description</label> */}
                                                                </Grid>
                                                                <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                                                    {/* <TextareaAutosize
                                                                    aria-label="minimum height"
                                                                    id = "description"
                                                                    minRows={10}
                                                                    placeholder=""
                                                                    style={{ width: "100%" }}
                                                                    />   */}
                                                                    <SwitchButton change = {(event) => setIsActive(event)} checkLabel = {'Active'} notCheckLabel = {'InActive'} checked = {isActive}/>
                                                                </Grid>
                                                            </Grid>
                                                </Grid>
                                        </Grid>
                                        <Grid  
                                            mt={1}
                                            container
                                            justifyContent={"flex-end"}
                                            alignItems="center"
                                            rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg : 4, xl : 5 }}
                                            className = "text-center">
                                                <Grid item xs={12} sm = {12} md = {8} lg = {12} xl = {12} sx = {{display : "flex",justifyContent : "end"}}>
                                                        <div className='mar-r-10'>
                                                            <BtnContent color = {"primary"} click = {() => {
                                                                if(isActive){
                                                                    let status = 'Y'
                                                                    dispatch(addTemplateServer({usertoken,templateName,status,mailSubject,mailMessage})).then((res)=>{
                                                                        console.log("res for template message",res)
                                                                        dispatch(setTemplateName(''))
                                                                        dispatch(setMailSubject(''))
                                                                        dispatch(setMailMessage(''))
                                                                    }).catch((err) => {
                                                                        console.log("err for template message",err)
                                                                        dispatch(setTemplateName(''))
                                                                        dispatch(setMailSubject(''))
                                                                        dispatch(setMailMessage(''))
                                                                    })   
                                                                }
                                                                else{
                                                                    let status = 'N'
                                                                    dispatch(addTemplateServer({usertoken,templateName,status,mailSubject,mailMessage})).then((res)=>{
                                                                        console.log("res for template message",res)
                                                                        dispatch(setTemplateName(''))
                                                                        dispatch(setMailSubject(''))
                                                                        dispatch(setMailMessage(''))
                                                                    }).catch((err) => {
                                                                        console.log("err for template message",err)
                                                                        dispatch(setTemplateName(''))
                                                                        dispatch(setMailSubject(''))
                                                                        dispatch(setMailMessage(''))
                                                                    })
                                                                }

                                                                // dispatch(addTemplateServer({usertoken,templateName,templateActive,mailSubject,mailMessage})).then((res)=>{
                                                                //     console.log("res for template message",res)
                                                                //     dispatch(setTemplateName(''))
                                                                //     dispatch(setMailSubject(''))
                                                                //     dispatch(setMailMessage(''))
                                                                // }).catch((err) => {
                                                                //     console.log("err for template message",err)
                                                                //     dispatch(setTemplateName(''))
                                                                //     dispatch(setMailSubject(''))
                                                                //     dispatch(setMailMessage(''))
                                                                // })
                                                                }}>
                                                                <span>add</span>
                                                            </BtnContent>
                                                        </div>
                                                        <div>
                                                            <BtnContent color = {"primary"} click = {() => {
                                                                dispatch(setTemplateName(''))
                                                                dispatch(setMailSubject(''))
                                                                dispatch(setMailMessage(''))
                                                            }}>
                                                                <span>cancel</span>
                                                            </BtnContent>
                                                        </div>
                                                </Grid>
                                        </Grid>
                                    </form>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <>
                                                <Grid container rowSpacing={3}>
                                                    <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                                        <form style={{width : "100%"}}>
                                                        <Grid container alignItems={"center"}>
                                                            <Grid item xs={12} sm = {12} md = {4} lg = {2} xl = {3} className = "pd-r-15px">
                                                                <TextField size="small" onChange={(e) => dispatch(setCriteria(e.target.value))} placeholder = "Search template" className='ipt-size' id="name" autoComplete = "off" variant="outlined" />
                                                            </Grid>
                                                            {/* <Grid item xs={12} sm = {12} md = {8} lg = {10} xl = {9} sx = {{display : "flex",textAlign : "start"}}>
                                                                <IconBtn icon = {<SearchIcon/>}/>
                                                            </Grid> */}
                                                        </Grid>
                                                        </form>
                                                    </Grid>
                                                    <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                                        <Grid container alignItems={"center"}>
                                                            <Grid item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                                            <CommonDataGrid columns = {columns} rows = {allTemplate} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                    </>
                                </TabPanel>
                        </Grid>
                </Grid>
            </Container>
            {/* <DialogLayoutWrapper open={open} title = {"edit template"} btnNameOne = {"save"} clickBtnNameOne = {clickBtnNameOne} clickBtnNameTwo = {clickBtnNameTwo} btnNameTwo = {"cancel"} close={()=>setOpen(!open)}>
                        <Grid container rowSpacing={3}>
                            <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                        <label for = "id" className="text-capitalize">template name</label>
                                    </Grid>
                                    <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                        <TextField size="small" className='ipt-size' autoComplete = "off" id="id" variant="outlined" />   
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                        <label for = "name" className="text-capitalize">subject</label>
                                    </Grid>
                                    <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                        <TextField size="small" className='ipt-size' autoComplete = "off" id="name" variant="outlined" />   
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                        <label for = "description" className="text-capitalize">message</label>
                                    </Grid>
                                    <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                        <TextareaAutosize
                                        className='ipt-size'
                                        aria-label="minimum height"
                                        id = "description"
                                        minRows={10}
                                        placeholder=""
                                        autoComplete = "off"
                                        size="small"
                                        />  
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
            </DialogLayoutWrapper> */}
            <EditTemplateDialog open={open} close={()=>setOpen(!open)} />
            <BackDropLoader loaderBool = {isLoading}/>
            <SnackBar condition={messageCondition} message={message} type={messageType} />
        </div>
    )
}

export default Testing