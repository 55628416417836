import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    teamOverAllStatusData : [],
    isLoading : false,
    message : "",
    messageCondition : false,
    messageType : ""
}

export const teamOverallStatusServer = createAsyncThunk(
    "status/teamOverallStatus",
    async (params) => {
        try{
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/TeamOverallStatusJSON?Token='+params.usertoken,{
                method : 'GET'
            })
            const data = await response.json()
            return data
        }catch(err){
            return console.log("error:",err)
        }
    }
)

const teamStatusSlice = createSlice({
    name : "teamStatus",
    initialState,
    reducers : {

    },
    extraReducers : {
        [teamOverallStatusServer.pending] : (state, action) => {
            state.isLoading = true
            state.teamOverAllStatusData = []
            console.log("team overallStatusServer state pending",state)
        },
        [teamOverallStatusServer.fulfilled] : (state, action) => {
            state.isLoading = false
            state.message = "Team status fetched successfully"
            state.messageCondition = true
            state.messageType = "success"
            if(action.payload.TeamStatus.Teams != '[]'){
                state.teamOverAllStatusData = (!Array.isArray(action.payload.TeamStatus.Teams) ? [action.payload.TeamStatus.Teams] : action.payload.TeamStatus.Teams)
            }
            else{
                state.teamOverAllStatusData = []
            }
            // state.teamOverAllStatusData = action.payload.TeamStatus.Teams
            console.log("team overallStatusServer state fullfilled",state)
        },
        [teamOverallStatusServer.rejected] : (state, action) => {
            state.isLoading = false
            state.message = "Failed to fetch Team status"
            state.messageCondition = true
            state.messageType = "error"
            state.teamOverAllStatusData = []
            console.log("team overallStatusServer state rejected",state)
        }
    }
})

export const {} = teamStatusSlice.actions
export default teamStatusSlice.reducer