import React from 'react'
import Grid from '@mui/material/Grid';
import '../common-message-indication/message-indication.css';

const MessageIndication = (props) => {
    return(
        <>
            <div className='message-wrapper'>
                <div className='icon-container'>
                    {props.icon}
                </div>
                <div className = "message-container text-center">
                    <p className="alert-msg">{props.message}</p>
                </div>
            </div>
        </>
    )
}

export default MessageIndication