import React, { useCallback, useEffect, useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const SwitchButton = (props) => {
    const [switchChecked, setSwitchChecked] = useState(props.checked)
    const switchTrigger = useCallback(() => {
        setSwitchChecked(!switchChecked)
    },[switchChecked])
    useEffect(() => {
        props.change(switchChecked)
    },[switchChecked])
    return(
        <>
            <FormGroup>
                <FormControlLabel control={<Switch onChange = {() => switchTrigger()} checked = {switchChecked} />} label={switchChecked ? props.checkLabel : props.notCheckLabel} />
            </FormGroup>
        </>
    )
}

export default SwitchButton