import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import './content-card.css';

const ContentCard = (props) => {
    return(
        <>
                            <Grid               
                            item 
                            xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Card onClick = {props.click} className={props.class} sx={{ color:`${props.clrwhite}`,display: 'flex', minWidth : '300px', justifyContent : 'center', background : `${props.bgColor}` }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <CardContent sx={{ flex: '70%', textAlign : 'start' }}>
                                            <div>
                                                <div>                                                    
                                                    <h3 className={props.carstitleclr == true ? "txt-blueclr text-capitalize m-0" : "text-capitalize"}>{props.cardTitle}</h3>
                                                </div>
                                                <div>                                                    
                                                    <p className={props.carstitleclr == true ? 'questionnarieContent m-0' : ""}>{props.description}</p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Grid>
        </>
    )
}

export default ContentCard