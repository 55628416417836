import React from 'react'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import '../common-icon-content-btn/btn-icon-content.css';
const BtnIconContent = (props) => {
    return(
        <>
            <Stack direction="row" spacing={2}>
                <Button className = "text-capitalize" variant="contained" color = "primary" startIcon={props.btnIcon} onClick = {props.click}>
                    {props.btnName}
                </Button>
            </Stack>
        </>
    )
}

export default BtnIconContent