import React,{useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import '../SurveyQuestionContainer/survey-questions.css'
import BtnContent from '../../components/common-content-btn/BtnContent';
import ContentCard from '../../components/common-content-card/ContentCard';
import { surveyQuestionnaireServer, submitQuestionnaireServer, setAnswerBoolState, setSuccessMessage, setErrorMessage, setMessageToFalse, surveyCompletedValidatorServer, NotifyTeamSubmissionJSON } from '../../features/surveyQuestions/surveyQuestionSlice';
import { useSelector, useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';
import { useLocation, useNavigate } from 'react-router';
import SnackBar from '../../components/snackBar-layout-wrapper/snackBar-layout-wrapper';
import IconBtn from '../../components/common-icon-btn/IconBtn';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SquareIcon from '@mui/icons-material/Square';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import emailjs from 'emailjs-com';

const SurveyQuestionContainer = () => {
    const navigate = useNavigate()
    let dispatch = useDispatch()
    const location = useLocation()
    const {questionData, teamId, personId, answerBoolState, isLoading, message, messageType, messageCondition} = useSelector((store) => store.survey)
    // const {usertoken} = useSelector((store) => store.login)
    const [counter, setCounter] = useState(1)
    const [checkedAnswer, setCheckedAnswer] = useState([])
    const [isOpenTeamToggle,setIsOpenTeamToggle] = useState(true);
    const [isOpenInfoIcon,setIsOpenInfoIcon] = useState(false);
    const [finalAnswer, setFinalAnswer] = useState({
        seqId : '',
        sectionId : '',
        section : '',
        categoryId : '',
        category : '',
        score : ''
    })


    const handlCloseiconview = (value) =>{
        if(value == "closeicon"){
            setIsOpenTeamToggle(false);
            setIsOpenInfoIcon(true);
        }
        else if(value == "infoicon"){
            setIsOpenTeamToggle(true);
            setIsOpenInfoIcon(false);
        }  
    }

//     ❖ “*” is Mandatory
// ❖ Please provide rating for each statement
// ❖ Here are 48 statements that are key in the development of highly effective teams.
// ❖ On a scale of 1 to 10, where 1 is “completely disagree” and 10 is totally agree
// ❖ Please rate your teams's performance.
// ❖ Your individual response will remain anonymous; however the amalgamated results will be
// shared and used to produce a Team Workology report,

    const cardContent = () => {
        return(
            <>
            
                <ul>
                    {/* <li>“*” is Mandatory</li> */}
                    <li>Please provide a rating, based on the team you have been asked to submit for.</li>
                    
                    <li>Use the scale of 1 to 10, where 1 is “completely disagree” and 10 is “totally agree”.</li>
                    {/* <li>Please rate your teams's performance.</li> */}
                 
                </ul>
            </>
        )
    }
    useEffect(() => {
        let usertoken = location.state.usertoken
        dispatch(surveyQuestionnaireServer({usertoken})).then((res) => {
            console.log("questionnaire response values",questionData)
        }).catch((err) => {
            console.log('questionnaire err',err)
        })
        console.log("teamId from survey questions",teamId)
    },[])
    
    return(
        <Container maxWidth="xl" style = {{marginTop : '65px'}}>
                <SnackBar condition={messageCondition} message={message} type={messageType} />
                {/* <BackDropLoader loaderBool={isLoading} /> */}
                <Grid
                    // spacing = {5}
                    container
                    // justifyContent = {'center'}
                    alignItems={'flex-start'}
                    className="text-center">
                    <Grid item xl = {!isOpenTeamToggle ? 12 : 7} lg = {!isOpenTeamToggle ? 12 : 7} md = {12} sm = {12} xs = {12} sx = {!isOpenTeamToggle ? {width : '100vw'} : {}}> 
                        <Stack
                            direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
                            spacing={2}
                            justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }}
                            alignItems="center" mb={1}>
                                <Grid container>
                                    <Grid item xl = {12}>
                                        <Grid container p = {2} alignItems={'center'}>
                                            <Grid item xl = {6} sx = {{display : 'flex',alignItems : 'center'}}>
                                                <span><p className='text-capitalize m-0 fs-1'>Hi</p></span>
                                                <span style = {{paddingLeft : '10px'}}><p className='m-0 fs-1-fw-500'>{location.state.name}</p></span>
                                            </Grid>
                                            <Grid item xl = {5} sx = {{display : 'flex',alignItems : 'center'}} pr = {1} className="text-align-end" justifyContent={"end"}>
                                                
                                            </Grid>
                                            <Grid item xl = {1} sx = {{display : 'flex', justifyContent : 'flex-end'}}> 
                                                <span>{isOpenTeamToggle == true &&<IconBtn className='icn-toggle-custom' toggle = {true} click={() => handlCloseiconview('closeicon')} icon={<ArrowForwardIosIcon />} />}</span>
                                                <span>{isOpenInfoIcon == true &&<IconBtn className='icn-toggle-custom' toggle = {true} click={() => handlCloseiconview('infoicon')} icon={<ArrowBackIosIcon />} />}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        </Stack>
                        <Divider/>
                        <Grid container direction = {'column'} mt = {2} p = {2}>
                            {questionData.map((x,index) => {
                                console.log("questionData length",questionData.length)
                                if(counter == index + 1){
                                    if(document.getElementById(index+1+'task-label')){
                                        document.getElementById(index+1+'task-label').classList.remove('not-completed')
                                        document.getElementById(index+1+'task-label').classList.add('present')
                                    }
                                    return(
                                        <>
                                            {/* <Grid item xl = {12} textAlign = {'left'}>
                                                <p className = "text-capitalize fs-1">{index + 1}</p>
                                            </Grid> */}
                                            <Grid item xl = {12}>
                                                <Grid container direction = {'column'} className = "outerQuestionvalue">
                                                        <Grid item xl = {12} lg = {12} textAlign = {'left'}>
                                                            <div style = {{display : 'flex' , paddingBottom:'20px'}}>
                                                                <div>
                                                                    <p className="m-0 fs-1">{index + 1}.</p>
                                                                </div>
                                                                <div>
                                                                    <p className="fs-1 m-0 pd-l-5">{x['Question']}<span className="text-warning fw-500"></span></p>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xl = {12} textAlign = {'left'}>
                                                            <Grid container>
                                                                <Grid item xl = {12} lg = {6}> 
                                                                    <FormControl style = {{width : '100%'}}>
                                                                        <RadioGroup
                                                                        value={checkedAnswer[index + 1]}
                                                                        onChange = {(e) => {
                                                                            if(index + 1 == 48){
                                                                                setFinalAnswer({
                                                                                    seqId : x['Seq_No'],
                                                                                    sectionId : x['Section_Id'],
                                                                                    section : x['Section'],
                                                                                    categoryId : x['Category_Id'],
                                                                                    category : x['Category'],
                                                                                    score : e.target.value
                                                                                })
                                                                            }
                                                                            
                                                                            let seqId = x['Seq_No']
                                                                            
                                                                            let sectionId = x['Section_Id']
                                                                            
                                                                            let section = x['Section']
                                                                            
                                                                            let categoryId = x['Category_Id']
                                                                            
                                                                            let category = x['Category']
                                                                            
                                                                            let score = e.target.value
                                                                            checkedAnswer[index + 1] = score
                                                                            if(index + 1 < 48){
                                                                            
                                                                            console.log("arrValue",checkedAnswer)
                                                                            let usertoken = location.state.usertoken
                                                                            dispatch(surveyCompletedValidatorServer({teamId,personId,usertoken,categoryId,category,section,seqId,sectionId,section,score})).then((res) => {
                                                                                console.log("response fetched for survey validator",res)
                                                                            }).catch((err) => {
                                                                                console.log("error occurer in service",err)
                                                                            })
                                                                            dispatch(submitQuestionnaireServer({usertoken, teamId, personId,categoryId,sectionId,seqId,score})).then((res) => {
                                                                                console.log("response for each question",res)
                                                                                
                                                                                document.getElementById(index+1+'task-label').classList.remove('present')
                                                                                document.getElementById(index+1+'task-label').classList.remove('not-completed')
                                                                                document.getElementById(index+1+'task-label').classList.remove('skipped')
                                                                                document.getElementById(index+1+'task-label').classList.add('completed')
                                                                                document.getElementById(index + 2+'check').click()
                                                                            }).catch((err) => {
                                                                                console.log("err",err)
                                                                            })
                                                                            }
                                                                        }}
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        name="radio-buttons-group"
                                                                        >
                                                                            <div>
                                                                                <table className='survey-table'>
                                                                                    <tr className="questionvalue">
                                                                                        {/* <th className='survey-header text-center'>
                                                                                            <FormLabel id="demo-radio-buttons-group-label" className = "text-capitalize">0</FormLabel>
                                                                                        </th> */}
                                                                                        <th className='survey-header text-center'>
                                                                                            <FormLabel id="demo-radio-buttons-group-label">1</FormLabel>
                                                                                        </th>
                                                                                        <th className='survey-header text-center'>
                                                                                            <FormLabel id="demo-radio-buttons-group-label">2</FormLabel>
                                                                                        </th>
                                                                                        <th className='survey-header text-center'>
                                                                                            <FormLabel id="demo-radio-buttons-group-label">3</FormLabel>
                                                                                        </th>
                                                                                        <th className='survey-header text-center'>
                                                                                            <FormLabel id="demo-radio-buttons-group-label">4</FormLabel>
                                                                                        </th>
                                                                                        <th className='survey-header text-center'>
                                                                                            <FormLabel id="demo-radio-buttons-group-label">5</FormLabel>
                                                                                        </th>
                                                                                        <th className='survey-header text-center'>
                                                                                            <FormLabel id="demo-radio-buttons-group-label">6</FormLabel>
                                                                                        </th>
                                                                                        <th className='survey-header text-center'>
                                                                                            <FormLabel id="demo-radio-buttons-group-label">7</FormLabel>
                                                                                        </th>
                                                                                        <th className='survey-header text-center'>
                                                                                            <FormLabel id="demo-radio-buttons-group-label">8</FormLabel>
                                                                                        </th>
                                                                                        <th className='survey-header text-center'>
                                                                                            <FormLabel id="demo-radio-buttons-group-label">9</FormLabel>
                                                                                        </th>
                                                                                        <th className='survey-header text-center'>
                                                                                            <FormLabel id="demo-radio-buttons-group-label" className = "text-capitalize">10</FormLabel>
                                                                                        </th>
                                                                                    </tr>

                                                                                    <tr className="answervalue">
                                                                                        
                                                                                            {/* <td className='survey-def text-center'>
                                                                                                <FormControlLabel value="0" control={<Radio />}  />
                                                                                            </td> */}
                                                                                            <td className='survey-def text-center'>
                                                                                                 <FormControlLabel value="1" control={<Radio />} />
                                                                                            </td>
                                                                                            <td className='survey-def text-center'>
                                                                                                <FormControlLabel value="2" control={<Radio />} />
                                                                                            </td>
                                                                                            <td className='survey-def text-center'>
                                                                                                <FormControlLabel value="3" control={<Radio />} />
                                                                                            </td>
                                                                                            <td className='survey-def text-center'>
                                                                                                <FormControlLabel value="4" control={<Radio />} />
</td>
                                                                                            <td className='survey-def text-center'>
                                                                                                <FormControlLabel value="5" control={<Radio />} />
                                                                                            </td>
                                                                                            <td className='survey-def text-center'>
                                                                                                <FormControlLabel value="6" control={<Radio />} />
                                                                                            </td>
                                                                                            <td className='survey-def text-center'>
                                                                                                <FormControlLabel value="7" control={<Radio />} />
                                                                                            </td>
                                                                                            <td className='survey-def text-center'>
                                                                                                <FormControlLabel value="8" control={<Radio />} />
                                                                                            </td>
                                                                                            <td className='survey-def text-center'>
                                                                                                <FormControlLabel value="9" control={<Radio />} />
                                                                                            </td>
                                                                                            <td className='survey-def text-center'>
                                                                                                <FormControlLabel value="10" control={<Radio />} />
                                                                                            </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid> 
                                                            </Grid>
                                                        </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xl = {12} mt = {2}>
                                                    <Grid container directio = "row" spacing = {1}> 
                                                        <Grid item xl = {6}>
                                                            <Grid container>
                                                                <Grid item>
                                                                    {questionData.length != index + 1 && <BtnContent click = {() => {
                                                                        setCounter(counter => counter + 1)
                                                                        if(document.getElementById(index+1+'task-label').className != 'completed'){
                                                                            document.getElementById(index+1+'task-label').classList.remove('present')
                                                                            document.getElementById(index+1+'task-label').classList.add('skipped')
                                                                        }
                                                                        }}>
                                                                        <span>skip</span>
                                                                    </BtnContent>}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xl = {6}>
                                                            <Grid container spacing = {1} xl = {12} lg = {12} display = 'flex' justifyContent={'space-between'}>
                                                                <Grid item>
                                                                    {index + 1 > 1 && <BtnContent click = {() => {
                                                                        setCounter(counter => counter - 1)
                                                                        if(checkedAnswer[index + 1] != undefined && checkedAnswer[index + 1] != null){
                                                                            document.getElementById(index+1+'task-label').classList.remove('present')
                                                                            document.getElementById(index+1+'task-label').classList.add('completed')
                                                                        }
                                                                        else if(document.getElementById(index+1+'task-label').className != 'completed' ){
                                                                            document.getElementById(index+1+'task-label').classList.remove('present')
                                                                            document.getElementById(index+1+'task-label').classList.add('skipped')
                                                                        }
                                                                        // console.log('checkedAnswer',checkedAnswer[index + 1])
                                                                        // if(document.getElementById(index+1+'task-label').className != 'completed'){
                                                                        //     document.getElementById(index+1+'task-label').classList.remove('present')
                                                                        //     document.getElementById(index+1+'task-label').classList.add('skipped')
                                                                        // }
                                                                        // else if(checkedAnswer[index + 1] != ''){
                                                                        //     document.getElementById(index+1+'task-label').classList.remove('present')
                                                                        //     document.getElementById(index+1+'task-label').classList.add('completed')
                                                                        // }
                                                                        }}>
                                                                        <span>previous</span>
                                                                    </BtnContent>}
                                                                </Grid>
                                                                <Grid item>
                                                                    {questionData.length > index + 1 && <BtnContent id = {"elem"+index + 1} click = {() => {
                                                                        setCounter(counter => counter + 1)
                                                                        console.log('checkedAnswer',checkedAnswer[index + 1])
                                                                        if(checkedAnswer[index + 1] != undefined && checkedAnswer[index + 1] != null){
                                                                            document.getElementById(index+1+'task-label').classList.remove('present')
                                                                            document.getElementById(index+1+'task-label').classList.add('completed')
                                                                        }
                                                                        else if(document.getElementById(index+1+'task-label').className != 'completed' ){
                                                                            document.getElementById(index+1+'task-label').classList.remove('present')
                                                                            document.getElementById(index+1+'task-label').classList.add('skipped')
                                                                        }  
                                                                        }}>
                                                                        <span>next</span>
                                                                    </BtnContent>}
                                                                    {questionData.length == index + 1 && <BtnContent id = {"elem"+index + 1} click = {() => {
                                                                                console.log("checkedAnswer[48]",checkedAnswer[48])
                                                                                if(index + 1 == 48 && finalAnswer.seqId != ''){
                                                                                    let seqId = finalAnswer.seqId
                                                                                    let sectionId = finalAnswer.sectionId
                                                                                    
                                                                                    let section = finalAnswer.section
                                                                                    
                                                                                    let categoryId = finalAnswer.categoryId
                                                                                    
                                                                                    let category = finalAnswer.category
                                                                                    
                                                                                    let score = finalAnswer.score
                                                                                    let usertoken = location.state.usertoken
                                                                                dispatch(surveyCompletedValidatorServer({teamId,personId,usertoken,categoryId,category,section,seqId,sectionId,section,score})).then((res) => {
                                                                                    console.log("response fetched for survey validator",res)
                                                                                }).catch((err) => {
                                                                                    console.log("error occurer in service",err)
                                                                                })
                                                                                dispatch(submitQuestionnaireServer({usertoken, teamId, personId,categoryId,sectionId,seqId,score})).then((res) => {
                                                                                    console.log("response for each question",res)
                                                                                    
                                                                                    document.getElementById(index+1+'task-label').classList.remove('present')
                                                                                    document.getElementById(index+1+'task-label').classList.remove('not-completed')
                                                                                    document.getElementById(index+1+'task-label').classList.remove('skipped')
                                                                                    document.getElementById(index+1+'task-label').classList.add('completed')
                                                                                    document.getElementById(index + 2+'check').click()
                                                                                }).catch((err) => {
                                                                                    console.log("err",err)
                                                                                })
                                                                                }
                                                                                // else{
                                                                                //     window.alert("triggered")
                                                                                // }
                                                                            
                                                                            let checker = false;
                                                                            for(var i = 1; i < questionData.length; i++){
                                                                                    
                                                                                if(document.getElementById(i+'task-label').className == 'completed'){
                                                                                    checker = true
                                                                                    // dispatch(setSuccessMessage())
                                                                                    // setTimeout(() => {
                                                                                    //     dispatch(setMessageToFalse())
                                                                                    // },3000)
                                                                                    // navigate('/surveyCompleted', {replace : false})
                                                                                    // break;
                                                                                }
                                                                                else{
                                                                                    checker = false
                                                                                    dispatch(setErrorMessage())
                                                                                    setTimeout(() => {
                                                                                        dispatch(setMessageToFalse())
                                                                                    },3000)
                                                                                    break;
                                                                                }
                                                                            }
                                                                            if(checker){
                                                                                dispatch(setSuccessMessage())
                                                                                setTimeout(() => {
                                                                                        dispatch(setMessageToFalse())
                                                                                },3000)
                                                                                dispatch(NotifyTeamSubmissionJSON({usertoken: 'PUBLIC',teamId, personId})).then(res => {
                                                                                    if(res['payload']['Notify'] !== '[]'){
                                                                                        let draft = {
                                                                                            from_name: res['payload']['Notify']['Delegate'],
                                                                                            to_name: res['payload']['Notify']['Email_Address'],
                                                                                            email_id: res['payload']['Notify']['Cid'],
                                                                                            mail_subject: res['payload']['Notify']['Subject'],
                                                                                            bcc : '',
                                                                                            message: res['payload']['Notify']['Body'],
                                                                                            survey_link: '',
                                                                                            pdfLink: ''
                                                                                        }
                                                                                        emailjs.send('service_kooyngm', 'template_6vkqbul', draft, 'Ha6CtAyRG7PFMtJOQ')
                                                                                        .then(function (response) {
                                                                                            console.log('SUCCESS!', response.status, response.text);
                                                                                        }, function (error) {
                                                                                            console.log('FAILED...', error);
                                                                                        });
                                                                                    }
                                                                                })
                                                                                navigate('/surveyCompleted', {replace : false})
                                                                            }
                                                                        }}>
                                                                        <span>finish</span>
                                                                    </BtnContent>}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                            </Grid>
                                        </>
                                    )
                                }
                            })}
                            {/* <Grid item>
                                <Grid container justifyContent={'flex-end'}>
                                    <Grid item>
                                        <span>{isOpenTeamToggle == true &&<IconBtn click={() => handlCloseiconview('closeicon')} icon={<ArrowForwardIosIcon />} />}</span>
                                        <span>{isOpenInfoIcon == true &&<IconBtn click={() => handlCloseiconview('infoicon')} icon={<ArrowBackIosIcon />} />}</span>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                            <Grid item xl = {12} mt = {2}>
                                <ContentCard clrwhite={"#000"} bgColor={"rgb(222 235 247)"} carstitleclr={true} cardTitle={"guide"} description={cardContent()} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {<Grid item xl = {isOpenTeamToggle ? 5 : 0} lg = {isOpenTeamToggle ? 5 : 0} md = {isOpenTeamToggle ? 12 : 0} sm = {isOpenTeamToggle ? 12 : 0} xs = {isOpenTeamToggle ? 12 : 0} style = {isOpenTeamToggle ? {height:'100vh',boxShadow: 'rgb(209, 191, 191) -1px 0px 0px 0px',paddingTop:'25px'} : {visibility : 'hidden'}}>
                        
                            <Grid container pl = {2}>
                                {/* <Grid item> */}
                                <div style={{display : 'flex', flexWrap : 'wrap'}}>
                                    {questionData.map((x,index) => {
                                            return(
                                                <>
                                                    <div style = {{padding : '12px 15px', display : 'flex', flexWrap : 'wrap'}}>
                                                        <div style = {{display : 'flex',flexDirection : "column"}}>
                                                            <label for = {index + 1}>{index + 1}</label>
                                                                <div id = {index + 1+'task-label'} className='not-completed'>
                                                                    <input type = "checkbox" onClick={() => {
                                                                        
                                                                        setCounter(index+1)
                                                                        if(checkedAnswer.length == 0){
                                                                            document.getElementById(1+'task-label').classList.add('not-completed')
                                                                        }
                                                                        for(var i = 0; i < questionData.length; i++){
                                                                            document.getElementById(i+1+'task-label').classList.remove('present')
                                                                            
                                                                            if(checkedAnswer[index + 1] != undefined && checkedAnswer[index + 1] != null){
                                                                                // document.getElementById(index+1+'task-label').classList.remove('present')
                                                                                document.getElementById(index+1+'task-label').classList.add('completed')
                                                                            }
                                                                            else if(document.getElementById(index+1+'task-label').className != 'completed' ){
                                                                                // document.getElementById(index+1+'task-label').classList.remove('present')
                                                                                document.getElementById(index+1+'task-label').classList.add('skipped')
                                                                            }
                                                                            console.log("document.getElementById(index+1+'task-label').className",document.getElementById(index+1+'task-label').className)
                                                                        }
                                                                        document.getElementById(i+1+'task-label').classList.add('present')
                                                                    }} style={{opacity : '0'}} id = {index + 1+'check'}/>
                                                                </div>
                                                        </div>     
                                                    </div>
                                                </>
                                            )
                                        })}
                                </div>
                                {/* </Grid> */}
                                <div className="margin-24">
                                                        <div className='d-flex'>
                                                            <div className='pr-1 d-flex align-items-center'>
                                                                {/* <SquareIcon className="statusclrcomplete" /> */}
                                                                <div className="completed"></div>
                                                                <label>Completed</label>
                                                            </div>
                                                            <div className='pr-1 d-flex align-items-center'>
                                                                {/* <DensityLargeIcon className="statusclrskipped" /> */}
                                                                <div className="skipped"></div>
                                                                <label>Skipped</label>
                                                            </div>
                                                            <div className='pr-1 d-flex align-items-center'>
                                                                {/* <PlayArrowIcon className="statusclrpresent" /> */}
                                                                <div className="present"></div>
                                                                <label>Present</label>
                                                            </div>
                                                        </div>
                                                    </div>
                            </Grid>
                        
                    </Grid>}
                </Grid>
            </Container>
    )
}

export default SurveyQuestionContainer