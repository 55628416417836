import React from 'react';
import { DataGrid, gridClasses} from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import '../common-data-grid/common-data-grid.css';
const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
      '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid ${
          theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
        }`,
      },
      '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${
          theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
        }`,
      },
      '& .MuiDataGrid-cell': {
        color:
          theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
      },
      '& .MuiPaginationItem-root': {
        borderRadius: 0,
      },
    },
  }));

const CommonDataGrid = (props) => {
    const [pageSize, setPageSize] = React.useState(10);
    
    console.log('checkprops',props)
    return(
        // <div style={{ height: "calc(100vh - 330px)", width: '100%' }}>
        <Grid 
        container
            justifyContent={{xs: 'center', sm: 'center', md : 'center', lg : 'center', xl : 'center'}}
            alignItems="center"
            // rowSpacing={3} 
            // columnSpacing={{ xs: 1, sm: 2, md: 3, lg : 4, xl : 5 }}
            >
            <Grid item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                        {/* <div style = {{height : '320px'}}> */}
                        <div style={{ height: props.valueht == undefined ? props.normalgridheight == true ? '300px':'calc(100vh - 230px)' : props.valueht}}>
                        <StripedDataGrid
                                rows={props.rows}
                                columns={props.columns}
                                pageSize={pageSize}
                               
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowsPerPageOptions={[5, 10, 15]}
                                pagination
                                checkboxSelection = {props.checkboxSelection !== undefined ? false : true}
                                disableSelectionOnClick
                                onSelectionModelChange={(ids) => props.checkBoxSelected(ids)}
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                  }
                                //     (ids) => {
                                //     console.log("ids",ids)
                                //     const selectedIDs = new Set(ids);
                                //     const selectedRowData = props.rows.filter((row) =>
                                //       selectedIDs.has(row.id.toString())
                                //     );
                                //   }
                                // }
                                onRowClick={(params,event) => props.rowClick(params,event)
                                    // (params, event) => {
                                    // if (!event.ignore) {
                                    
                                    // console.log("push -> /roles/" + params.row.name);
                                    // // handleOpenTeams(params.row.name);
                                    // //     window.alert("triggered")
                                    // }
                                }
                            />
                            {/* <DataGrid
                                rows={props.rows}
                                columns={props.columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                disableSelectionOnClick
                                onSelectionModelChange={(ids) => props.checkBoxSelected(ids)}
                                //     (ids) => {
                                //     console.log("ids",ids)
                                //     const selectedIDs = new Set(ids);
                                //     const selectedRowData = props.rows.filter((row) =>
                                //       selectedIDs.has(row.id.toString())
                                //     );
                                //   }
                                // }
                                onRowClick={(params,event) => props.rowClick(params,event)
                                    // (params, event) => {
                                    // if (!event.ignore) {
                                    
                                    // console.log("push -> /roles/" + params.row.name);
                                    // // handleOpenTeams(params.row.name);
                                    // //     window.alert("triggered")
                                    // }
                                }
                            /> */}
                        </div>
            </Grid>
        </Grid>
        // </div>
    )
}

export default CommonDataGrid