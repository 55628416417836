import React, { useState } from 'react';
import DialogLayoutWrapper from '../Dialog-layout-wrapper/DialogLayoutWrapper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from "react-redux";
import { addTeamServer, addTeamInput, getTeamsServer } from '../../features/dashboard/dashboardSlice';
import SwitchButton from '../common-switch-button/SwitchButton';
import pieimage from './pdffile/2620_TeamDNA_Graph.png';
import imgone from './pdffile/images/Powered by C4D-Logo-small.png';
import imgtwo from './pdffile/images/Team DyNAmics report-Logo Small.png';
import imgthree from './pdffile/2620_TeamDNA_Graph.png';
import image from './pdffile/images/Team DyNAmics report-Logo Blue Strip.png';
import ColoursRed from './pdffile/images/Key Circled Colours-Red.png';
import ColoursBlue from './pdffile/images/Key Circled Colours-Blue.png';
import ColoursYellow from './pdffile/images/Key Circled Colours-Yellow.png';
import ColoursGreen from './pdffile/images/Key Circled Colours-Green.png';
import RedAccountabilityIcon from './pdffile/images/Red-Accountability Icon.png';
import RedPurposeIcon from './pdffile/images/Red-Purpose Icon.png';
import reportLogoSmall from './pdffile/images/Team DyNAmics report-Logo Small.png';
import RedDecisionMaking from './pdffile/images/Red-Decision Making Icon.png';
import RedVisionIcon from './pdffile/images/Red-Vision Icon.png';
import SeperationLine from './pdffile/images/Seperation Line.png';
import RedStatus from './pdffile/images/RedStatus.png';
import bluePlanning from './pdffile/images/Blue-Planning Icon.png';
import blueProcesses from './pdffile/images/Blue-Processes Icon.png';
import blueReflection from './pdffile/images/Blue-Reflection Icon.png';
import blueRoles from './pdffile/images/Blue-Roles & Skills Icon.png';
import BlueStatus from './pdffile/images/BlueStatus.png';
import yellowStatus from './pdffile/images/YellowStatus.png';
import greenStatus from './pdffile/images/GreenStatus.png';
import YellowCollaboration from './pdffile/images/Yellow-Collaboration Icon.png';
import YellowEnvironemnet from './pdffile/images/Yellow-Environment Icon.png';
import YellowTransformation from './pdffile/images/Yellow-Transformation Icon.png';
import GreenCommitment from './pdffile/images/Green-Commitment Icon.png';
import GreenDiversity from './pdffile/images/Green-Diversity Icon.png';
import GreenTeam from './pdffile/images/Green-Team Meetings Icon.png';
import GreenTrust from './pdffile/images/Green-Trust Icon.png';
import colorGrey from './pdffile/images/Key Circled Colours-Grey.png';
import './reportdialog.css';
//PDF Style
import './pdffile/Style-Default.css';
import './pdffile/Style-Profile-New.css';
import './pdffile/Style-TeamDNA.css';


const AddTeamDialog = (props) => {
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(true)
    const { usertoken } = useSelector((store) => store.login);
    const { addTeamInputValue } = useSelector((store) => store.dashboard)
    return (
        <>
            <DialogLayoutWrapper open={props.open} title={props.title} show={true} btnNameOne={props.btnNameOne} clickBtnNameOne={props.clickBtnNameOne} clickBtnNameTwo={props.clickBtnNameTwo} btnNameTwo={props.btnNameTwo} close={props.close}>
                <div id='pdf-view'>
                    {/* <div className="DNA-front-page" style={{ position: 'absolute', top: '0px', right: '0px' }} /> */}
                    {/* <div style={{ position: 'absolute', align: 'right', left: '400px', top: '50px' }}>
                        <img src={image} border={0} />
                    </div> */}
                    <div style={{ position: 'absolute', left: '340px', top: '510px', textAlign: 'right', width: '400px' }}>
                        {/* <font className="fs-32"><b>Team DNA</b><br />
                        </font>
                        <font className="fs-16">24/07/2022</font> */}
                        <br /><br /><br /><br /><br />
                        <div style={{ position: 'absolute', left: '150px', top: '70px' }}>
                            &nbsp;
                        </div>
                    </div>
                    <div style={{ position: 'absolute', left: '500px', top: '800px' }}>
                        <img src={imgone} border={0} width="250px" />
                    </div>
                    <div className="DNA-front-bottom-page" style={{ position: 'absolute', top: '0px', right: '0px' }} />
                    <div className="page">&nbsp;</div>
                    <div className="statement2">
                        <div align="right"><img src={imgtwo} />&nbsp;&nbsp;&nbsp;</div>
                        <table width="90%" border={0}>
                            <tbody>
                                <tr>
                                    <td align="left" colSpan={2}>
                                        <font className="fs-32">Category Average Percentage</font>
                                    </td>
                                </tr>
                                <tr>
                                    <td coslpan={2}>
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td width="55%">
                                        <table width="100%" className="DNA-table">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <img src={pieimage} border={0} width="400px" />
                                                    </td>
                                                </tr>
                                                <tr><td><br /><br /><br /><br /></td></tr>
                                                <tr>
                                                    <td width="10px">
                                                        <img src={ColoursRed} border={0} width="20px" />
                                                    </td>
                                                    <td align="left">
                                                        <b>Strategic Action</b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10px">
                                                        <img src={ColoursBlue} border={0} width="20px" />
                                                    </td>
                                                    <td align="left">
                                                        <b>Framework Mapping</b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10px">
                                                        <img src={ColoursYellow} border={0} width="20px" />
                                                    </td>
                                                    <td align="left">
                                                        <b>Creative Interactions</b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10px">
                                                        <img src={ColoursGreen} border={0} width="20px" />
                                                    </td>
                                                    <td align="left">
                                                        <b>Coactive Connections</b>
                                                    </td>
                                                </tr>
                                                <tr><td><br /><br /><br /><br /><br /></td></tr>
                                                <tr>
                                                    <td colSpan={2} height="2px" align="left">
                                                        <img src={SeperationLine} border={0} width="80%" />
                                                    </td>
                                                </tr>
                                                <tr><td><br /><br /><br /><br /></td></tr>
                                                <tr>
                                                    <td colSpan={2} align="left"> <br />
                                                        The Team DyNAmics Report is produced based on the perception of individual respondents and holds up a mirror on how effective teamwork is. The 48 statements answered, feed into 16 Elements, 3 statements per Element.  These 16 Elements feed into 4 Categories, 4 per Category.  <br /><br />The Team DyNAmics Effectiveness percentage represents the overall perception of how effective teamwork is.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td width="45%" valign="top">
                                        <table width="100%" cellSpacing={0} cellPadding={2} border={0}>
                                            <tbody><tr><td colSpan={2}>&nbsp;<br /><br /></td></tr>
                                                <tr className="DNA-table">
                                                    <td colSpan={2} align="left" className="FontLargeBlack">
                                                        <b>Respondents: 8</b>
                                                    </td>
                                                </tr>
                                                <tr className="DNA-table">
                                                    <td colSpan={2} align="left" className="FontLargeBlack">
                                                        <b>Team DyNAmics Effectiveness: 61%</b>
                                                    </td>
                                                </tr>
                                                <tr><td colSpan={2}>&nbsp;<br /><br /><br /></td></tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td className="TableFill" nowrap><font className="fs-16" color="white">&nbsp;Top 8 Elements</font></td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td className="TableL">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td className="TableL">
                                                        <table border={0} className="fs-16 fw-500">
                                                            <tbody><tr><td nowrap><img src={ColoursRed} border={0} width="12px" />&nbsp;Purpose</td><td align="left">75%</td></tr><tr /><tr><td nowrap><img src={ColoursGreen} border={0} width="12px" />&nbsp;Trust</td><td align="left">68%</td></tr><tr /><tr><td nowrap><img src={ColoursBlue} border={0} width="12px" />&nbsp;Roles &amp; Skills</td><td align="left">67%</td></tr><tr /><tr><td nowrap><img src={ColoursYellow} border={0} width="12px" />&nbsp;Environment</td><td align="left">67%</td></tr><tr /><tr><td nowrap><img src={ColoursBlue} border={0} width="12px" />&nbsp;Reflection</td><td align="left">66%</td></tr><tr /><tr><td nowrap><img src={ColoursBlue} border={0} width="12px" />&nbsp;Processes</td><td align="left">66%</td></tr><tr /><tr><td nowrap><img src={ColoursRed} border={0} width="12px" />&nbsp;Vision</td><td align="left">65%</td></tr><tr /><tr><td nowrap><img src={ColoursRed} border={0} width="12px" />&nbsp;Decision-Making</td><td align="left">63%</td></tr><tr>
                                                            </tr></tbody></table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td className="TableL">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td className="TableFill" nowrap><font className="fs-16" color="white">&nbsp;Bottom 8 Elements</font></td>
                                                    <td className="TableL">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td className="TableL">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <table border={0} className="fs-16 fw-500">
                                                            <tbody><tr><td nowrap><img src={ColoursYellow} border={0} width="12px" />&nbsp;Communication</td><td align="left">60%</td></tr><tr /><tr><td nowrap><img src={ColoursGreen} border={0} width="12px" />&nbsp;Commitment</td><td align="left">59%</td></tr><tr /><tr><td nowrap><img src={ColoursYellow} border={0} width="12px" />&nbsp;Transformation</td><td align="left">58%</td></tr><tr /><tr><td nowrap><img src={ColoursYellow} border={0} width="12px" />&nbsp;Collaboration</td><td align="left">56%</td></tr><tr /><tr><td nowrap><img src={ColoursGreen} border={0} width="12px" />&nbsp;Team Meetings</td><td align="left">54%</td></tr><tr /><tr><td nowrap><img src={ColoursBlue} border={0} width="12px" />&nbsp;Planning</td><td align="left">53%</td></tr><tr /><tr><td nowrap><img src={ColoursGreen} border={0} width="12px" />&nbsp;Diversity</td><td align="left">53%</td></tr><tr /><tr><td nowrap><img src={ColoursRed} border={0} width="12px" />&nbsp;Accountability</td><td align="left">52%</td></tr><tr>
                                                            </tr></tbody></table>
                                                    </td>
                                                    <td className="TableL">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td className="TableL">&nbsp;</td>
                                                </tr>
                                            </tbody></table>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div className="page">&nbsp;</div>
                    <div className="statement2">
                        <div align="right"><img src={reportLogoSmall} />&nbsp;&nbsp;&nbsp;</div>
                        <table width="90%" border={0}>
                            <tbody><tr>
                                <td align="left" colSpan={2}>
                                    <font color="#000000" className="fs-32">Tasks vs Relationships</font>
                                </td>
                            </tr>
                                <tr>
                                    <td width="100%" colSpan={2}>
                                        <table className="DNA-table" width="658px">
                                            <tbody>
                                                <tr style={{ fontSize: 'small' }}>
                                                    <td colSpan={2} valign="bottom" align="center" style={{ fontSize: '20px' }}>Tasks 63%</td>
                                                    <td colSpan={3}>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} className="TableTask" width="329px" height={236} align="center" valign="bottom">
                                                        <div className="FontLarge">63%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;64%</div>
                                                        <br /><br /><br />
                                                        Framework Mapping&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Strategic Action<br /><br /><br /><br />
                                                    </td>
                                                    <td colSpan={2} className="TableRelation" width="329px" height={236} align="center" valign="top" nowrap>
                                                        <br /><br />Creative Interactions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coactive Connections
                                                        <br /><br /><br /><br /><br />
                                                        <div className="FontLarge">60%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;58%</div>
                                                    </td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr style={{ fontSize: 'small' }}>
                                                    <td colSpan={2}>&nbsp;</td>
                                                    <td colSpan={2} valign="top" align="center" style={{ fontSize: '20px' }}>Relationships  59%</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <table cellPadding={1} cellSpacing={1} border={0} width="100%">
                                        <tbody>
   <tr>
     <td align="right" className="FontSmall" valign="bottom">0</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td align="left" className="FontSmall" valign="bottom">10</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td align="left" className="FontSmall" valign="bottom">20</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td align="left" className="FontSmall" valign="bottom">30</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td align="left" className="FontSmall" valign="bottom">40</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td align="left" className="FontSmall" valign="bottom">50</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td align="left" className="FontSmall" valign="bottom">60</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td align="left" className="FontSmall" valign="bottom">70</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td align="left" className="FontSmall" valign="bottom">80</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td align="left" className="FontSmall" valign="bottom">90</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td>&nbsp;</td>
     <td align="left" className="FontSmall" valign="bottom">100</td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Purpose</p>
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Trust</p>
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Roles &amp; Skills</p>
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Environment</p>
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Reflection</p>
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Processes</p>
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Vision</p>
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Decision-Making</p>
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Communication</p>
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Commitment</p>
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Transformation</p>
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Collaboration</p>
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursYellow} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Team Meetings</p>
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Planning</p>
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursBlue} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext fs-16">Diversity</p>
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursGreen} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
   <tr>
     <td nowrap>
       <p className="verticaltext">Accountability</p>
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={ColoursRed} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
     <td nowrap>
       <img src={colorGrey} border={0} width="10px" />
     </td>
   </tr>
 </tbody>   
                                        </table>
                                    </td>
                                </tr>
                                <tr><td colSpan={2}>&nbsp;</td></tr>
                                <tr>
                                    <td height="2px" align="left">
                                        <img src={SeperationLine} border={0} width="180px" />
                                    </td>
                                    <td><font color="#000000" className="fs-24"><b>Element Order</b></font></td>
                                </tr>
                                <tr>
                                    <td width="10px" valign="top">
                                        <table width="100%">
                                            <tbody><tr>
                                                <td width="10px">
                                                    <img src={ColoursRed} border={0} width="20px" />
                                                </td>
                                                <td align="left" nowrap>
                                                    <b>Strategic Action</b>
                                                </td>
                                            </tr>
                                                <tr>
                                                    <td width="10px">
                                                        <img src={ColoursBlue} border={0} width="20px" />
                                                    </td>
                                                    <td align="left" nowrap>
                                                        <b>Framework Mapping</b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10px">
                                                        <img src={ColoursYellow} border={0} width="20px" />
                                                    </td>
                                                    <td align="left" nowrap>
                                                        <b>Creative Interactions</b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10px">
                                                        <img src={ColoursGreen} border={0} width="20px" />
                                                    </td>
                                                    <td align="left" nowrap>
                                                        <b>Coactive Connections</b>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </td>
                                    <td className="DNA-table" valign="top">
                                        The graph above is a visual representation of how the 16 Elements have been ranked, from highest at the top to lowest at the bottom.  The colours provide an indication as to whether similar Elements are clustered together or spread out.  <br /><br />These Elements, feed into the Tasks and Relationships percentages at the top of the page.  The  percentages provide an understanding of whether there is an imbalance between the Tasks and Relationships related Elements.<br />
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div className="page">&nbsp;</div>
                    <div className="statement2">
                        <div align="right"><img src={reportLogoSmall} />&nbsp;&nbsp;&nbsp;</div>
                        <table width="90%">
                            <tbody><tr>
                                <td align="left" colSpan={2}>
                                    <font color="#e30613" className="fs-32 clr-red fw-600">Strategic Action (64%)</font>
                                </td>
                            </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <table border={0} cellPadding={4} cellSpacing={0} width="100%">
                                            <tbody><tr>
                                                <td width="10%">
                                                    <img src={RedAccountabilityIcon} border={0} width="60px" />
                                                </td>
                                                <td valign="middle" align="left" width="90%">
                                                    <font className="fs-32" >Accountability</font><br />
                                                    <span className='fs-14'>Individuals taking personal responsibility for their actions and behaviours.</span>
                                                </td>
                                            </tr>
                                                <tr>
                                                    <td width="10%">
                                                        <img src={RedDecisionMaking} border={0} width="60px" />
                                                    </td>
                                                    <td valign="middle" align="left" width="90%">
                                                        <font className="fs-32">Decision-Making</font><br />
                                                        <span className='fs-14'>Clarity about who has the authority to make what decisions and when.</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10%">
                                                        <img src={RedPurposeIcon} border={0} width="60px" />
                                                    </td>
                                                    <td valign="middle" align="left" width="90%">
                                                        <font className="fs-32">Purpose</font><br />
                                                        <span className='fs-14'>The understanding of what the team exists to do, what this enables and the benefits this delivers.</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10%">
                                                        <img src={RedVisionIcon} border={0} width="60px" /><br />
                                                    </td>
                                                    <td valign="middle" align="left" width="90%">
                                                        <font className="fs-32">Vision</font><br />
                                                        <span className='fs-14'>The longer-term, aspirational view of what the team would like to achieve.</span>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="100%" height="3px" align="left" colSpan={2}>
                                        <img src={SeperationLine} border={0} width="40%" />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left">
                                        <font color="#e30613" className='fs-24'>Strategic Action</font>
                                    </td>
                                    <td width="40%" align="center"><font size="4px">Score</font></td>
                                </tr>
                                <tr>
                                    <td align="left" colSpan={2}>
                                        <table border={0} cellPadding={3} cellSpacing={0} width="100%" className="DNA-table">
                                            {/*--------- Accountability -----*/}
                                            <tbody><tr className="DNA-head">
                                                <td width="70%" colSpan={2}>
                                                    <font size="4px">Accountability (52%)</font>
                                                </td>
                                                <td><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                <td><font size="4px" className="ChartL">&nbsp;Avg&nbsp;</font></td>
                                                <td><font size="4px" className="ChartL">Min</font></td>
                                                <td><font size="4px" className="ChartL">Max</font></td>
                                                <td><font size="4px" className="ChartL">Rank</font></td>
                                            </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Individuals take personal responsibility for their actions
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB">
                                                        <tbody><tr>
                                                            <td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td>
                                                            <td valign="bottom"><img src={RedStatus} width="6px" height="9px" /></td>
                                                            <td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td>
                                                            <td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td>
                                                            <td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td>
                                                            <td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td>
                                                            <td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td>
                                                            <td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td>
                                                            <td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td>
                                                            <td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    </td>
                                                    <td align="center" className="ChartL">50%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">38</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We fail and succeed as a team
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">57%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">31</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We are held accountable by other team members
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">49%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">42</td>
                                                </tr>
                                                {/*--------- Decision -----*/}
                                                <tr className="DNA-head">
                                                    <td width="70%" className="ChartT" colSpan={2}>
                                                        <font size="4px">Decision-Making (63%)</font>
                                                    </td>
                                                    <td className="ChartT"><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                    <td className="ChartT"><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                    <td className="ChartLT"><font size="4px">Min</font></td>
                                                    <td className="ChartLT"><font size="4px">Max</font></td>
                                                    <td className="ChartLT"><font size="4px">Rank</font></td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        People have appropriate authority to make decisions
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="9px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">50%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">8</td>
                                                    <td align="center" className="ChartL">38</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        I know who to speak to if I cannot make a decision
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">54%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">35</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        When a decision is made, the whole team commit to it
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="9px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">84%</td>
                                                    <td align="center" className="ChartL">5</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">1</td>
                                                </tr>
                                                {/*--------- Purpose -----*/}
                                                <tr className="DNA-head">
                                                    <td width="70%" className="ChartT" colSpan={2}>
                                                        <font size="4px">Purpose (75%)</font>
                                                    </td>
                                                    <td className="ChartT"><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                    <td className="ChartT"><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                    <td className="ChartLT"><font size="4px">Min</font></td>
                                                    <td className="ChartLT"><font size="4px">Max</font></td>
                                                    <td className="ChartLT"><font size="4px">Rank</font></td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        I know what I need to do to support the delivery of team objectives
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">71%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">7</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Everyone knows our team objectives and purpose
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">76%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">4</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        I have regular meetings to review progress against my objectives
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">77%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">3</td>
                                                </tr>
                                                {/*--------- Vision -----*/}
                                                <tr className="DNA-head">
                                                    <td width="70%" className="ChartT" colSpan={2}>
                                                        <font size="4px">Vision (65%)</font>
                                                    </td>
                                                    <td className="ChartT"><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                    <td className="ChartT"><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                    <td className="ChartLT"><font size="4px">Min</font></td>
                                                    <td className="ChartLT"><font size="4px">Max</font></td>
                                                    <td className="ChartLT"><font size="4px">Rank</font></td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We have an inspirational and aspirational vision for our team
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">67%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">13</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We all know what our team vision is
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">67%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">13</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Our vision adds value to the wider organisation
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="9px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={RedStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">61%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">22</td>
                                                </tr>
                                            </tbody></table>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div className="page">&nbsp;</div>
                    <div className>
                        <div align="right"><img src={reportLogoSmall} />&nbsp;&nbsp;&nbsp;</div>
                        <table width="90%">
                            <tbody><tr>
                                <td align="left" colSpan={2}>
                                    <font color="#3287c8" className="fs-32 fw-600">Framework Mapping (63%)</font>
                                </td>
                            </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <table border={0} cellPadding={4} cellSpacing={0} width="100%">
                                            <tbody><tr>
                                                <td width="10%">
                                                    <img src={bluePlanning} border={0} width="60px" />
                                                </td>
                                                <td valign="middle" align="left" width="90%">
                                                    <font className="fs-32">Planning</font><br />
                                                    Knowing what needs to be done, by whom and by when.
                                                </td>
                                            </tr>
                                                <tr>
                                                    <td width="10%">
                                                        <img src={blueProcesses} border={0} width="60px" />
                                                    </td>
                                                    <td valign="middle" align="left" width="90%">
                                                        <font className="fs-32">Processes</font><br />
                                                        The rules, regulations and guidelines by which tasks are achieved.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10%">
                                                        <img src={blueReflection} border={0} width="60px" />
                                                    </td>
                                                    <td valign="middle" align="left" width="90%">
                                                        <font className="fs-32">Reflection</font><br />
                                                        Reviewing what has happened within your team, to learn from things that have gone well and not so well.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10%">
                                                        <img src={blueRoles} border={0} width="60px" /><br />
                                                    </td>
                                                    <td valign="middle" align="left" width="90%">
                                                        <font className="fs-32">Roles &amp; Skills</font><br />
                                                        Understanding who is best suited to undertake a task, both technically and behaviourally.
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="100%" height="3px" align="left" colSpan={2}>
                                        <img src={SeperationLine} border={0} width="40%" />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left">
                                        <font color="#3287c8" className="fs-24">Framework Mapping</font>
                                    </td>
                                    <td width="40%" align="center"><font className="fs-14">Score</font></td>
                                </tr>
                                <tr>
                                    <td align="left" colSpan={2}>
                                        <table border={0} cellPadding={1} cellSpacing={0} width="100%" className="DNA-table">
                                            {/*--------- PLANNING -----*/}
                                            <tbody><tr className="DNA-head">
                                                <td width="70%" colSpan={2}>
                                                    <font size="4px">Planning (53%)</font>
                                                </td>
                                                <td><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                <td><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                <td><font size="4px" className="ChartL">Min</font></td>
                                                <td><font size="4px" className="ChartL">Max</font></td>
                                                <td><font size="4px" className="ChartL">Rank</font></td>
                                            </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We all have the opportunity to be involved in developing our team plans
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">50%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">38</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We have team plans that are regularly reviewed and updated
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">64%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">17</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We have robust, accurate information to enable us to review our team performance
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="9px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">44%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">48</td>
                                                </tr>
                                                {/*--------- PROCESSES -----*/}
                                                <tr className="DNA-head">
                                                    <td width="70%" className="ChartT" colSpan={2}>
                                                        <font size="4px">Processes (66%)</font>
                                                    </td>
                                                    <td className="ChartT"><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                    <td className="ChartT"><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                    <td className="ChartLT"><font size="4px">Min</font></td>
                                                    <td className="ChartLT"><font size="4px">Max</font></td>
                                                    <td className="ChartLT"><font size="4px">Rank</font></td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We have clearly documented procedures in our team
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">56%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">34</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We have processes in place that are effective and support what we do
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="9px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">67%</td>
                                                    <td align="center" className="ChartL">3</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">13</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        I have the systems and tools to do my job effectively
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="9px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">76%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">4</td>
                                                </tr>
                                                {/*--------- REFLECTION -----*/}
                                                <tr className="DNA-head">
                                                    <td width="70%" className="ChartT" colSpan={2}>
                                                        <font size="4px">Reflection (66%)</font>
                                                    </td>
                                                    <td className="ChartT"><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                    <td className="ChartT"><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                    <td className="ChartLT"><font size="4px">Min</font></td>
                                                    <td className="ChartLT"><font size="4px">Max</font></td>
                                                    <td className="ChartLT"><font size="4px">Rank</font></td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        My performance is discussed and changes agreed regularly
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">51%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">37</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Mistakes are seen as opportunities to do things differently in the future
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">63%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">21</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We regularly reflect and review our performance as a team
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="12px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">83%</td>
                                                    <td align="center" className="ChartL">3</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">2</td>
                                                </tr>
                                                {/*--------- ROLESKILLS -----*/}
                                                <tr className="DNA-head">
                                                    <td width="70%" className="ChartT" colSpan={2}>
                                                        <font size="4px">Roles &amp; Skills (67%)</font>
                                                    </td>
                                                    <td className="ChartT"><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                    <td className="ChartT"><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                    <td className="ChartLT"><font size="4px">Min</font></td>
                                                    <td className="ChartLT"><font size="4px">Max</font></td>
                                                    <td className="ChartLT"><font size="4px">Rank</font></td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        I understand the purpose of my role and how it support team objectives
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">57%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">31</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        I frequently discuss my learning and development needs to help me develop
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">71%</td>
                                                    <td align="center" className="ChartL">3</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">7</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        I understand what other team members do and skills they have
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={BlueStatus} width="6px" height="6px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">71%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">7</td>
                                                </tr>
                                            </tbody></table>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div className="page">&nbsp;</div>
                    <div className>
                        <div align="right"><img src={reportLogoSmall} />&nbsp;&nbsp;&nbsp;</div>
                        <table width="90%">
                            <tbody><tr>
                                <td align="left" colSpan={2}>
                                    <font color="#efc455"  className="fs-32 fw-600">Creative Interactions (60%)</font>
                                </td>
                            </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <table border={0} cellPadding={4} cellSpacing={0} width="100%">
                                            <tbody><tr>
                                                <td width="10%">
                                                    <img src={YellowCollaboration} border={0} width="60px" />
                                                </td>
                                                <td valign="middle" align="left" width="90%">
                                                    <font className="fs-32">Collaboration</font><br />
                                                    A joint effort between individuals, whereby the skills and knowledge of more than one person is required to achieve a common goal.
                                                </td>
                                            </tr>
                                                <tr>
                                                    <td width="10%">
                                                        <img src={YellowCollaboration} border={0} width="60px" />
                                                    </td>
                                                    <td valign="middle" align="left" width="90%">
                                                        <font className="fs-32">Communication</font><br />
                                                        The life-blood of the team, enabling information to be shared, ideas generated, discussed and decisions made.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10%">
                                                        <img src={YellowEnvironemnet} border={0} width="60px" />
                                                    </td>
                                                    <td valign="middle" align="left" width="90%">
                                                        <font className="fs-32">Environment</font><br />
                                                        The atmosphere and culture that exists in the working environment.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10%">
                                                        <img src={YellowTransformation} border={0} width="60px" /><br />
                                                    </td>
                                                    <td valign="middle" align="left" width="90%">
                                                        <font className="fs-32">Transformation</font><br />
                                                        The implementation of ideas to change how things are done to become more effective and efficient.
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="100%" height="3px" align="left" colSpan={2}>
                                        <img src={SeperationLine} border={0} width="40%" />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left">
                                        <font color="#efc455" className="fs-32">Creative Interactions</font>
                                    </td>
                                    <td width="40%" align="center"><font size="4px">Score</font></td>
                                </tr>
                                <tr>
                                    <td align="left" colSpan={2}>
                                        <table border={0} cellPadding={3} cellSpacing={0} width="100%" className="DNA-table">
                                            {/*--------- COLLABOR -----*/}
                                            <tbody><tr className="DNA-head">
                                                <td width="70%" colSpan={2}>
                                                    <font size="4px">Collaboration (56%)</font>
                                                </td>
                                                <td><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                <td><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                <td><font size="4px" className="ChartL">Min</font></td>
                                                <td><font size="4px" className="ChartL">Max</font></td>
                                                <td><font size="4px" className="ChartL">Rank</font></td>
                                            </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We work well as a team to deliver results
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">61%</td>
                                                    <td align="center" className="ChartL">3</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">22</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Team members put team success above personal success
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">47%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">45</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Support is readily given when a colleague needs it
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">59%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">28</td>
                                                </tr>
                                                {/*--------- COMMUNICATION -----*/}
                                                <tr className="DNA-head">
                                                    <td width="70%" className="ChartT" colSpan={2}>
                                                        <font size="4px">Communication (60%)</font>
                                                    </td>
                                                    <td className="ChartT"><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                    <td className="ChartT"><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                    <td className="ChartLT"><font size="4px">Min</font></td>
                                                    <td className="ChartLT"><font size="4px">Max</font></td>
                                                    <td className="ChartLT"><font size="4px">Rank</font></td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Everyone has the opportunity to speak up and be heard
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">59%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">28</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        I receive the information I need, to support what I do, in a timely fashion
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">61%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">22</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Communication within the team is open and honest
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">61%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">22</td>
                                                </tr>
                                                {/*--------- ENVIRONMENT -----*/}
                                                <tr className="DNA-head">
                                                    <td width="70%" className="ChartT" colSpan={2}>
                                                        <font size="4px">Environment (67%)</font>
                                                    </td>
                                                    <td className="ChartT"><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                    <td className="ChartT"><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                    <td className="ChartLT"><font size="4px">Min</font></td>
                                                    <td className="ChartLT"><font size="4px">Max</font></td>
                                                    <td className="ChartLT"><font size="4px">Rank</font></td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        There is a good working culture and atmosphere
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="9px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">70%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">11</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        The needs of individuals are recognised
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">64%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">17</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We collectively celebrate the success we have as a team
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">67%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">13</td>
                                                </tr>
                                                {/*--------- TRANSFORMATION -----*/}
                                                <tr className="DNA-head">
                                                    <td width="70%" className="ChartT" colSpan={2}>
                                                        <font size="4px">Transformation (58%)</font>
                                                    </td>
                                                    <td className="ChartT"><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                    <td className="ChartT"><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                    <td className="ChartLT"><font size="4px">Min</font></td>
                                                    <td className="ChartLT"><font size="4px">Max</font></td>
                                                    <td className="ChartLT"><font size="4px">Rank</font></td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We are encouraged to find more effective ways of working
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="9px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">64%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">17</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        New ideas are valued and appreciated
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">49%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL" >9</td>
                                                    <td align="center" className="ChartL">42</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        We make time to explore and discuss new ways of doing things as a team
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={yellowStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">60%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">27</td>
                                                </tr>
                                            </tbody></table>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div className="page">&nbsp;</div>
                    <div className>
                        <div align="right"><img src={reportLogoSmall} />&nbsp;&nbsp;&nbsp;</div>
                        <table width="90%">
                            <tbody><tr>
                                <td align="left" colSpan={2}>
                                    <font color="#00a439" className="fs-32">Coactive Connections (58%)</font>
                                </td>
                            </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <table border={0} cellPadding={4} cellSpacing={0} width="100%">
                                            <tbody><tr>
                                                <td width="10%">
                                                    <img src={GreenCommitment} border={0} width="60px" />
                                                </td>
                                                <td valign="middle" align="left" width="90%">
                                                    <font className="fs-32">Commitment</font><br />
                                                    The energy, effort and time that individuals are prepared to invest in the team for it to achieve success.
                                                </td>
                                            </tr>
                                                <tr>
                                                    <td width="10%">
                                                        <img src={GreenDiversity} border={0} width="60px" />
                                                    </td>
                                                    <td valign="middle" align="left" width="90%">
                                                        <font className="fs-32">Diversity</font><br />
                                                        The recognition and appreciation of differences between individual team members in their behaviours, styles and skills.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10%">
                                                        <img src={GreenTeam} border={0} width="60px" />
                                                    </td>
                                                    <td valign="middle" align="left" width="90%">
                                                        <font className="fs-32">Team Meetings</font><br />
                                                        The mechanism by which regular communication occurs between team members at an agreed time and with an agreed format.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="10%">
                                                        <img src={GreenTrust} border={0} width="60px" /><br />
                                                    </td>
                                                    <td valign="middle" align="left" width="90%">
                                                        <font className="fs-32">Trust</font><br />
                                                        The emotional bond that glues the team together.
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="100%" height="3px" align="left" colSpan={2}>
                                        <img src={SeperationLine} border={0} width="40%" />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left">
                                        <font color="#00a439" className="fs-32">Coactive Connections</font>
                                    </td>
                                    <td width="40%" align="center"><font size="4px">Score</font></td>
                                </tr>
                                <tr>
                                    <td align="left" colSpan={2}>
                                        <table border={0} cellPadding={3} cellSpacing={0} width="100%" className="DNA-table">
                                            {/*--------- COMMIT -----*/}
                                            <tbody><tr className="DNA-head">
                                                <td width="70%" colSpan={2}>
                                                    <font size="4px">Commitment (59%)</font>
                                                </td>
                                                <td><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                <td><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                <td><font size="4px" className="ChartL">Min</font></td>
                                                <td><font size="4px" className="ChartL">Max</font></td>
                                                <td><font size="4px" className="ChartL">Rank</font></td>
                                            </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        I feel proud to be part of the team
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="9px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">69%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">12</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Team members are commited to the success of the team
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="9px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">47%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">45</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Individuals have a loyalty towards the team
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">61%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">22</td>
                                                </tr>
                                                {/*--------- DIVERSITY -----*/}
                                                <tr className="DNA-head">
                                                    <td width="70%" className="ChartT" colSpan={2}>
                                                        <font size="4px">Diversity (53%)</font>
                                                    </td>
                                                    <td className="ChartT"><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                    <td className="ChartT"><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                    <td className="ChartLT"><font size="4px">Min</font></td>
                                                    <td className="ChartLT"><font size="4px">Max</font></td>
                                                    <td className="ChartLT"><font size="4px">Rank</font></td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        I am appreciated by team members, for who I am and what I deliver
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">54%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">8</td>
                                                    <td align="center" className="ChartL">35</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Differences of opinion, in our team,  are expressed in an adult way
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="9px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">56%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">33</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Uniqueness in behavioural style is valued and encouraged
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="9px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">47%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">45</td>
                                                </tr>
                                                {/*--------- TEAMMEET -----*/}
                                                <tr className="DNA-head">
                                                    <td width="70%" className="ChartT" colSpan={2}>
                                                        <font size="4px">Team Meetings (54%)</font>
                                                    </td>
                                                    <td className="ChartT"><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                    <td className="ChartT"><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                    <td className="ChartLT"><font size="4px">Min</font></td>
                                                    <td className="ChartLT"><font size="4px">Max</font></td>
                                                    <td className="ChartLT"><font size="4px">Rank</font></td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Our team meetings are effective and produce actions
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">50%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">38</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Relevant information is provided in good time to prepare for team meetings
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">49%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">9</td>
                                                    <td align="center" className="ChartL">42</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        Our team meetings start and end on time
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">64%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">17</td>
                                                </tr>
                                                {/*--------- TRUST -----*/}
                                                <tr className="DNA-head">
                                                    <td width="70%" className="ChartT" colSpan={2}>
                                                        <font size="4px">Trust (68%)</font>
                                                    </td>
                                                    <td className="ChartT"><table cellPadding={1} cellSpacing={0} width="100%" className="DNA-score"><tbody><tr><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td><td>10</td></tr></tbody></table></td>
                                                    <td className="ChartT"><font size="4px">&nbsp;Avg&nbsp;</font></td>
                                                    <td className="ChartLT"><font size="4px">Min</font></td>
                                                    <td className="ChartLT"><font size="4px">Max</font></td>
                                                    <td className="ChartLT"><font size="4px">Rank</font></td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        I feel comfortable sharing my views with other team members
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">59%</td>
                                                    <td align="center" className="ChartL">2</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">28</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        I feel comfortable talking about how I am feeling with my colleagues
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">71%</td>
                                                    <td align="center" className="ChartL">3</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">7</td>
                                                </tr>
                                                <tr>
                                                    <td width="3%">&nbsp;</td>
                                                    <td width="70%">
                                                        In our team, ideas are challenged, not individuals
                                                    </td>
                                                    <td><table cellPadding={1} cellSpacing={0} width="100%" className="ChartB"><tbody><tr><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="0px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="3px" /></td><td valign="bottom"><img src={greenStatus} width="6px" height="6px" /></td></tr></tbody></table></td>
                                                    <td align="center" className="ChartL">74%</td>
                                                    <td align="center" className="ChartL">3</td>
                                                    <td align="center" className="ChartL">10</td>
                                                    <td align="center" className="ChartL">6</td>
                                                </tr>
                                            </tbody></table>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div className="page">&nbsp;</div>
                    <div className>
                        <div align="right"><img src={reportLogoSmall} />&nbsp;&nbsp;&nbsp;</div>
                        <table width="95%" className="DNA-table">
                            <tbody><tr>
                                <td align="left">
                                    <font color="#000000" className="fs-32"><b>Team DyNAmics - Action Plan</b></font><b>
                                    </b></td>
                            </tr>
                                <tr>
                                    <td>
                                        <table border={0} cellPadding={0} cellSpacing={6} width="100%" className="FontMedium">
                                            <tbody><tr>
                                                <td align="left" valign="top" width="50%"><font size={4}><b>Track</b></font> <br />Having read your Team DyNAmics Report, what are your summary observations and conclusions?&nbsp;</td>
                                                <td align="left" valign="top" width="50%"><font size={4}><b>Evaluate</b></font> <br />Having read and discussed the detail of the report with your colleagues in your team, what are the Elements that you have decided need to be worked on to increase your team effectiveness?&nbsp;</td>
                                            </tr>
                                                <tr>
                                                    <td align="left" width="50%" height="180px" className="grey-box">&nbsp;</td>
                                                    <td align="left" width="50%" height="180px" className="grey-box">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td align="left" valign="top" width="50%"><font size={4}><b>Amend</b></font> <br />What practical ideas have you and your colleagues discussed and agreed to implement?&nbsp;</td>
                                                    <td align="left" valign="top" width="50%"><font size={4}><b>Monitor</b></font> <br />Based on the ideas that you have agreed to implement, who is responsible for taking action and by what date has it been agreed to be implemented by?&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td align="left" width="50%" height="180px" className="grey-box">&nbsp;</td>
                                                    <td align="left" width="50%" height="180px" className="grey-box">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td align="left" colSpan={2}>Based on the above, when do you think that your team should re-measure progress by retaking the Team DyNAmics Report?&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td align="left" colSpan={2} height="70px" className="grey-box">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td align="left" colSpan={2}>What other teams in your organisation do you think would benefit from completing a Team DyNAmics Report?&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td align="left" colSpan={2} height="70px" className="grey-box">&nbsp;</td>
                                                </tr>
                                            </tbody></table>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div className="page">&nbsp;</div>
                </div>
            </DialogLayoutWrapper>
        </>
    )
}

export default AddTeamDialog