import React, { useState } from "react";
import DialogLayoutWrapper from '../Dialog-layout-wrapper/DialogLayoutWrapper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from "@mui/material";
import { setForeName, setSurName, setEmail, editTeamMemServer, teamsMemServer } from "../../features/teams/teamsSlice";
import { useSelector, useDispatch } from "react-redux";

const EditTeamMemberDialog = (props) => {
    const dispatch = useDispatch()
    const {usertoken} = useSelector((store) => store.login)
    const {foreName, surName, email, personId} = useSelector((store) => store.teams)
    const [firstName, setFirstName] = useState(foreName)
    const [lastName, setLastName] = useState(surName)
    const [emailAddr, setEmailAddr] = useState(email)
    return(
        <>
            <DialogLayoutWrapper open={props.open} show = {true} title={'edit team member'} btnNameOne={'update'} clickBtnNameOne={() => {
                let team_id = props.teamId
                dispatch(editTeamMemServer({usertoken,foreName,surName,email,team_id, personId})).then((res) => {
                    
                    dispatch(teamsMemServer({ usertoken,team_id})).then((res)=>{
                        console.log('props.close',res)
                        props.close()
                    })
                })
            }} clickBtnNameTwo={props.close} btnNameTwo={'cancel'} close={props.close}>
                <Grid container rowSpacing={3}>
                    <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                        <label for = "id" className="text-capitalize">first name</label>
                                    </Grid>
                                    <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                        <TextField className='ipt-size' id="id" value={foreName} size="small" onChange={(e) => {
                                            setFirstName(e.target.value)
                                            dispatch(setForeName(e.target.value))
                                        }} variant="outlined" />   
                                    </Grid>
                                </Grid>
                            </Grid>
                    <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                <label for="name" className="text-capitalize">surname</label>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
                                <TextField autoComplete = "off" size="small" value={surName}
                                    className='ipt-size' id="name" variant="outlined" onChange={(event) => {
                                        setLastName(event.target.value)
                                        dispatch(setSurName(event.target.value))
                                    }}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                        <label for = "description" className="text-capitalize">email</label>
                                    </Grid>
                                    <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                        <TextField autoComplete = "off" size="small" value={email}
                                        className='ipt-size' id="name" variant="outlined" onChange={(event) => {
                                            setEmailAddr(event.target.value)
                                            dispatch(setEmail(event.target.value))
                                        }}/>
                                        {/* <TextareaAutosize
                                        aria-label="minimum height"
                                        id = "description"
                                        minRows={10}
                                        placeholder=""
                                        style={{ width: "100%" }}
                                        />   */}
                                        {/* <SwitchButton change = {(event) => setIsActive(event)} checkLabel = {'Active'} notCheckLabel = {'InActive'} checked = {isActive}/> */}
                                        {/* <SwitchButton change = {(event) => props.change(event)} checkLabel = {'Active'} notCheckLabel = {'InActive'} checked = {props.checked}/> */}
                                    </Grid>
                                </Grid>
                    </Grid>
                </Grid>
            </DialogLayoutWrapper>
        </>
    )
}

export default EditTeamMemberDialog