import React,{useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import BtnContent from '../../components/common-content-btn/BtnContent';
import ContentCard from '../../components/common-content-card/ContentCard';
import { useNavigate } from 'react-router';
import Divider from '@mui/material/Divider';
import { setTeamId, setPersonId } from '../../features/surveyQuestions/surveyQuestionSlice'; 
import './questionnaire.css';
import { useSelector, useDispatch } from 'react-redux';
import { surveyCompletedValidatorServer } from '../../features/surveyQuestions/surveyQuestionSlice';
import SnackBar from '../../components/snackBar-layout-wrapper/snackBar-layout-wrapper';
import BackDropLoader from "../../components/common-backdrop-loader/BackdropLoader";

const Questionnaire = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate() 
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [usertoken, setUserToken] = useState('')
    const {teamId, personId, messageCondition, message, messageType, isLoading} = useSelector((store) => store.survey)
    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        setFirstName(atob(params.get('key1')))
        setLastName(atob(params.get('key2')))
        setEmail(atob(params.get('key3')))
        setUserToken(params.get('usertoken'))
        dispatch(setTeamId(params.get('teamId')))
        dispatch(setPersonId(params.get('personId')))
        console.log("params value",params.get('teamId'),params.get('personId'),params.get('firstName'),params.get('surName'),params.get('email'))
        // dispatch(surveyCompletedValidatorServer({usertoken,teamId,personId})).then((res) => {
        //     console.log("response for surveyhCompleted",res)
        // }).catch((err) => {
        //     console.log("error to check",err)
        // })
    },[])
    useEffect(() => {
        if(usertoken != ''){
            dispatch(surveyCompletedValidatorServer({usertoken,teamId,personId})).then((res) => {
                console.log("response for surveyhCompleted",res)
                if(res.payload.Result == 'Survey Already Submitted'){
                    navigate('/surveyCompleted', {replace : false})
                }
            }).catch((err) => {
                console.log("error to check",err)
            })
        }
    },[usertoken, email, teamId, personId])


    const showSurveycontent = () => {
        return(
            <ul>
                <li>Please start by checking that your Personal Details are correct, so that submissions can be confirmed.</li>
                <li>Please complete all 48 statements openly and honestly, about the team you have been asked to submit for.</li>
                <li>Your individual response will remain totally anonymous however, amalgamated with the responses from your colleagues, enabling a Team Synergy Report to be produced.</li>
            </ul>
        )
    }
    return(
        <>
            <Container maxWidth="xl" style = {{marginTop : '65px'}}>
            <BackDropLoader loaderBool = {isLoading} />
                <SnackBar condition={messageCondition} message={message} type={messageType} />
                {/* <BackDropLoader loaderBool={isLoading} /> */}
                <Grid ml={2}
                    // style = {{paddingLeft : '20px'}}
                    // justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }}
                    // alignItems="center"
                    // rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    // className="text-center"
                    >
                    <Stack
                        // direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
                        // spacing={2}
                        // justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }}
                        // alignItems="center" mb={1}
                        >
                            <h2 
                            className = 'text-capitalize'
                            // className='text-capitalize m-0 fw-500 p-5' 
                            // style={{padding: "20px 10px"}}
                            >personal details</h2>
                    </Stack>
                </Grid>
                <Divider/>
                <Grid
                    container
                    // justifyContent = {'around'}
                    // rowSpacing={1}
                    spacing={1}
                    pl={2}
                    pr={2}
                    // justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'center', xl: 'center' }}
                    // alignItems="center"
                    // style = {{padding : '15px 60px'}}
                    // rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                    // className="text-center"
                    >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container justifyContent={'center'}>
                                <Grid item>
                                    <p>Please provide the following details. Mandatory fields are indicated by <span className="text-warning">*</span>.</p>
                                </Grid>
                            </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container alignItems = {'center'} rowSpacing = {1}>
                                    <Grid item xs={12} sm={12} md={4} lg={3} xl={1}>
                                        <label for="name" className="text-capitalize">First Name <span className="text-warning">*</span></label>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={9} xl={2}>
                                        <TextField size="small"
                                            value = {firstName}
                                            disabled
                                            className='ipt-size' id="name" label="" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} xl={1} pl = {{xs : 0, sm : 0, md : 0, lg : 0, xl : 2}}>
                                        <label for="name" className="text-capitalize">Surname <span className="text-warning">*</span></label>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={9} xl={2}>
                                        <TextField size="small"
                                        value={lastName}
                                        disabled
                                            className='ipt-size' id="name" label="" variant="outlined" />
                                    </Grid>
                                </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container alignItems = {'center'} rowSpacing = {1}>
                                    <Grid item xs={12} sm={12} md={4} lg={3} xl={1}>
                                        <label for="name" className="text-capitalize">Email <span className="text-warning">*</span></label>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={9} xl={2}>
                                        <TextField size="small"
                                        value={email}
                                        disabled
                                            className='ipt-size' id="name" label="" variant="outlined" />
                                    </Grid>
                                </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container alignItems = {'center'} justifyContent = {'center'} pr = {{xs: 0, sm : 0, md : 0, lg : 0 , xl : 7}}>
                                    {/* <Grid item xs={12} sm={12} md={8} lg={9} xl={5}>
                                
                                    </Grid> */}
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent = {'center'}> */}
                                        <BtnContent click = {() => navigate('/surveyQuestions',{replace : false, state : {
                                            name : firstName +' '+ lastName,
                                            email : email,
                                            usertoken : usertoken
                                        }})} color = {'primary'}>
                                            <span>next</span>
                                        </BtnContent>
                                    {/* </Grid> */}
                                </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className = "text-capitalize txt-blueclr fw-600 m-0">data privacy policy</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container mt = {1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className = "m-0">The information that you provide will allow a Team Synergy Report to be created.  By submitting your responses, you indicate that you have read the Guide below.</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs = {12} sm = {12} md = {12} lg = {12} xl = {12}>       
                        <Grid container mt = {5}>
                             <Grid item xs = {12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <ContentCard clrwhite={"#000"} bgColor={"rgb(222 235 247)"} cardTitle={"guide"} carstitleclr={true} description={showSurveycontent()}/>
                             </Grid>           
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Questionnaire