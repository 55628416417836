import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
console.log('checkLocalStorage',JSON.parse(localStorage.getItem('teamList')))
const initialState = {
  isLoading: false,
  teamsList: JSON.parse(localStorage.getItem('teamList'))==null?[]:JSON.parse(localStorage.getItem('teamList')),
  addTeamInputValue: '',
  teamId : '',
  messageCondition : false,
  message : '',
  messageType : '',
  // isActive : 'Y'
};
export const getTeamsServer = createAsyncThunk(
  "dashboard/getTeamsServer", async (userToken) => {
    try {
      const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/TeamsJSON?Token=' + userToken + '&Criteria=%', {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const addTeamServer = createAsyncThunk(
  "dashboard/addTeamServer", async (params) => {
    try {
      const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/AddTeam?Token=' + params.usertoken + '&TeamName=' + params.addTeamInputValue+'&Active='+params.status, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const updateTeamServer = createAsyncThunk(
  "dashboard/updateTeamServer",async (params) => {
    try{
      const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/UpdateTeam?Token=' + params.usertoken + '&TeamID='+params.teamId+'&TeamName='+params.addTeamInputValue+'&Active='+params.status,{
        method : 'GET'
      });
      const data = await response.json()
      return data;
    }
    catch(err){
      console.log("update team server failed",err)
    }
  }
)

const dashboardSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    addTeamInput: (state, action) => {
      console.log('input', state, action)
      state.addTeamInputValue = action.payload
    },
    setTeamId: (state, action) => {
      console.log("team id edit check",action.payload)
      state.teamId = action.payload.teamId
    },
    setErrorMessageFalse : (state, action) => {
      state.messageCondition = false
    }
  },
  extraReducers: {
    [getTeamsServer.pending]: (state) => {
      state.isLoading = true; // if the promise is still pending our state.isLoading will be true
    },
    [getTeamsServer.fulfilled]: (state, action) => {
      console.log('teamlistaction', action.payload.Result.Teams)
      state.isLoading = false;
      if(action.payload.Result.Teams != '[]'){
        state.teamsList = (!Array.isArray(action.payload.Result.Teams) ? [action.payload.Result.Teams] : action.payload.Result.Teams)
        localStorage.setItem('teamList',JSON.stringify(state.teamsList))
      }
      else{
        console.log("JSON.parse value",JSON.parse(action.payload.Result.Teams))
        state.teamsList = []
      }
      // localStorage.setItem('teamList',JSON.stringify(action.payload.Result.Teams))

    },
    [getTeamsServer.rejected]: (state, action) => {
      state.isLoading = false;
      state.messageCondition = true;
      state.messageType = 'error';
      state.message = 'Failed to fetch data'
    },


    [addTeamServer.pending]: (state) => {
      state.isLoading = true;
      state.messageCondition = false;
    },
    [addTeamServer.fulfilled]: (state, action) => {
      console.log('created', state, action)
      state.messageCondition = true;
      state.messageType = 'success';
      state.message = 'Team added successfully'
      state.isLoading = false;
      // state.isLoading = false;
      // state.teamsList=action.payload.Result.Teams

    },
    [addTeamServer.rejected]: (state, action) => {
      state.isLoading = false;
      state.messageCondition = true;
      state.messageType = 'error';
      state.message = 'Failed to add team'
    },
    [updateTeamServer.pending] : (state,action) => {
      state.isLoading = true;
      state.messageCondition = false;
    },
    [updateTeamServer.fulfilled] : (state,action) => {
      state.isLoading = false;
      state.messageCondition = true;
      state.messageType = 'success';
      state.message = 'Updated successfully'
    },
    [updateTeamServer.rejected] : (state,action) => {
      state.isLoading = false;
      state.messageCondition = true;
      state.messageType = 'error';
      state.message = 'Failed to update'
    }
  },


});
export const { addTeamInput, setTeamId, setIsActive, setErrorMessageFalse} = dashboardSlice.actions;
export default dashboardSlice.reducer;