import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material/';
import TextField from '@mui/material/TextField';
import '../loginContainer/LoginContainer.css';
import Button from '@mui/material/Button';
import PasswordChecklist from "react-password-checklist"

import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import { changefrgUserName, userRetriveServer, messageServer, userRetrivedCnd,passwordResetServer,changeNewPassword } from '../../features/login/loginSlice';
import teamlogo from '../../assets/teamlogo.jpg';
import loginimage from '../../assets/loginimg.png';
import loginimageone from '../../assets/two.jpg';
import loginimagetwo from '../../assets/completed-task.png';
import loginimagethree from '../../assets/online-survey.png';
import loginimagefour from '../../assets/checklist.png';
import { styled } from '@mui/material/styles';
import SnackBar from "../../components/snackBar-layout-wrapper/snackBar-layout-wrapper";


export default function LoginContainer() {



    const [submitDisable, setSubmitDisable] = useState(true)
    const navigate = useNavigate()
    const navigateToDashboard = () => {
        navigate('dashboard', { replace: false })
        // window.history.replaceState(null, "", "/")
    }
    // const classes = useStyles();
    const dispatch = useDispatch();

    const { frguserName, userRetrived, messageCondition, message, Type, isLoading, newPassword,frgUserToken } = useSelector((store) => store.login);
    console.log('loginprops', useSelector((store) => store.login));
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#3699ff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '97.7vh',
    }));

    useEffect(() => {
        console.log('checkUseEffect')
        dispatch(userRetrivedCnd(false))
    }, [])

    const PasswordResetServer = (userName, psd,token) => {
        

       dispatch(passwordResetServer({userName,psd,token})).then((res)=>{
        if(res.payload.Result=='Password Changed Successful'){
            setSubmitDisable(true)
            navigate('/',{replace : false})
            window.location.reload()
        }
       })

    }

    const userRetriver = (frguserName) => {
        dispatch(userRetriveServer(frguserName)).then((res) => {

            setTimeout(() => {
                dispatch(messageServer())
            }, 7000);


        })

    };
    useEffect(()=>{
    dispatch(messageServer())
    },[])

    return (
        <div className="" component="main">
            <SnackBar condition={messageCondition} message={message} type={Type} />
            <Grid container spacing={0}>
                <Grid item xs={5} md={5} display={{ xs: "none", sm: "none", md: "block", lg: "block" }} className="leftside">
                    <Item>
                        <div className='pt-23'>
                            <h2>Welcome</h2>
                            <img src={loginimage} alt={'team logo'} className="login" width="80%" />
                            <p className="text">A team performance measurement and reporting tool, aim at providing a tangible understanding of what
                                drives team effectiveness.
                            </p>
                            <div className="footerpart">
                                <div className="footvalue">
                                    <span className="">www.teamworkology.com</span>
                                    <span className="">Contact Us</span>
                                    <span className="">Introduction</span>
                                </div>
                            </div>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={12} md={7} textAlign={{ xs: 'center', sm: 'center', md: 'center', lg: 'center', xl: 'center' }} sx={{ margin: "auto" }}>

                    {userRetrived ? <div className="loginusernamecontainer">
                        <div className="logo">
                            <span><img src={teamlogo} alt="logo" /></span>
                        </div>
                        <div className="pb-10">
                            <TextField
                                size='small'
                                autoComplete={'off'}
                                className="width-1"
                                required
                                id="filled-required"
                                label="User Name"
                                defaultValue={frguserName}
                                fullWidth
                                onChange={(e) => dispatch(changefrgUserName(e.target.value))}

                            />
                        </div>
                        <div className="pb-10">
                            <TextField
                                autoComplete={'off'}
                                size='small'
                                className="width-1"
                                required
                                id="filled-required"
                                label="New Password"
                                type="password"
                                defaultValue={newPassword}
                                fullWidth
                                onChange={(e) => dispatch(changeNewPassword(e.target.value))}

                            />
                            <PasswordChecklist style={{marginTop:10}}
                            rules={["minLength", "specialChar", "number", "capital",]}
                            minLength={5}
                            value={newPassword}
                            onChange={(isValid) => {
                                if(isValid){
                                    setSubmitDisable(false)
                                }
                                else{
                                    setSubmitDisable(true)
                                }
                            }}
                        />
                        </div>

                        <div className="submit-cont">
                            <p> <a href="#" onClick={() => navigate('/')}>Back To Login</a></p>
                            <Button disabled = {submitDisable} onClick={() => PasswordResetServer(frguserName, newPassword,frgUserToken)} className="float-right" variant="contained" >
                                {isLoading ? <CircularProgress color="inherit" /> : 'Submit'}

                            </Button>

                        </div>

                    </div> : <div className="loginusernamecontainer">
                        <div className="logo">
                            <span><img src={teamlogo} alt="logo" /></span>
                        </div>
                        <div className="pb-10">
                            <TextField
                                size='small'
                                className="width-1"
                                required
                                autoComplete={'off'}
                                id="filled-required"
                                label="User Name"
                                defaultValue={frguserName}
                                fullWidth
                                onChange={(e) => dispatch(changefrgUserName(e.target.value))}

                            />
                        </div>

                        <div className="submit-cont">
                            <p> <a href="#" onClick={() => navigate('/')}>Back To Login</a></p>
                            <Button onClick={() => userRetriver(frguserName)} className="float-right" variant="contained" >
                                {isLoading ? <CircularProgress color="inherit" /> : 'Submit'}

                            </Button>
                        </div>
                    </div>
                    }
                </Grid>

            </Grid>

        </div>
    )
}




