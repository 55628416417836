import  React,{useEffect, useState} from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { teamsMemServer } from '../../features/teams/teamsSlice'
import { useDispatch, useSelector } from "react-redux";
import { setTeamMemId } from '../../features/teams/teamsSlice';
import './common-select.css';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Team A',
  'Team B',
  'Team C',
  'Team D',
 
];

const issuesurvey = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
]; 

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function CommonSelect(props) {
  console.log('checkcommonprops',props)
  const dispatch = useDispatch();

  const { usertoken } = useSelector((store) => store.login);

  const [selectedTeam,setSelectedTeam]= useState('')
  const theme = useTheme();

  useEffect(() => {
    setSelectedTeam(props.label)
  },[props.label])

  function selectTeam(x,usertoken){
    console.log('checkx',x,usertoken)
    setSelectedTeam(x.target.value)
    dispatch(setTeamMemId(x.target.value))
    // setSelectedTeam(x.target.value.Team_Name)
    // let team_id=x.target.value.Team_Id
    // dispatch(teamsMemServer({ usertoken,team_id }))
  }

  return (
    <div>
      <FormControl className='ipt-size' >
        {/* <InputLabel id="demo-multiple-name-label">{props.label}</InputLabel> */}
        {console.log('selectedTeam',selectedTeam,props.list)}
        <Select
         
          size="small"
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={selectedTeam}
          onChange={(x)=>selectTeam(x,usertoken)}
          MenuProps={MenuProps}
          fullWidth
        >
          {props.list.map((name) => (
            <MenuItem
              key={name['Team_Id']}
              value={name['Team_Id']}
              style={getStyles(name.Team_Name, 'personName', theme)}
            >
              {name.Team_Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
