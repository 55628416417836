import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import BreadCrumb from '../../components/common-breadcrumb-section/BreadCrumb';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import IconContentCard from "../../components/common-icon-content-card/IconContentCard";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined';
import AddOutlined from '@mui/icons-material/AddOutlined';
import IconBtn from '../../components/common-icon-btn/IconBtn';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CommonDataGrid from '../../components/common-data-grid/CommonDataGrid';
import BtnContent from '../../components/common-content-btn/BtnContent';
import { useNavigate } from 'react-router';
import AddTeamDialog from '../../components/add-team-dialog/AddTeamDialog';
import '../TeamManagementContainer/team-management.css'
import { useDispatch, useSelector } from "react-redux";
import AddTeamMemberDialog from '../../components/add-team-member-dialog/AddTeamMemberDialog';
import { selectedGridDataChecked } from '../../features/teamManagement/teamManagementSlice'
import { removeTeamServer, groupGridDataChecked, setMessageShowBool } from '../../features/teamManagement/teamManagementSlice';
import { teamsMemServer, setTeamMemId, } from '../../features/teams/teamsSlice'
import { getTeamsServer, setTeamId, updateTeamServer, addTeamInput, setErrorMessageFalse } from '../../features/dashboard/dashboardSlice';
import EditTeamDialog from '../../components/edit-team-dialog/EditTeamDialog';
import SnackBar from '../../components/snackBar-layout-wrapper/snackBar-layout-wrapper';
import BackDropLoader from '../../components/common-backdrop-loader/BackdropLoader';
import Divider from '@mui/material/Divider';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import MessageIndication from '../../components/common-message-indication/MessageIndication';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const TeamManagement = () => {
    const [isActive, setIsActive] = useState(true)
    const [gridDataChecked, setGridDataChecked] = useState([])
    const [showIssueSurvey, setShowIssueSurvey] = useState(false)
    const [open, setOpen] = useState(false)
    const [addTeamMemDialog, setAddTeamMemDialog] = useState(false)
    const [editTeamDialogOpen, setEditTeamDialogOpen] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { usertoken } = useSelector((store) => store.login);
    const { teamsList, teamId, addTeamInputValue, message, messageCondition, messageType, isLoading } = useSelector((store) => store.dashboard)
    const { selectedGridDataChecked, groupTableCheckedArr, messageTeamMng, messageConditionTeamMng, messageTypeTeamMng } = useSelector((store) => store.teamManageMent)
    const { isLoadingTeamMem, teamMemList } = useSelector((store) => store.teams)
    // let newArr = teamsList.map((v, index) => ({ ...v, id: index, status: v.Active == 'Y' ? 'Active' : 'InActive' })) ;
    const [newArr, setNewArr] = useState([])
    const [isOpenTeamToggle, setIsOpenTeamToggle] = useState(true);
    const [isOpenInfoIcon, setIsOpenInfoIcon] = useState(false);
    const [teamDeleteShow, setTeamDeleteShow] = useState(false)
    const [panelDeleteShow, setPanelDeleteShow] = useState(true)

    useEffect(() => {
        setNewArr(teamsList.map((v, index) => ({ ...v, id: index, status: v.Active == 'Y' ? 'Active' : 'InActive' })))
    }, [teamsList])

    useEffect(() => {
        console.log("gridDataChecked", gridDataChecked)
        if (gridDataChecked.length != 0) {
            setShowIssueSurvey(true)
            dispatch(groupGridDataChecked({ gridDataChecked }))
            console.log("groupTableCheckedArr", groupTableCheckedArr)
        }
        else {
            setShowIssueSurvey(false)
        }
    }, [gridDataChecked])

    const columns = [
        {
            field: 'Team_Id',
            headerName: 'Team ID',
            flex: 'auto',
            editable: false
        },
        {
            field: 'Team_Name',
            headerName: 'Team Name',
            flex: 1,
            editable: false,
            renderCell: (params) => {
                console.log("params", params)
                var team_id = params.row.Team_Id
                return (
                    <div>
                        <a className='nav-highlight' onClick={() => {
                            // {dispatch(setTeamId(location.state['Team_Id']))
                            dispatch(setTeamMemId(params.row.Team_Id))
                            navigate('/teams', { state: { Team_Id: team_id } })
                        }}>{params.value}</a>
                    </div>
                )
            }
        },
        {
            field: 'Members',
            headerName: 'Team Members',
            flex: 1,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 'auto',
            editable: false
        },
        {
            field: 'action',
            headerName: '',
            // minWidth: 150,
            flex: 'auto',
            editable: false,
            renderCell: (params) => {
                console.log("params for team management", params)
                return (
                    <div>
                        {/* <span id={"shared" + params.row.id} className="pd-r-15px cursor-pointer" onClick={() => window.alert("share")}>
                            <ShareOutlinedIcon />
                        </span> */}
                        <span id={"edit" + params.row.id} className="pd-r-15px cursor-pointer" onClick={() => {
                            let teamId = params.row['Team_Id']
                            dispatch(setTeamId({ teamId }))
                            dispatch(addTeamInput(params.row['Team_Name']))
                            if (teamId != '') {
                                setEditTeamDialogOpen(true)
                            }
                        }}>
                            <CreateOutlinedIcon />
                        </span>
                        <span>
                        {params.row['Members'] == '' && <span id={"delete" + params.row.id} className="pd-r-15px cursor-pointer" onClick={() => {
                            console.log("grid delete clicked", params)
                            let teamId = params.row['Team_Id']
                            let team_id = params.row['Team_Id']
                            dispatch(teamsMemServer({ usertoken, team_id })).then((res) => {
                                if (res.payload.data.TeamStatus.Person == "[]") {
                                    dispatch(removeTeamServer({ usertoken, teamId })).then((res) => {
                                        dispatch(getTeamsServer(usertoken)).then((res) => {
                                            // window.alert("deleted successfully")
                                            setTimeout(() => {
                                                dispatch(setMessageShowBool())
                                            }, 3000)
                                        })
                                    }
                                    ).catch(err => {
                                        window.alert(err)
                                    })
                                }
                                else {
                                    setTeamDeleteShow(true)
                                    setTimeout(() => {
                                        setTeamDeleteShow(false)

                                    }, 3000)
                                }
                            })
                        }}>
                            <DeleteOutlineOutlinedIcon />
                        </span>}
                        </span>
                    </div>
                )
            }
        }
    ];

    const addEditTeam = () => {
        setOpen(true)
    }
    const removeTeam = () => {
        for(var i = 0; i < groupTableCheckedArr.gridDataChecked.length; i++){
            let teamId = groupTableCheckedArr.gridDataChecked[i]['Team_Id']
            let team_id = groupTableCheckedArr.gridDataChecked[i]['Team_Id']
            let bool = false
            dispatch(teamsMemServer({ usertoken, team_id })).then((res) => {
                if (res.payload.data.TeamStatus.Person == "[]") {
                    dispatch(removeTeamServer({ usertoken, teamId })).then((res) => {
                        dispatch(getTeamsServer(usertoken)).then((res) => {
                            // window.alert("deleted successfully")
                            setTimeout(() => {
                                dispatch(setMessageShowBool())
                            }, 3000)
                        })
                    }
                    ).catch(err => {
                        window.alert(err)
                    })
                }
                else {
                    bool = true
                    setTeamDeleteShow(true)
                    setTimeout(() => {
                        setTeamDeleteShow(false)

                    }, 3000)
                }
            })
            if(bool){
                break
            }
        }
        // groupTableCheckedArr.gridDataChecked.map(x => {
        //     let teamId = x['Team_Id']
        //     dispatch(removeTeamServer({ usertoken, teamId })).then((res) => {
        //         dispatch(getTeamsServer(usertoken)).then((res) => {
        //             // window.alert("deleted successfully")
        //         })
        //     }
        //     ).catch(err => {
        //         window.alert(err)
        //     })
        // })
    }

    const rowClick = (params, event) => {
    }

    const checkBoxSelected = (id) => {
        // let deleteShow = false
        //    const getID = newArr.find(x=>x.id==id[0])
        //    console.log('getID',getID)
        //    dispatch(selectedGridDataChecked(getID.Team_Id))
        //    setGridDataChecked(getID.Team_I)
        let removeTeamData = id.map((idValue, index) => {
            return newArr[idValue]
        })
        setPanelDeleteShow(true)
        removeTeamData.find((element) => {
            console.log("element to check",element)
            if(element['Members'].toString() != ''){
                setPanelDeleteShow(false)
                // document.getElementById('remove-team').classList.remove('disp-none')
            }
        })
        
        console.log("removeTeamData checking",removeTeamData)
        setGridDataChecked(gridDataChecked => removeTeamData)
    }

    const navigateTeams = (params) => {
        // navigate('/teams',{state : params.row})
        window.history.replaceState(null, "", "/")
    }

    const clickBtnNameOne = () => {
        window.alert("triggered 1")
    }

    const clickBtnNameTwo = () => {
        window.alert("triggered 2")
    }
    const handlCloseiconview = (value) => {
        if (value == "closeicon") {
            setIsOpenTeamToggle(false);
            setIsOpenInfoIcon(true);
        }
        else if (value == "infoicon") {
            setIsOpenTeamToggle(true);
            setIsOpenInfoIcon(false);
        }

    }

    const searchTeam = (e) => {
        let searchKey = e.target.value
        if (searchKey == '') {
            setNewArr(teamsList.map((v, index) => ({ ...v, id: index, status: v.Active == 'Y' ? 'Active' : 'InActive' })))
        }
        else {
            let newFilteredArr = newArr.filter((v, index) => {
                if (v['Team_Name'].toLowerCase().includes(searchKey) || v['Team_Id'].toLocaleLowerCase().includes(searchKey)) {
                    return { ...v, id: index, status: v.Active == 'Y' ? 'Active' : 'InActive' }
                }
            })
            setNewArr(newArr => newFilteredArr)
        }
    }

    const deleteTeam = () => {
        for(var i = 0; i < groupTableCheckedArr.gridDataChecked.length; i++){
            let teamId = groupTableCheckedArr.gridDataChecked[i]['Team_Id']
            let team_id = groupTableCheckedArr.gridDataChecked[i]['Team_Id']
            let bool = false
            dispatch(teamsMemServer({ usertoken, team_id })).then((res) => {
                if (res.payload.data.TeamStatus.Person == "[]") {
                    dispatch(removeTeamServer({ usertoken, teamId })).then((res) => {
                        dispatch(getTeamsServer(usertoken)).then((res) => {
                            // window.alert("deleted successfully")
                            setTimeout(() => {
                                dispatch(setMessageShowBool())
                            }, 3000)
                        })
                    }
                    ).catch(err => {
                        window.alert(err)
                    })
                }
                else {
                    bool = true
                    setTeamDeleteShow(true)
                    setTimeout(() => {
                        setTeamDeleteShow(false)
                    }, 3000)
                }
            })
            if(bool){
                break
            }
        }
        // groupTableCheckedArr.gridDataChecked.map(x => {
        //     let teamId = x['Team_Id']
        //     let team_id = x['Team_Id']
        //     dispatch(teamsMemServer({ usertoken, team_id })).then((res) => {
        //         if (res.payload.data.TeamStatus.Person == "[]") {
        //             dispatch(removeTeamServer({ usertoken, teamId })).then((res) => {
        //                 dispatch(getTeamsServer(usertoken)).then((res) => {
        //                     // window.alert("deleted successfully")
        //                 })
        //             }
        //             ).catch(err => {
        //                 window.alert(err)
        //             })
        //         }
        //         else {
        //             setTeamDeleteShow(true)
        //             setTimeout(() => {
        //                 setTeamDeleteShow(false)

        //             }, 3000)
        //         }
        //     })

        // })
    }
    if (!isLoadingTeamMem) {
        return (

            <>
                <BackDropLoader loaderBool={isLoading} />
                <SnackBar condition={messageCondition} message={message} type={messageType} />
                <SnackBar condition={messageConditionTeamMng} message={messageTeamMng} type={messageTypeTeamMng} />
                <SnackBar condition={teamDeleteShow} message={'Not able to delete. Because it has team members'} type={'error'} />
                <Container maxWidth="xl">
                    <Grid
                        justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }}
                        alignItems="center"
                        rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                        className="text-center">
                        <Stack
                            direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
                            spacing={2}
                            justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }}
                            alignItems="start" mb={1}>
                            <BreadCrumb breadCrumbName={"Team Management"} />
                        </Stack>
                    </Grid>
                    <Divider />
                    {newArr.length == 0 && <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <MessageIndication icon={<ErrorOutlineIcon className="message-icn-size" />} message={"Oh uh, You don’t have any team, Add new team"} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} mb={3} sx={{ display: "flex", justifyContent: "center" }}>
                            <IconContentCard cursor = {true} clrwhite={"#fff"} bgColor={"#1bc5bd"} click={addEditTeam} icon={<GroupAddOutlinedIcon className="message-icn-size" />} cardTitle={"Add/Edit Team"} description={"Add new team"} />
                        </Grid>
                    </Grid>}
                    {newArr.length != 0 && <Grid
                        container
                        // justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'center', xl: 'center' }}
                        alignItems="center"
                        // rowSpacing={3} 
                        // columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                        columnSpacing={1}
                        className="text-center">
                        <Grid className="openvalue" item xs={12} sm={12} md={12} lg={isOpenInfoIcon == true ? 'col' : '7'} xl={isOpenInfoIcon == true ? 'col' : '8'}>
                            <Grid direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }} container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="space-between" alignItems="start">
                                <Grid item xs={5} sm={7} md={8} lg={8} xl={2} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                    <h1 className="header_title" >Teams</h1>
                                </Grid>
                                {/* <Grid item xl = {6} sx = {{display : 'flex', justifyContent : 'flex-end'}}>
                                    <span><TextField size = "small" id="outlined-basic" autoComplete='off' onChange= {(e) => searchTeam(e)} placeholder='Search Teams' variant="outlined" /></span>
                                </Grid> */}
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }} className="m-t-5">
                                    <span className='pd-r-10px'><TextField size="small" id="outlined-basic" autoComplete='off' onChange={(e) => searchTeam(e)} placeholder='Search Team' variant="outlined" className='searchwidth' /></span>
                                    {/* <span className='pd-r-10px'>{showIssueSurvey && <BtnContent align={true} size={"medium"}><p className="mar-0">Issue Survey</p></BtnContent>}</span> */}
                                    {gridDataChecked.length > 1 && panelDeleteShow && <span className='pd-r-10px'>{showIssueSurvey && <IconBtn click={() => deleteTeam()} icon={<DeleteOutlineOutlinedIcon />} />}</span>}

                                    {!showIssueSurvey && <IconBtn click={() => addEditTeam()} icon={<AddOutlined />} />}
                                    {/* {isOpenTeamToggle == true && <p className="teamtoggle" onClick={() => handlCloseiconview('closeicon')}><IconBtn click={() => addEditTeam()} icon={<ArrowForwardIcon />} /></p>}
                                   {isOpenInfoIcon == true && <p className="teamtoggleinfo" onClick={() => handlCloseiconview('infoicon')}><ArrowBackIconIcon /></p>} */}
                                    {/* <span className = "pd-l-10px">{isOpenTeamToggle == true &&<IconBtn click={() => handlCloseiconview('closeicon')} icon={<ArrowForwardIcon />} />}</span>
                                   <span className = "pd-l-10px">{isOpenInfoIcon == true &&<IconBtn click={() => handlCloseiconview('infoicon')} icon={<ArrowBackIcon />} />}</span> */}
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <CommonDataGrid checkboxSelection = {false} columns={columns} rows={newArr} rowClick={rowClick} checkBoxSelected={checkBoxSelected} />
                            </Grid>
                        </Grid>
                        <Grid item display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>
                            <span>{isOpenTeamToggle == true && <IconBtn toggle={true} click={() => handlCloseiconview('closeicon')} icon={<ArrowForwardIosIcon />} />}</span>
                            <span >{isOpenInfoIcon == true && <IconBtn toggle={true} click={() => handlCloseiconview('infoicon')} icon={<ArrowBackIosIcon className="backwardicon" />} />}</span>
                        </Grid>
                        {isOpenTeamToggle == true && <Grid className="togglebtn" item xs={12} sm={12} md={12} lg xl mt={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }} display={{ xs: "none", sm: "none", md: "block", lg: "block" }}>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={2}>
                                <IconContentCard cursor = {true} clrwhite={"#fff"} bgColor={"#1bc5bd"} click={addEditTeam} icon={<GroupAddOutlinedIcon className="message-icn-size" />} cardTitle={"Add/Edit Team"} description={"Create a new team by giving it a name(name must be unique)"} />
                                {/* <IconContentCard clrwhite={"#fff"} bgColor={"#1bc5bd"} click={()=>setAddTeamMemDialog(!addTeamMemDialog)} icon={<GroupAddOutlinedIcon className="message-icn-size" />} cardTitle={"Add/Edit Team"} description={"Create a new team by giving it a name(name must be unique)"} /> */}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {/* <span id = "remove-team" className='disp-none'> */}
                                {gridDataChecked.length > 1 && panelDeleteShow && <IconContentCard cursor = {true} clrwhite={"#fff"} bgColor={"#ea6060"} click={removeTeam} icon={<GroupRemoveOutlinedIcon className="message-icn-size" />} cardTitle={"Remove Team"} description={"Can Remove an existing team/person or someone new"} />}
                                {/* </span> */}
                            </Grid>
                        </Grid>}
                    </Grid>}
                </Container>
                <EditTeamDialog change={(event) => setIsActive(event)} checked={true} open={editTeamDialogOpen} title={"edit team"} btnNameOne={"save"} clickBtnNameOne={() => {
                    if (isActive) {
                        let status = 'Y'
                        dispatch(updateTeamServer({ teamId, addTeamInputValue, usertoken, status })).then((res) => {
                            dispatch(getTeamsServer(usertoken))
                            setEditTeamDialogOpen(false)
                            console.log("response for edit dialog", res)
                        }).catch((err) => {
                            console.log("error occurred")
                        })
                    }
                    else {
                        let status = 'N'
                        dispatch(updateTeamServer({ teamId, addTeamInputValue, usertoken, status })).then((res) => {
                            dispatch(getTeamsServer(usertoken))
                            setEditTeamDialogOpen(false)
                            console.log("response for edit dialog", res)
                        }).catch((err) => {
                            console.log("error occurred")
                        })
                    }
                }} clickBtnNameTwo={() => setEditTeamDialogOpen(!editTeamDialogOpen)} btnNameTwo={"cancel"} close={() => {
                    setEditTeamDialogOpen(!editTeamDialogOpen)
                    dispatch(addTeamInput(''))
                }} />
                <AddTeamDialog open={open} title={"add team"} btnNameOne={"add"} clickBtnNameOne={clickBtnNameOne} clickBtnNameTwo={() => setOpen(!open)} btnNameTwo={"cancel"} close={() => setOpen(!open)} />
                <AddTeamMemberDialog open={addTeamMemDialog} title={'Add Team Member'} btnNameOne={'add'} clickBtnNameOne={clickBtnNameOne} clickBtnNameTwo={clickBtnNameTwo} btnNameTwo={"cancel"} close={() => setAddTeamMemDialog(!addTeamMemDialog)} />
            </>
        )
    }
    else {
        return (
            <Stack alignItems="center">
                <BackDropLoader loaderBool={isLoadingTeamMem} />
            </Stack>
        )
    }
}

export default TeamManagement;