import React,{useState, useEffect} from 'react'
import DialogLayoutWrapper from '../Dialog-layout-wrapper/DialogLayoutWrapper'
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import {setEditTemplateName, setEditTemplateSubject, setEditTemplateMessage, setEditTemplateActive, listAllTemplatesServer, updateTemplateServer} from '../../features/settings/SettingsSlice';
import './edit-template-dialog.css';
import SwitchButton from '../common-switch-button/SwitchButton';
const EditTemplateDialog = (props) => {
    const [isActive, setIsActive] = useState(true)
    const dispatch = useDispatch()
    const {editTemplateName, editTemplateSubject, editTemplateMessage, editTemplateActive, criteria} = useSelector((store) => store.settings)
    const {usertoken} = useSelector((store) => store.login)
    const clickBtnNameOne = () => {
        if(isActive){
            let status = 'Y'
            dispatch(updateTemplateServer({usertoken, editTemplateName, editTemplateSubject, editTemplateMessage, status})).then((res) => {
                console.log("edit successfully",res)
                props.close()
                dispatch(listAllTemplatesServer({usertoken,criteria}))
            }).catch((err) => {
                console.log("error returned",err)
            })  
        }
        else{
            let status = 'N'
            dispatch(updateTemplateServer({usertoken, editTemplateName, editTemplateSubject, editTemplateMessage, status})).then((res) => {
                console.log("edit successfully",res)
                props.close()
                dispatch(listAllTemplatesServer({usertoken,criteria}))
            }).catch((err) => {
                console.log("error returned",err)
            })
        }
        }
        // dispatch(updateTemplateServer({usertoken, editTemplateName, editTemplateSubject, editTemplateMessage, editTemplateActive})).then((res) => {
        //     console.log("edit successfully",res)
        //     props.close()
        //     dispatch(listAllTemplatesServer({usertoken,criteria}))
        // }).catch((err) => {
        //     console.log("error returned",err)
        // })
    // }

    const clickBtnNameTwo = () => {
        // setOpen(!open)
        props.close()
    }

    const outputReturn = (data) => {
        let datas = data.split('/n')
        return datas.join('\n')
    }
    return(
        <>
            <DialogLayoutWrapper open={props.open} show = {true} title = {"edit template"} btnNameOne = {"save"} clickBtnNameOne = {clickBtnNameOne} clickBtnNameTwo = {clickBtnNameTwo} btnNameTwo = {"cancel"} close={props.close}>
                        <Grid container rowSpacing={3}>
                            <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2} className="padingpb">
                                        <label for = "id" className="text-capitalize">template name</label>
                                    </Grid>
                                    <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                        <TextField value = {editTemplateName} size="small" onChange={(e) => dispatch(setEditTemplateName(e.target.value))} className='ipt-size' autoComplete = "off" id="id" variant="outlined" />   
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2} className="padingpb">
                                        <label for = "name" className="text-capitalize">subject</label>
                                    </Grid>
                                    <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                        <TextField value = {editTemplateSubject} onChange = {(e) => dispatch(setEditTemplateSubject(e.target.value))} size="small" className='ipt-size' autoComplete = "off" id="name" variant="outlined" />   
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2} className="padingpb">
                                        <label for = "description" className="text-capitalize">message</label>
                                    </Grid>
                                    <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                        <TextareaAutosize
                                        value = {outputReturn(editTemplateMessage)}
                                        onChange = {(e) => {
                                            let mailMess = e.target.value.split('\n')
                                            // dispatch(setMailMessage(mailMess.join('/n')))
                                            dispatch(setEditTemplateMessage(mailMess.join('/n')))
                                        }}
                                        className='ipt-size'
                                        aria-label="minimum height"
                                        id = "description"
                                        minRows={10}
                                        placeholder=""
                                        autoComplete = "off"
                                        size="small"
                                        />  
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                        {/* <label for = "description" className="text-capitalize">description</label> */}
                                    </Grid>
                                    <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                        {/* <TextareaAutosize
                                        aria-label="minimum height"
                                        id = "description"
                                        minRows={10}
                                        placeholder=""
                                        style={{ width: "100%" }}
                                        />   */}
                                        <SwitchButton change = {(event) => setIsActive(event)} checkLabel = {'Active'} notCheckLabel = {'InActive'} checked = {isActive}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
            </DialogLayoutWrapper>
        </>
    )
}

export default EditTemplateDialog