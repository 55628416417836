import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  userName: '',
  frguserName: '',
  password: '',
  newPassword: '',
  usertoken: localStorage.getItem('userToken'),
  isLoading: '',
  message: '',
  Type: '',
  frgUserToken: '',
  messageCondition: false,
  accInforUserName: localStorage.getItem('accInfoUserName'),
  lastLoggedOn: localStorage.getItem('lastLoggedOn'),
  isUserInformationLoading: '',
  email: localStorage.getItem('email'),
  name: localStorage.getItem('name'),
  notificationCount: localStorage.getItem('notificationCount'),
  userRetrived: false
};
// 14375
// Clarity4d2022!
export const loginFunction = createAsyncThunk(
  "login/loginFunction", async (credentials) => {
    try {
      const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/AuthenticateJSON?userName=' + credentials.userName + '&Password=' + credentials.password, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const userRetriveServer = createAsyncThunk(
  "login/userRetriveServer", async (frguserName) => {
    try {
      const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/PasswordTokenJSON?userName=' + frguserName, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const userInformation = createAsyncThunk(
  "login.userInformation", async (credentials) => {
    try {
      const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/AccountJSON?Token=' + credentials, {
        method: 'GET'
      });
      const data = await response.json();
      return data;
    } catch (err) {
      return console.log(err)
    }
  }
)

export const notificationInformation = createAsyncThunk(
  "login/notificationInformation", async (credentials) => {
    try {
      const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/TeamNotificationJSON?Token=' + credentials, {
        method: 'GET'
      });
      const data = await response.json()
      return data
    }
    catch (err) {
      return err
    }
  }
)

export const passwordResetServer = createAsyncThunk(
  "login/passwordResetServer", async (params) => {
    try {
      const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/PasswordResetJSON?Token=' + params.token + '&UserName=' + params.userName + '&NewPassword=' + params.psd, {
        method: 'GET'
      });
      const data = await response.json()
      return data
    }
    catch (err) {
      return err
    }
  }
)

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    changeUserName: (state, action) => {
      state.userName = action.payload
    },
    changefrgUserName: (state, action) => {
      state.frguserName = action.payload
    },

    messageServer: (state, action) => {
      state.message = ''
      state.Type = ''
      state.messageCondition = false
    },
    userRetrivedCnd: (state, action) => {
      state.userRetrived = action.payload
    },
    changeNewPassword: (state, action) => {
      state.newPassword = action.payload
    },
    changePassword: (state, action) => {
      state.password = action.payload

    },
    closeSnackbar: (state, action) => {
      state.messageCondition = action.payload
    },

  },
  extraReducers: {
    [loginFunction.pending]: (state) => {
      state.isLoading = true; // if the promise is still pending our state.isLoading will be true
      state.message = '';
      state.messageCondition = false;
      state.Type = ''
    },
    [loginFunction.fulfilled]: (state, action) => {
      console.log('action', action.payload)
      state.isLoading = false;
      if (action.payload.hasOwnProperty('Token')) {
        localStorage.setItem('userToken', action.payload.Token)

        state.usertoken = action.payload.Token
        state.message = 'Logged in successfully'
        state.Type = 'success'
        state.messageCondition = true
      } else {
        state.message = action.payload.Result
        state.Type = 'error'
        state.messageCondition = true
      }
    },
    [loginFunction.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [userInformation.pending]: (state, action) => {
      state.isUserInformationLoading = true
    },
    [userInformation.fulfilled]: (state, action) => {
      console.log("user information action", action)
      localStorage.setItem('email', action.payload.Result.Account.Email_Address)
      localStorage.setItem('name', action.payload.Result.Account.User_Name)
      localStorage.setItem('accInfoUserName', action.payload.Result.Account.Name)
      localStorage.setItem('lastLoggedOn', action.payload.Result.Account['Last_Signon'])
      state.email = action.payload.Result.Account.Email_Address;
      state.name = action.payload.Result.Account.User_Name;
      state.accInforUserName = action.payload.Result.Account.Name;
      state.lastLoggedOn = action.payload.Result.Account['Last_Signon']
      // state.email=localStorage.getItem('email');
      // state.name=localStorage.getItem('name');
      // state.accInforUserName = localStorage.getItem('accInfoUserName');
      // state.lastLoggedOn = localStorage.getItem('lastLoggedOn')
    },
    [userInformation.rejected]: (state, action) => {
      state.isUserInformationLoading = false
    },
    [notificationInformation.pending]: (state, action) => {

    },
    [notificationInformation.fulfilled]: (state, action) => {
      localStorage.setItem('notificationCount', action.payload['Status']['Notification'])
      state.notificationCount = action.payload['Status']['Notification']
    },
    [notificationInformation.rejected]: (state, action) => {

    },
    [userRetriveServer.pending]: (state, action) => {
      state.isLoading = true;
      state.message = '';
      state.Type = '';
      state.messageCondition = false
    },
    [userRetriveServer.fulfilled]: (state, action) => {

      state.isLoading = false;
      if (action.payload.Result.hasOwnProperty('Email')) {
        state.frgUserToken = action.payload.Result.Token
        state.userRetrived = true

      } else {
        state.message = action.payload.Result
        state.Type = 'error'
        state.messageCondition = true


      }
    },
    [userRetriveServer.rejected]: (state, action) => {

    },

    [passwordResetServer.pending]: (state, action) => {
      state.isLoading = true;
      state.message = '';
      state.Type = '';
      state.messageCondition = false
    },
    [passwordResetServer.fulfilled]: (state, action) => {

      state.isLoading = false;

      state.frgUserToken = action.payload.Result


      state.message = action.payload.Result
      if (action.payload.Result == 'Password Changed Successful') {
        state.Type = 'success'
      }
      else {
        state.Type = 'error'
      }
      state.messageCondition = true



    },
    [passwordResetServer.rejected]: (state, action) => {

    }


  },


});


export const { changeNewPassword, userRetrivedCnd, messageServer, changefrgUserName, changeUserName, changePassword, closeSnackbar, showSnackbar } = loginSlice.actions;
export default loginSlice.reducer;