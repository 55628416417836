import React from 'react'
import IconButton from '@mui/material/IconButton';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import '../common-icon-btn/icon-btn.css'

const IconBtn = (props) => {
    return(
        <>
            <IconButton className = {props.toggle != true ? 'icn-custom' : 'icn-toggle-custom'} aria-label="delete" onClick = {props.click} variant="contained">
                {props.icon}
            </IconButton>
        </>
    )
}

export default IconBtn