import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const initialState = {
   recipient: [],
   senderInformation: [],
   mailSubject: '',
   mailBody: '',
   messageIssue: '',
   issueType: '',
   messageIssueCondition: false,
   isMessageLoading: false,
   snackmessage: '',
   type: false,
   snackmessageCondition: '',
   pdfReport: {},
   publishTableShow: false,
   pdfLink: '',
   mailPdfLink: '',
   bccMail : []
};

export const recordSurveyMail = createAsyncThunk(
   'survey/recordSurveyMail',
   async (params) => {
      try {
         const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/SentEmailJSON?Token=' + params.usertoken + '&TeamID=' + params.teamId + '&PersonID=' + params.personId + '&Email=' + params.email, {
            method: 'GET'
         })
         const data = await response.json()
         return data
      }
      catch (err) {
         return err
      }
   }
)
export const publishService = createAsyncThunk(
   'survey/publishService',
   async (params) => {
      try {
         const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/PublishJSON?Token=' + params.usertoken + '&Profile=TEAMDYNAMICS&PersonId=' + params.personId, {
            method: 'GET'
         })
         const data = await response.json()
         return data
      }
      catch (err) {
         return err
      }
   }
)
export const teamReportExists = createAsyncThunk(
   'survey/teamReportExists',
   async (params) => {
      try {
         const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/TeamReportExistsJSON?Token=' + params.usertoken + '&TeamID=' + params.team_id, {
            method: 'GET'
         })
         const data = await response.json()
         return data
      }
      catch (err) {
         return err
      }
   }
)
export const PublishTeamReport = createAsyncThunk(
   'survey/PublishTeamReport',
   async (params) => {
      console.log('PublishTeamReport', params)
      try {
         const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/PublishTeamReportJSON?Token=' + params.usertoken + '&TeamID=' + params.team_id, {
            method: 'GET'
         })
         const data = await response.json()
         return data
      }
      catch (err) {
         return err
      }
   }
)
export const TeamReportDelete = createAsyncThunk(
   'survey/TeamReportDelete',
   async (params) => {
      try {
         const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/TeamReportDeleteJSON?Token=' + params.usertoken + '&TeamID=' + params.team_id, {

            method: 'GET'
         })
         const data = await response.json()
         return data
      }
      catch (err) {
         return err
      }
   }
)

const issueSurveySlice = createSlice({
   name: 'issueSurvey',
   initialState,
   reducers: {
      addRecipient: (state, action) => {
         console.log('checkAction', action)
         state.recipient = action.payload
         console.log("state.recipient", state.recipient)
      },
      addRecipientFromStatus: (state, action) => {
         // console.log('addRecipientFromStatus', state, action)
         state.recipient = action.payload
      },
      removeRecipient: (state, action) => {
         console.log('checkAction', action, state)
         state.recipient.splice(action.payload, 1);
         // state.recipient = action.payload
      },
      setMailSubject: (state, action) => {
         state.mailSubject = action.payload
      },
      setMailBody: (state, action) => {
         state.mailBody = action.payload
      },
      sendMail: (state, action) => {
         if (state.recipient.length != 0) {
            let data = state.mailBody.split('/n')
            let newData = data.join('<br/>')
            state.senderInformation = state.recipient.map((x, index) => {
               let key1 = btoa(x.forename)
               let key2 = btoa(x.surname)
               let key3 = btoa(x.emailAddress)
               return {
                  from_name: "hieu.phan@clarity4d.com",
                  to_name: x.emailAddress,
                  email_id: index,
                  mail_subject: state.mailSubject,
                  message: newData,
                  bcc : state.bccMail.toString(),
                  // survey_link : 'http://localhost:3000/questionnaire/?usertoken=PUBLIC&teamId='+x.teamId+'&personId='+x.personId+'&firstName='+x.forename+'&surName='+x.surname+'&email='+x.emailAddress
                  // survey_link: (state.mailPdfLink == '' ? 'http://localhost:3000/surveyForm/?usertoken=PUBLIC&teamId=' + x.teamId + '&personId=' + x.personId + '&key1=' + key1 + '&key2=' + key2 + '&key3=' + key3 : state.mailPdfLink)
                  // survey_link: (state.mailPdfLink == '' ? 'http://workologydev.clarity4d.com/surveyForm/?usertoken=PUBLIC&teamId=' + x.teamId + '&personId=' + x.personId + '&key1=' + key1 + '&key2=' + key2 + '&key3=' + key3 : state.mailPdfLink)
                  survey_link: (state.mailPdfLink == '' ? window.location.protocol+'//'+window.location.host+'/surveyForm/?usertoken=PUBLIC&teamId=' + x.teamId + '&personId=' + x.personId + '&key1=' + key1 + '&key2=' + key2 + '&key3=' + key3 : ''),
                  survey_label: state.mailPdfLink == '' ? 'Click to open questionnaire' : '',
                  // survey_link: !(JSON.stringify(mailPdfLink).includes('pdf')) ? mailPdfLink : '',
                  pdfLink: JSON.stringify(state.mailPdfLink).includes('pdf') ? state.mailPdfLink : ''
               }
            })
         }
         else {
            window.alert("Atleast one recipient need to added")
         }
      },
      setSenderInformationEmpty: (state, action) => {
         state.senderInformation = action.payload
         console.log("senderInformation need to check",action.payload)
      },
      setMailPdfLink: (state, action) => {
         state.mailPdfLink = action.payload
      },
      setBccMail : (state, action) => {
         state.bccMail = action.payload
      }
   },
   extraReducers: {
      [publishService.pending]: (state) => {
         state.isLoading = true;
      },
      [publishService.fulfilled]: (state, action) => {
         console.log('action.payload', action.payload)
         state.isLoading = false;
         state.pdfReport = action.payload
      },
      [publishService.rejected]: (state, action) => {
         state.isLoading = false;
      },
      [teamReportExists.pending]: (state, action) => {
         state.publishTableShow = false
      },
      [teamReportExists.fulfilled]: (state, action) => {
         console.log('checkTeamReportExists', action)
         state.publishTableShow = action.payload
      },
      [teamReportExists.rejected]: (state, action) => {
         state.publishTableShow = false
      },
      [recordSurveyMail.pending]: (state, action) => {
         state.isMessageLoading = true
         state.messageIssueCondition = false
      },
      [recordSurveyMail.fulfilled]: (state, action) => {
         state.isMessageLoading = false
         state.messageIssueCondition = true
         state.issueType = 'success'
         state.messageIssue = 'Mail sent successfully'
         // state.messageIssueCondition = false
      },
      [recordSurveyMail.rejected]: (state, action) => {
         state.isMessageLoading = false
         state.messageIssueCondition = true
         state.issueType = 'error'
         state.messageIssue = 'Failed to send mail'
         // state.messageIssueCondition = false
      },

      [PublishTeamReport.pending]: (state, action) => {
      },
      [PublishTeamReport.fulfilled]: (state, action) => {
         console.log('pdfAction', action)
         state.pdfLink = action.payload.Result
      },
      [PublishTeamReport.rejected]: (state, action) => {
      }
   }
})
export const {addRecipientFromStatus, addRecipient, addBcc, removeRecipient, setMailSubject, setMailBody, sendMail, setSenderInformationEmpty, setMailPdfLink, setBccMail } = issueSurveySlice.actions;

export default issueSurveySlice.reducer;