import React,{useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { addRecipientFromStatus} from '../../features/issueSurvey/issueSurveySlice';

import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MessageIndication from "../../components/common-message-indication/MessageIndication";
import IconContentCard from "../../components/common-icon-content-card/IconContentCard";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import BreadCrumb from '../../components/common-breadcrumb-section/BreadCrumb';
import Stack from '@mui/material/Stack';
import ShareIcon from '@mui/icons-material/Share';
import '../StatusContainer/statusContainer.css';
import Divider from '@mui/material/Divider';
import { teamOverallStatusServer } from '../../features/teamStatus/teamStatusSlice';
import { useSelector,useDispatch } from 'react-redux';
import BackDropLoader from '../../components/common-backdrop-loader/BackdropLoader';
import SnackBar from '../../components/snackBar-layout-wrapper/snackBar-layout-wrapper';
import AddTeamDialog from '../../components/add-team-dialog/AddTeamDialog';
import Pagintaion from '../../components/common-pagination/Pagination';
import { teamsMemServer , setTeamMemId} from '../../features/teams/teamsSlice';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconBtn from '../../components/common-icon-btn/IconBtn';

export default function Status() {
    const dispatch = useDispatch()
    const {usertoken} = useSelector((store) => store.login) 
    const {isLoading, teamOverAllStatusData, message, messageCondition, messageType} = useSelector((store) => store.teamStatus)
    const { isLoadingTeamMem, teamMemList } = useSelector((store) => store.teams)
    const [open, setOpen] = useState(false);
    const [statusData, setStatusData] = useState([]);
    const [paginateData, setPaginateData] = useState([]);
    const [isOpenTeamToggle,setIsOpenTeamToggle] = useState(true);
    const [isOpenInfoIcon,setIsOpenInfoIcon] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        if(statusData.length == 0){
            console.log("user token from login",usertoken)
            dispatch(teamOverallStatusServer({usertoken})).then((res) => {
            }).catch((err) => {
            })
        }
    },[statusData])

    useEffect(() => {
        if(teamOverAllStatusData.length != 0){  
            setStatusData(teamOverAllStatusData)
        }
    },[teamOverAllStatusData])
    
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    const handlCloseiconview = (value) =>{
        if(value == "closeicon"){
            setIsOpenTeamToggle(false);
            setIsOpenInfoIcon(true);
        }
        else if(value == "infoicon"){
            setIsOpenTeamToggle(true);
            setIsOpenInfoIcon(false);
        }
       
    }

    const isopenvalue = (value) =>{
        let publis = value['Latest_Published_Date'];
        let publis_date = publis.split(" ");

        return publis_date[0]
    }
    
    const fn_ShareRecipient = (team_id) =>{
        // alert(teamId)
      var recipients=[]
        dispatch(teamsMemServer({ usertoken, team_id })).then((res) => {
            console.log('getResponse',res.payload.data.TeamStatus.Person)
            res.payload.data.TeamStatus.Person.map(x=>{
                recipients.push({name:x.Forenames+x.Surname})
            })
           dispatch(addRecipientFromStatus(recipients))
            navigate('/issuesurvey',{state: { Team_Id: team_id,teamMembers:[] },replace : false})
           
            // console.log('pushedrecipients',recipients)
            
        })
    }
    return (
        <Container maxWidth="xl">
        {/* <SnackBar condition={messageCondition} message={message} type={messageType} /> */}
        <BackDropLoader loaderBool={isLoading} />
        <Grid
            justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }}
            alignItems="center"
            rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
            className="text-center">
            <Stack
                direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
                spacing={2}
                justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }}
                alignItems="start" mb={1}>
                <BreadCrumb breadCrumbName={"Status"} />
            </Stack>
        </Grid>
        <Divider/>
        {statusData.length == 0 && <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <MessageIndication icon={<ErrorOutlineIcon className="message-icn-size" />} message={"Oh uh, You don’t have any team, Add new team"} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6} mb={3} sx={{ display: "flex", justifyContent: "center" }}>
                        <IconContentCard cursor = {true} clrwhite={"#fff"} bgColor={"#1bc5bd"} click={()=>setOpen(true)} icon={<GroupAddOutlinedIcon className="message-icn-size" />} cardTitle={"Add/Edit Team"} description={"Add new team"} />
                    </Grid>
                    </Grid>}
        {statusData.length != 0 && <Grid
        style = {{marginTop : '0px'}}
            container
            justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'center', xl: 'center' }}
            alignItems="start"
            rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
            className="text-center">
            <Grid className="openvaluestatus" item xs={12} sm={12} md={12} lg={isOpenInfoIcon == true ? 'col':'7'} xl={isOpenInfoIcon == true ? 'col':'8'} >
           
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mr-20">
                {paginateData.map(x => (
                    
                <>
                {console.log("Latest_Published_Date",x['Latest_Published_Date'])}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card onClick={() => {
                            let team_id = x['Team_Id']
                            console.log("team_id from status container",team_id)
                            dispatch(setTeamMemId(team_id));
                        


                            // navigate('/teams',{state : {Team_Id : team_id}})
                            // navigate('/issuesurvey',{replace : true})
                            // dispatch(addRecipientFromStatus([]))
                            // navigate('/issuesurvey',{state: { Team_Id: team_id },replace : false})
                        }
                        } className="outerline" style={{ padding: 10 }}>
                            <Grid container onClick={()=>fn_ShareRecipient(x['Team_Id'])} alignItems = "center" className="position-relative pointer">
                                {/* <Grid item md={1} xs={12} sx={12} className="br-clr">
                                    <Typography sx={{ flex: 1 }} variant="h6" >
                                        {x['Team_Id']}
                                    </Typography>
                                </Grid> */}
                                <Grid item md={2} xs={12} sx={12} className="br-clr titlebb">
                                    <Typography sx={{ flex: 1 }} variant="h6" >
                                        {x['Team_Name']}
                                    </Typography>
                                    <Grid item md={1} xs={1} sx={1} display={{xs: "block",sm:"block", md:"none", lg: "none" }} className="shareIconmb">
                                 <ShareIcon />
                                </Grid>
                                </Grid>
                                <Grid item md={2} xs={12} sx={12} flexDirection='row' className="br-clr">
                                    <Typography sx={{ flex: 1 }} variant="h6" className="bg-colorissue font-listview">
                                        <span>{x['Submited_Number']}</span> / <span style = {{color : '#a19d9d'}}>{x['Team_Number']}</span>
                                    </Typography>
                                    <Typography sx={{ flex: 1 }} variant="p" className="compValue">
                                        Completed
                                    </Typography>
                                </Grid>
                                {/* <Grid item md={3} xs={12} sx={12} className="br-clr">
                                    <Typography sx={{ flex: 1 }} variant="h6" >
                                        {x.inCompletedCount}
                                    </Typography>
                                    <Typography sx={{ flex: 1 }} variant="h6" >
                                        InCompleted
                                    </Typography>
                                </Grid> */}
                                <Grid item md={3} xs={12} sx={12} className="br-clr">
                                    <Typography sx={{ flex: 1 }} variant="h6" className="issuesumbitted">
                                        {x['Latest_Submission_Date']}

                                    </Typography>
                                    <Typography sx={{ flex: 1 }} variant="span" className="text-capitalize compValue ">
                                        {x['Latest_Submission_Date'] == '' ? 'no submission' : 'submitted date'}
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={12} sx={12} className="br-clr">
                                <Typography sx={{ flex: 1 }} variant="h6" className="issuesumbittedPublish f-weight-bold">
                                        {x['Status']}
                                    </Typography>
                                    <Typography sx={{ flex: 1 }} variant="span" className="compValuepublish">
                                        {/* {isopenvalue(x)} */}
                                        {x['Latest_Published_Date']}
                                    </Typography>
                                  
                                </Grid>
                                 <Grid  item md={1} xs={12} sx={12} display={{xs: "none",sm:"none", md:"block", lg: "block" }} className="">
                                 <ShareIcon />
                                </Grid>
                                
                            </Grid> 
                        </Card>
                    </Grid>

                </>
                ))}
                </Grid>
                <>
                    <Pagintaion data = {statusData}  paginationData = {(event) => setPaginateData(event)} />
                </>
                
            </Grid>
            <Grid item className="statustoggle"  display={{ xs: "none",sm:"none", md:"inline-flex", lg: "inline-flex" }}>
                            <span>{isOpenTeamToggle == true &&<IconBtn   toggle={true} click={() => handlCloseiconview('closeicon')} icon={<ArrowForwardIosIcon />} />}</span>
                            <span >{isOpenInfoIcon == true &&<IconBtn   toggle={true} click={() => handlCloseiconview('infoicon')} icon={<ArrowBackIosIcon className="backwardicon" />} />}</span>
                        </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={3} mt = {1}  display={{ xs: "none",sm:"none", md:"inline-flex", lg: "inline-flex" }}>
           
                {isOpenTeamToggle == true && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <IconContentCard cursor = {true} clrwhite={"#fff"} bgColor={"#1bc5bd"} click={()=>setOpen(true)} icon={<GroupAddOutlinedIcon className="message-icn-size" />} cardTitle={"Add/Edit Team"} description={"Create a new team by giving it a name(name must be unique)"} />
                </Grid>}
            </Grid>
        </Grid>}
        <AddTeamDialog open={open} title={"add team"} btnNameOne={"add"} clickBtnNameTwo={() => setOpen(!open)} btnNameTwo={"cancel"} close={() => setOpen(!open)} />
    </Container>
    )
}