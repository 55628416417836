import React, { useState, useEffect } from "react";
import { addRecipientFromStatus} from '../../features/issueSurvey/issueSurveySlice';

import DashboardPanel from "../../components/common-dashboard-panel/DashboardPanel";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import '../DashboardContainer/dashboard.css';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AddIcon from '@mui/icons-material/Add';
import BtnIconContent from "../../components/common-icon-content-btn/BtnIconContent";
import SendIcon from '@mui/icons-material/Send';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import BackDropLoader from "../../components/common-backdrop-loader/BackdropLoader";
// import CircularProgress from '@mui/material/CircularProgress';
import {  Stack } from '@mui/material';

import { getTeamsServer } from '../../features/dashboard/dashboardSlice'
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Divider from '@mui/material/Divider';

const Dashboard = (props) => {
    const {  team_id} = useSelector((store) => store.teams)
    const { usertoken } = useSelector((store) => store.login);
    const { isLoading,teamsList } = useSelector((store) => store.dashboard)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [noTeam, setNoTeam] = useState(false)

    const [open, setOpen] = useState(false)
    // team management navigator
    const navigateTeamManagement = () => {
        navigate('/teamManagement',{replace : false})
        // window.location.pathname = "/"
        // window.history.replaceState(null, "", "/")
    }

    // preference navigator
    const navigatePreference = () => {
        navigate('/preference',{replace : false})
        // window.history.replaceState(null, "", "/")
    }

    //survey navigator
    const navigateSurvey = () => {
        // navigate('/issuesurvey', {replace : false})
        dispatch(addRecipientFromStatus([]))
        navigate('/issuesurvey',{state: { Team_Id: team_id },replace : false})
        // window.history.replaceState(null, "", "/")
    }

    //team status navigator
    const navigateTeamStatus = () => {
        navigate('/status',{replace : false})
        // window.history.replaceState(null, "", "/")
    }

    //publication navigator
    const navigatePublication = () => {
        window.alert("publication")
    }

    //settings navigator
    const navigateSettings = () => {
        navigate('/settings', { replace: false })
        // window.history.replaceState(null, '', '/')
    }

    const navigateTeamMembers = () => {
        navigate('/teams',{state : {Team_Id : team_id}})
    }

    const addTeam = () => {
        window.alert("add team")
    }

    const survey = () => {
        // window.alert("survey button")
        // navigate('/issuesurvey', {replace : false})
        navigate('/issuesurvey',{state: { Team_Id: '' },replace : false})

    }

    const addEditTeam = () => {
        setOpen(!open)
    }

    const clickBtnNameOne = () => {
        window.alert("triggered 1")
    }

    const clickBtnNameTwo = () => {
        window.alert("triggered 2")
    }
    useEffect(() => {
        dispatch(getTeamsServer(usertoken));
    }, []);
   
    if (isLoading) {
        return (
            <Stack alignItems="center">
                {/* <CircularProgress /> */}
                <BackDropLoader loaderBool = {isLoading} />
            </Stack>
        )
    }
    else {
        return (
            <>
                <div>
                {/* <Container maxWidth="xl">
                        <Grid
                            justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end', lg: 'flex-end', xl: 'flex-end' }}
                            alignItems="center"
                            rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                            className="text-center">
                            <Stack
                                direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
                                spacing={2}
                                justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end', lg: 'flex-end', xl: 'flex-end' }}
                                alignItems="center" mb={1}>
                                <div>
                                    <BtnIconContent btnIcon={<AddIcon />} btnName={"add"} click={addTeam} />
                                </div>
                                <div>
                                    <BtnIconContent btnIcon={<SendIcon />} btnName={"issue survey"} click={survey} />
                                </div>
                            </Stack>
                        </Grid>
                    </Container> */}
                    
                    <Container maxWidth="lg">
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                            className="text-center">
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <DashboardPanel icon={<GroupsOutlinedIcon className="panel-icn-size panel-icn-center" />} panelDescription={"team management"} panelColor={'#92d050'} clickEvent={navigateTeamManagement} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <DashboardPanel icon={<GroupAddIcon className="panel-icn-size panel-icn-center" />} panelDescription={"team members"} panelColor={'#1bc5bd'} clickEvent={navigateTeamMembers} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <DashboardPanel icon={<PollOutlinedIcon className="panel-icn-size panel-icn-center" />} panelDescription={"survey & report"} panelColor={'#ffa800'} clickEvent={navigateSurvey} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <DashboardPanel icon={<PeopleAltOutlinedIcon className="panel-icn-size panel-icn-center" />} panelDescription={"team status"} panelColor={'#0071e2'} clickEvent={navigateTeamStatus} />
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <DashboardPanel icon={<FeedOutlinedIcon className="panel-icn-size panel-icn-center" />} panelDescription={"publication"} panelColor={'#1bc5bd'} clickEvent={navigatePublication} />
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <DashboardPanel icon={<SettingsOutlinedIcon className="panel-icn-size panel-icn-center" />} panelDescription={"settings"} panelColor={'#f64e60'} clickEvent={navigateSettings} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <DashboardPanel icon={<ManageAccountsOutlinedIcon className="panel-icn-size panel-icn-center" />} panelDescription={"profile"} panelColor={'rgb(36 171 244)'} clickEvent={navigatePreference} />
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </>
        )
    }
    // else  {
    //     return (
    //         <>
    //             <div>
    //                 <Container maxWidth="xl">
    //                     <Grid
    //                         justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end', lg: 'flex-end', xl: 'flex-end' }}
    //                         alignItems="center"
    //                         rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
    //                         className="text-center">
    //                         <Stack
    //                             direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
    //                             spacing={2}
    //                             justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end', lg: 'flex-end', xl: 'flex-end' }}
    //                             alignItems="center" mb={1}>
    //                             <div>
    //                                 <BtnIconContent btnIcon={<AddIcon />} btnName={"add"} click={addTeam} />
    //                             </div>
    //                             <div>
    //                                 <BtnIconContent btnIcon={<SendIcon />} btnName={"issue survey"} click={survey} />
    //                             </div>
    //                         </Stack>
    //                     </Grid>
    //                 </Container>
    //             </div>
    //         </>
    //     )
    // }

}

export default Dashboard