import React,{useState} from "react";
import DialogLayoutWrapper from '../Dialog-layout-wrapper/DialogLayoutWrapper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from "@mui/material";
import {addTeamInput } from "../../features/dashboard/dashboardSlice";
import { useSelector, useDispatch } from "react-redux";
import SwitchButton from '../common-switch-button/SwitchButton';

const EditTeamDialog = (props) => {
    const {addTeamInputValue} = useSelector((store) => store.dashboard)
    const dispatch = useDispatch()
    return(
        <>
            <DialogLayoutWrapper open={props.open} title={props.title} show = {true} btnNameOne={props.btnNameOne} clickBtnNameOne={props.clickBtnNameOne} clickBtnNameTwo={props.clickBtnNameTwo} btnNameTwo={props.btnNameTwo} close={props.close}>
                <Grid container rowSpacing={3}>
                    {/* <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                        <label for = "id" className="text-capitalize">team id</label>
                                    </Grid>
                                    <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                        <TextField id="id" label="team-id" variant="outlined" />   
                                    </Grid>
                                </Grid>
                            </Grid> */}
                    <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                <label for="name" className="text-capitalize">edit team name</label>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
                                <TextField autoComplete = "off" size="small" value = {addTeamInputValue}
                                    className='ipt-size' id="name" variant="outlined" onChange={(event) => {
                                        dispatch(addTeamInput(event.target.value))
                                    }}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {2}>
                                        {/* <label for = "description" className="text-capitalize">description</label> */}
                                    </Grid>
                                    <Grid item xs={12} sm = {12} md = {8} lg = {9} xl = {10}>
                                        {/* <TextareaAutosize
                                        aria-label="minimum height"
                                        id = "description"
                                        minRows={10}
                                        placeholder=""
                                        style={{ width: "100%" }}
                                        />   */}
                                        {/* <SwitchButton change = {(event) => setIsActive(event)} checkLabel = {'Active'} notCheckLabel = {'InActive'} checked = {isActive}/> */}
                                        <SwitchButton change = {(event) => props.change(event)} checkLabel = {'Active'} notCheckLabel = {'InActive'} checked = {props.checked}/>
                                    </Grid>
                                </Grid>
                    </Grid>
                </Grid>
            </DialogLayoutWrapper>
        </>
    )
}

export default EditTeamDialog