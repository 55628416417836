import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./features/login/loginSlice"; // reducer slice
import dashboardReducer from './features/dashboard/dashboardSlice'
import teamManageMentReducer from "./features/teamManagement/teamManagementSlice";
import teamsReducer from "./features/teams/teamsSlice"
import teamStatusReducer from "./features/teamStatus/teamStatusSlice";
import surveyQuestionReducer from "./features/surveyQuestions/surveyQuestionSlice";
import issueSurveyReducer from "./features/issueSurvey/issueSurveySlice";
import settingsReducer from './features/settings/SettingsSlice';

export const store = configureStore({
  
  reducer: {
    login: loginReducer,
    dashboard: dashboardReducer,
    teamManageMent: teamManageMentReducer,
    teams:teamsReducer,
    teamStatus : teamStatusReducer,
    survey :surveyQuestionReducer,
    issueSurvey:issueSurveyReducer,
    settings : settingsReducer
  },
});