import React, { useState } from 'react';
import CenterDialogWrapper from '../center-small-dialog-wrapper/CenterDialogWrapper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const ConfirmDelete = (props) => {
    return (
        <>
            <CenterDialogWrapper open={props.open} title={props.title} show = {true} btnNameOne={props.btnNameOne} clickBtnNameOne={props.clickBtnNameOne} clickBtnNameTwo={props.clickBtnNameTwo} btnNameTwo={props.btnNameTwo} close={props.close}>
                <Grid container rowSpacing={3}>
                    <Grid sx={{ display: "flex", alignItems: "center", justifyContent : 'flex-start' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h3>Do you really want to delete ?</h3>
                    </Grid>
                </Grid>
            </CenterDialogWrapper>
        </>
    )
}

export default ConfirmDelete