import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const initialState = {
    isLoadingTeamMem:false,
    isLoadingdrpdwnMem:false,
    teamMemList:[],
    team_id : '',
    message : '',
    messageCondition : false,
    messageType : '',
    criteria : '%',
    allTeamMemList : [],
    foreName : '',
    surName : '',
    email : '',
    personId : '',
    teamsQueLink:[]
};
export const teamsMemServer = createAsyncThunk(
    "teams/teamsMemServer", async (params) => {
        console.log('initialCheck',params)
        try {
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/TeamStatusJSON?Token='+params.usertoken+'&TeamID='+params.team_id+'&PersonID=%', {
                method: 'GET'
            });
            // const responseQue = await fetch('http://workologydev.clarity4d.com/reportLink/'+params.team_id, {
            //     method: 'GET'
            // });
            const data = await response.json()
            
            // const dataQue = await responseQue.json()
            // console.log('teamMemResult',data)
            return {data
                // ,dataQue
            };
        } catch (err) {
            return console.log(err);
        }
    }
);
export const teamsMemreportLink = createAsyncThunk(
    "teams/teamsMemreportLink", async (params) => {
        console.log('teamsServer',params)
        try {
            const response = await fetch('http://localhost:3000/reportLink/'+params.team_id, {
                method: 'GET'
            });
            const data = await response.json()
            console.log('teamMemResult',data)
            return data;
        } catch (err) {
            return console.log(err);
        }
    }
);

export const removeTeamMemServer = createAsyncThunk(
    "teams/removeMemServer", async (params) => {
        try{
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/RemoveTeamMember?Token='+params.usertoken+'&TeamID='+params.team_id+'&PersonID='+params.personId,{
                method : 'GET'
            })
            const data = await response.json()
            return data;
        }
        catch(err){
            return err;
        }
    }
)

export const listAllTeamMemServer = createAsyncThunk(
    "teams/listAllTeamMemServer",
    async (params) => {
        try{
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/SearchJSON?Token='+params.usertoken+'&Client=%&Criteria='+(params.criteria == '' ? '%' : params.criteria),{
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return err
        }
    }
)

export const addExistingTeamMemServer = createAsyncThunk(
    'teams/addExistingTeamMemServer',
    async (params) => {
        try{
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/AddTeamMember?Token='+params.usertoken+'&TeamID='+params.teamId+'&PersonID='+params.personId,{
            method : 'GET'
            })
            const data = await response.json()
            return data;
        }
        catch(err){
            return err
        }
    }
)

export const editTeamMemServer = createAsyncThunk(
    'teams/editTeamMemServer',
    async(params) => {
        console.log("edit team server params",params)
        try{
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/UpdateTeamMemberJSON?Token='+params.usertoken+'&TeamID='+params.team_id+'&PersonID='+params.personId+'&Firstname='+params.foreName+'&Surname='+params.surName+'&Email='+params.email,{
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return err
        }
    }
)

const teamsSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        setTeamMemId : (state, action) => {
            console.log("team id action",action.payload,state.team_id)
            state.team_id = action.payload
            // console.log("state.team_id",state.team_id)
        },
        setCriteriaValue : (state, action) => {
            state.criteria = action.payload.criteria
        },
        setForeName : (state, action) => {
            console.log('forename',action.payload)
            state.foreName = action.payload
            console.log("state.foreName",state.foreName)
        },
        setSurName : (state, action) => {
            state.surName = action.payload
        },
        setEmail : (state, action) => {
            state.email = action.payload
        },
        setPersonId : (state, action) => {
            state.personId = action.payload
        }
    },
    extraReducers: {
        [teamsMemServer.pending]: (state) => {
            state.isLoadingTeamMem = true;
            // state.team_id = '';
            // state.teamMemList = []
          },
          [teamsMemServer.fulfilled]: (state, action) => {
            console.log('checkActioncheckAction',action)
            state.isLoadingTeamMem = false;
            // console.log('typeof',typeof(action.payload.TeamStatus.Person),action.payload.TeamStatus.Person)
            // state.teamMemList=action.payload
            if(action.payload.data.TeamStatus.Person == '[]'){
                state.teamMemList = []
            }
            else{
                state.teamMemList = (Array.isArray(action.payload.data.TeamStatus.Person) ? action.payload.data.TeamStatus.Person.map((x, index) => {
                     console.log('testConsole',action.payload)
                     let key1 = btoa(x['Forenames'])
                     let key2 = btoa(x['Surname'])
                     let key3 = btoa(x['Email_Address'])
                    return{
                        id : index,
                        personId : x['Person_Id'],
                        forename : x['Forenames'],
                        surname : x['Surname'],
                        name : x['Forenames']+' '+ x['Surname'],
                        emailAddress : x['Email_Address'],
                        dateSent : x['Date_Sent'],
                        dateSubmitted : x['Date_Submitted'],
                        teamName : x['Team_Name'],
                        teamId : x['Team_Id'],
                        questionnaireLink : (window.location.protocol+'//'+window.location.host+'/surveyForm/?usertoken=PUBLIC&teamId=' + x['Team_Id'] + '&personId=' + x['Person_Id'] + '&key1=' + key1 + '&key2=' + key2 + '&key3=' + key3),
                        // queLink:action.payload.dataQue.find(y=>y.personId==x.Person_Id && y.Team_Id==x.teamId)!=undefined?action.payload.dataQue.find(y=>y.personId==x.Person_Id).link:''
                        queLink:''

                    }
                }) : [{
                    id : 0,
                    personId : action.payload.data.TeamStatus.Person['Person_Id'],
                    forename : action.payload.data.TeamStatus.Person['Forenames'],
                    surname : action.payload.data.TeamStatus.Person['Surname'],
                    name : action.payload.data.TeamStatus.Person['Forenames'] +' '+ action.payload.data.TeamStatus.Person['Surname'],
                    emailAddress : action.payload.data.TeamStatus.Person['Email_Address'],
                    dateSent : action.payload.data.TeamStatus.Person['Date_Sent'],
                    dateSubmitted : action.payload.data.TeamStatus.Person['Date_Submitted'],
                    teamName : action.payload.data.TeamStatus.Person['Team_Name'],
                    teamId : action.payload.data.TeamStatus.Person['Team_Id'],
                    // queLink:action.payload.dataQue.find(y=>y.personId==action.payload.data.TeamStatus.Person['Person_Id'])!=undefined?action.payload.dataQue.find(y=>y.personId==action.payload.data.TeamStatus.Person['Person_Id']).link:''
                    queLink:''
                }])
            }
          },
          [teamsMemServer.rejected]: (state, action) => {
            state.isLoadingTeamMem = false;
          },
          [removeTeamMemServer.pending] : (state, action) => {
            state.isLoadingTeamMem = true
            state.messageCondition = false
          },
          [removeTeamMemServer.fulfilled] : (state, action) => {
            state.messageCondition = true;
            state.message = "Removed Successfully";
            state.messageType = 'success'
          },
          [removeTeamMemServer.rejected] : (state, action) => {
            state.messageCondition = true;
            state.message = "Failed to remove"
            state.messageType = 'error'
          },
        //   ---------------------------------
        [teamsMemreportLink.pending] : (state, action) => {

          },
          [teamsMemreportLink.fulfilled] : (state, action) => {
                state.teamsQueLink = action.payload
          },
          [teamsMemreportLink.rejected] : (state, action) => {

          },
          [listAllTeamMemServer.pending] : (state, action) => {

          },
          [listAllTeamMemServer.fulfilled] : (state, action) => {
            if(action.payload.Result.Person == '[]'){
                state.allTeamMemList = []
            }
            else{
                state.allTeamMemList = (Array.isArray(action.payload.Result.Person) ? action.payload.Result.Person.map((x, index) => {
                    return{
                        // id : index,
                        // personId : x['Person_Id'],
                        // forename : x['Forenames'],
                        // surname : x['Surname'],
                        // emailAddress : x['Email_Address'],
                        // dateCreated : x['Date_Created'],
                        // jobTitle : x['Job_Title'],
                        // clientId : x['Client_Id']
                        id : index,
                        personId : x['Person_Id'],
                        forename : x['Forenames'],
                        surname : x['Surname'],
                        name : x['Forenames']+' '+ x['Surname'],
                        emailAddress : x['Email_Address'],
                        dateCreated : x['Date_Created'],
                        jobTitle : x['Job_Title'],
                        clientId : x['Client_Id']
                    }
                }) : 
                // [{
                //     id : 0,
                //     personId : action.payload.Result.Person['Person_Id'],
                //     forename : action.payload.Result.Person['Forenames'],
                //     surname : action.payload.Result.Person['Surname'],
                //     emailAddress : action.payload.Result.Person['Email_Address'],
                //     dateCreated : action.payload.Result.Person['Date_Created'],
                //     jobTitle : action.payload.Result.Person['Job_Title'],
                //     clientId : action.payload.Result.Person['Client_Id']
                // }]
                [{
                    id : 0,
                    personId : action.payload.Result.Person['Person_Id'],
                    forename : action.payload.Result.Person['Forenames'],
                    surname : action.payload.Result.Person['Surname'],
                    name : action.payload.Result.Person['Forenames'] +' '+ action.payload.Result.Person['Surname'], 
                    emailAddress : action.payload.Result.Person['Email_Address'],
                    dateCreated : action.payload.Result.Person['Date_Created'],
                    jobTitle : action.payload.Result.Person['Job_Title'],
                    clientId : action.payload.Result.Person['Client_Id']
                }]
                )
            }
          },
          [listAllTeamMemServer.rejected] : (state, action) => {

          },
          [addExistingTeamMemServer.pending] : (state, action) => {
            state.messageCondition = false;
          },
          [addExistingTeamMemServer.fulfilled] : (state, action) => {
            state.messageCondition = true;
            state.message = "Added Successfully";
            state.messageType = 'success'
          },
          [addExistingTeamMemServer.rejected] : (state, action) => {
            state.messageCondition = true;
            state.message = "Failed to add";
            state.messageType = 'error'
          }
         
    }
})
export const {setTeamMemId, setCriteriaValue, setForeName, setEmail, setSurName, setPersonId} = teamsSlice.actions;
export default teamsSlice.reducer;