import React,{useEffect, useState} from 'react';
import Pagination from '@mui/material/Pagination';
import usePagination from './UsePagination';
import '../common-pagination/pagination.css';

export default function Pagintaion(props) {
    let [page, setPage] = useState(1);
    const PER_PAGE = 5;

    const count = Math.ceil(props.data.length / PER_PAGE);
    const _DATA = usePagination(props.data, PER_PAGE);

    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p)
    };

    useEffect(() => {
        props.paginationData(_DATA.currentData())
    },[_DATA])
  
  return (
    <>
        <Pagination
        count={count}
        size="large"
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handleChange}
        className="pagespace"
        />
    </>
  );
}