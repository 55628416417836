import React, { useState } from 'react';
import Container from '@mui/material/Container';
import BreadCrumb from '../../components/common-breadcrumb-section/BreadCrumb';
import Grid from '@mui/material/Grid';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import BtnIconContent from '../../components/common-icon-content-btn/BtnIconContent';
import CommonSelect from '../../components/common-select/CommonSelect';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import BtnContent from '../../components/common-content-btn/BtnContent';
import '../PreferenceContainer/preference.css';
import ChangePasswordDialog from '../../components/change-password-dialog/ChangePasswordDialog';
import Divider from '@mui/material/Divider';
import { changePasswordServer ,requiredPsdField,closeSnackBar} from '../../features/settings/SettingsSlice'
import { useDispatch, useSelector } from "react-redux";
import SnackBar from '../../components/snackBar-layout-wrapper/snackBar-layout-wrapper';
import { useNavigate } from 'react-router';


const Preference = () => {
    const navigate = useNavigate()
    const { email, name, accInforUserName, lastLoggedOn } = useSelector((store) => store.login);
    const { message, messageCondition, messageType } = useSelector((store) => store.settings)
    const { usertoken } = useSelector((store) => store.login)
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const clickBtnNameOne = (oldPsd, newPsd,againPsd) => {
        // console.log('updatePassword',usertoken,name,oldPsd,newPsd)
        if(name==''||oldPsd==''||newPsd==''||againPsd==''){
            dispatch(requiredPsdField('Enter all the required fields'))
            setTimeout(() => {
                dispatch(closeSnackBar())
            },1000)
        }
        else if(oldPsd==newPsd){
            dispatch(requiredPsdField('Your new password cannot be the same as your current password'))
            setTimeout(() => {
                dispatch(closeSnackBar())
            },1000)
        }
        else{
        dispatch(changePasswordServer({ usertoken, name, oldPsd, newPsd })).then((res) => {
            if (res.payload.Result == "Password Changed Successfully") {
                setTimeout(() => {
                    localStorage.clear()
                    navigate('/', { replace: false })
                    window.location.reload()
                }, 3000);
            }
            else {
                dispatch(requiredPsdField(res.payload.Result))

            }
            console.log('res from update password', res)
        })
    }
    }

    const clickBtnNameTwo = () => {
        setOpen(!open)
    }
    const changePasswordfunction = () => {

    }

    return (
        <>
            <div>
                <Container maxWidth="xl">
                    <Grid
                        justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }}
                        alignItems="center"
                        rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                        className="text-center">
                        <Stack
                            direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
                            spacing={2}
                            justifyContent={{ xs: 'space-between', sm: 'space-between', md: 'space-between', lg: 'space-between', xl: 'space-between' }}
                            alignItems="start" mb={1}>
                            <BreadCrumb breadCrumbName={"Preference"} />
                            {/* <Grid item xs={12} sm = {12} md = {4} lg = {6} xl = {6}>
                                <Grid container rowSpacing={3} justifyContent = {{xs: "center", sm: "center", md: "center", lg : "end", xl : "end"}}>
                                    <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                        <Grid container alignItems={"center"} justifyContent = {{xs: "center", sm: "center", md: "center", lg : "end", xl : "end"}}> */}
                            <BtnIconContent btnName={'change password'} btnIcon={<HttpsOutlinedIcon />} click={() => setOpen(true)} />
                            {/* </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                        </Stack>
                    </Grid>
                </Container>
                <Divider />
                <Container maxWidth="xl" className="m-t-10">
                    <Grid
                        container
                        justifyContent={"center"}
                        rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container rowSpacing={3} justifyContent={"center"}>
                                {/* <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm = {12} md = {12} lg = {5} xl = {5} className = "pd-r-10px text-right">
                                            <label for = "id" className="text-capitalize">start up window</label>
                                        </Grid>
                                        <Grid item xs={12} sm = {12} md = {12} lg = {4} xl = {4}>
                                            <CommonSelect list={[]} label = {""}/>   
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className="text-right pd-r-10px">
                                            <label for="name" className="text-capitalize">name</label>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <TextField value={accInforUserName} editable={false} size="small" disabled className='ipt-size' id="name" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className="text-right pd-r-10px">
                                            <label for="name" className="text-capitalize">user name</label>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <TextField disabled value={name} editable={false} size="small" className='ipt-size' id="name" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className="text-right pd-r-10px">
                                            <label for="name" className="text-capitalize">email address</label>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <TextField value={email} editable={false} size="small" disabled className='ipt-size' id="name" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm = {12} md = {12} lg = {5} xl = {5} className = "text-right pd-r-10px">
                                            <label for = "name" className="text-capitalize">email sent from me</label>
                                        </Grid>
                                        <Grid item xs={12} sm = {12} md = {12} lg = {4} xl = {4}>
                                            <CommonSelect label = {""} list = {[]}/>   
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                {/* <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm = {12} md = {12} lg = {5} xl = {5} className = "text-right pd-r-10px">
                                            <label for = "name" className="text-capitalize">notify me of submission</label>
                                        </Grid>
                                        <Grid item xs={12} sm = {12} md = {12} lg = {4} xl = {4}>
                                            <CommonSelect label = {""} list = {[]}/>   
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                {/* <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm = {12} md = {12} lg = {5} xl = {6}>
                                            <label for = "name" className="text-capitalize">Security question</label>
                                        </Grid>
                                        <Grid className = "pd-l-8px" item xs={12} sm = {12} md = {12} lg = {7} xl = {6}>
                                            <TextField className='ipt-size' id="name" label="team-name" variant="outlined" />   
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm = {12} md = {12} lg = {5} xl = {6}>
                                            <label for = "name" className="text-capitalize">security answer</label>
                                        </Grid>
                                        <Grid className = "pd-l-8px" item xs={12} sm = {12} md = {12} lg = {7} xl = {6}>
                                            <TextField className='ipt-size' id="name" label="team-name" variant="outlined" />   
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className="text-right pd-r-10px">
                                            <label for="name" className="text-capitalize">Last Logged On</label>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <TextField disabled value={lastLoggedOn} editable={false} size="small" className='ipt-size' id="name" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} sm = {12} md = {4} lg = {6} xl = {6}>
                                <Grid container rowSpacing={3} justifyContent = {{xs: "center", sm: "center", md: "center", lg : "end", xl : "end"}}>
                                    <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                        <Grid container alignItems={"center"} justifyContent = {{xs: "center", sm: "center", md: "center", lg : "end", xl : "end"}}>
                                            <BtnIconContent btnName = {'change password'} btnIcon = {<HttpsOutlinedIcon/>} click = {() => setOpen(true)}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                    </Grid>
                </Container>
                {/* <Grid 
                                container 
                                justifyContent="end"
                                alignItems="center"
                                mt={2}>
                                    <Grid item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                        <Grid container justifyContent = {{xs: "center", sm: "center", md: "center", lg : "center", xl : "center"}}>
                                                    <div className='mar-r-10'>
                                                        <BtnContent color = {"primary"}><span>save</span></BtnContent>
                                                    </div>
                                                    <div>
                                                        <BtnContent color = {"primary"}><span>cancel</span></BtnContent>
                                                    </div>
                                        </Grid>
                                    </Grid>
                </Grid> */}
                <ChangePasswordDialog open={open} close={() => setOpen(!open)} clickBtnNameOne={(oldPsd, newPsd,againPsd) => clickBtnNameOne(oldPsd, newPsd,againPsd)} clickBtnNameTwo={() => clickBtnNameTwo()} />
                <SnackBar condition={messageCondition} message={message} type={messageType} />
            </div>
        </>
    )
}

export default Preference