import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogActions } from '@mui/material';
import BtnContent from '../common-content-btn/BtnContent';


export default function CenterDialogWrapper(props) {
  const [show, setShow] = React.useState(true)
//   const theme = useTheme();
//   const xlResolutionIdentifier = useMediaQuery(theme.breakpoints.down('xl'));
//   const lgResolutionIdentifier = useMediaQuery(theme.breakpoints.down('lg'));
//   const mdResolutionIdentifier = useMediaQuery(theme.breakpoints.down('md'));
//   const smResolutionIdentifier = useMediaQuery(theme.breakpoints.down('sm'));
//   const xsResolutionIdentifier = useMediaQuery(theme.breakpoints.down('xs'));
  React.useEffect(() => {
    setShow(props.show)
  },[props.show])
  return (
    <div>
      <form>
        <Dialog
        //   className={xsResolutionIdentifier ? '' : smResolutionIdentifier ? '' : mdResolutionIdentifier ? '' : lgResolutionIdentifier ? 'dialog-position-setter' : xlResolutionIdentifier ? 'dialog-position-setter' : 'dialog-position-setter'}
          open={props.open}
          onClose={props.close}
        >
        <DialogTitle sx = {{display : "flex", alignItems : "center"}}>
              <Typography sx={{ flex: 1 }} variant="h6" component="div" className='text-capitalize'>
                {props.title}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.close}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
        </DialogTitle>
        <Divider/>
        <DialogContent>
            {props.children}
        </DialogContent>
        <Divider/>
        <DialogActions>
            {show && <BtnContent click = {props.clickBtnNameOne} color = {'primary'}>
              {props.btnNameOne}
            </BtnContent>}
            <BtnContent click = {props.clickBtnNameTwo} color = {'primary'}>
              {props.btnNameTwo}
            </BtnContent>
        </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
