import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const initialState = {
    firstNameValue: '',
    surNameValue: '',
    emailValue: '',
    tableCheckedId:'',
    groupTableCheckedArr : [],
    messageConditionTeamMng : false,
    messageTypeTeamMng : '',
    messageTeamMng : '',
    isLoading : false
};
export const addNewMemServer = createAsyncThunk(
    "teamManageMent/addNewMemServer", async (params) => {
        console.log('checkaddParams',params)
        try {
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/AddNewTeamMember?Token='+params.usertoken+'&TeamID='+params.tableCheckedId+'&Firstname='+params.firstNameValue+'&Surname='+params.surNameValue+'&Email='+params.emailValue, {
                method: 'GET'
            });
            const data = await response.json()
            return data;
        } catch (err) {
            return console.log(err);
        }
    }
);

export const removeTeamServer = createAsyncThunk(
    "dashboard/removeTeamServer",async(params) => {
      try{
        // https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/RemoveTeamJSON?Token=9234629d-1ba4-4f87-a24f-972891c90f73&TeamID=5771
        const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/RemoveTeamJSON?Token='+params.usertoken+'&TeamID='+params.teamId,{
          method : 'GET'
        });
        const data = await response.json()
        return data;
      }catch(err){
        return console.log(err)
      }
    }
)
// export const addTeamServer = createAsyncThunk(
//     "teamManageMent/addTeamServer", async (params) => {
//         try {
//             const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/AddTeam?Token=' + params.usertoken + '&TeamName=' + params.addTeamInputValue, {
//                 method: 'GET'
//             });
//             const data = await response.json()
//             return data;
//         } catch (err) {
//             return console.log(err);
//         }
//     }
// );
const dashboardSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
      
        firstName: (state, action) => {
            state.firstNameValue = action.payload
        },
        surName: (state, action) => {
            state.surNameValue = action.payload
        },
        email: (state, action) => {
            state.emailValue = action.payload
        },
        selectedGridDataChecked: (state, action) => {
            state.tableCheckedId = action.payload
        },
        groupGridDataChecked : (state,action) => {
            state.groupTableCheckedArr = action.payload
        },
        setMessageShowBool : (state, action) => {
          state.messageConditionTeamMng = false
          state.messageConditionTeamMng = false
        }
    },
    extraReducers: {

        [addNewMemServer.pending]: (state) => {
            state.isLoading = true;
            state.messageConditionTeamMng = false;
          },
          [addNewMemServer.fulfilled]: (state, action) => {
            console.log('newPersonAdded', state, action)
            state.isLoading = false;
            state.messageConditionTeamMng = true;
            state.messageTypeTeamMng = 'success';
            state.messageTeamMng = 'Member added successfully';
            state.firstName='';
            state.surName='';
            state.emailValue=''
          },
          [addNewMemServer.rejected]: (state, action) => {
            state.isLoading = false;
            state.messageConditionTeamMng = true;
            state.messageTypeTeamMng = 'error';
            state.messageTeamMng = 'Failed to add member'
          },
          [removeTeamServer.pending]:(state) => {
            state.isLoading = true;
            state.messageConditionTeamMng = false;
          },
          [removeTeamServer.fulfilled]: (state,action) => {
            state.isLoading = false;
            state.messageConditionTeamMng = true;
            state.messageTypeTeamMng = 'success';
            state.messageTeamMng = 'Team deleted successfully'
          },
          [removeTeamServer.rejected]:(state,action) =>{
            state.isLoading = false;
            state.messageConditionTeamMng = true;
            state.messageTypeTeamMng = 'error';
            state.messageTeamMng = 'Failed to delete'
          }
    },


});
export const { firstName, surName, email,selectedGridDataChecked, groupGridDataChecked,requiredValues,setMessageShowBool } = dashboardSlice.actions;
export default dashboardSlice.reducer;