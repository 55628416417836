import React, { useState, useEffect } from 'react';
import DialogLayoutWrapper from '../../components/Dialog-layout-wrapper/DialogLayoutWrapper';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import SearchIcon from '@mui/icons-material/Search';
import SnackBar from '../../components/snackBar-layout-wrapper/snackBar-layout-wrapper';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import BtnIconContent from '../../components/common-icon-content-btn/BtnIconContent';
import Grid from '@mui/material/Grid';
import CommonDataGrid from '../common-data-grid/CommonDataGrid';
import BtnContent from '../common-content-btn/BtnContent';
import { useSelector, useDispatch } from 'react-redux';
import { addNewMemServer, email, firstName, tableCheckedId, surName, requiredValues, closeRequiredValues } from '../../features/teamManagement/teamManagementSlice';
import { teamsMemServer, listAllTeamMemServer, setCriteriaValue, addExistingTeamMemServer } from '../../features/teams/teamsSlice';
import '../add-team-member-test-dialog/add-team-member-test-dialog.css'

const AddTeamMemberTestDialog = (props) => {
    const tableCheckedId = props.teamId
    const dispatch = useDispatch();
    const [CheckBox, setCheckBox] = useState({ messageCondition: false, message: '', messageType: '' })
    const { usertoken } = useSelector((store) => store.login)
    const { firstNameValue, surNameValue, emailValue, } = useSelector((store) => store.teamManageMent)
    const { criteria, allTeamMemList } = useSelector((store) => store.teams)
    const [gridDataChecked, setGridDataChecked] = useState([])
    useEffect(() => {
        dispatch(listAllTeamMemServer({ usertoken, criteria })).then((res) => {
            console.log("isAllTeamMemServer", res)
        }).catch((err) => {
            console.log("isAllTeamMemServer err", err)
        })
    }, [])

    const dialogColumns = [
        {
            field: 'personId', headerName: 'PersonID', minWidth: 90,
            flex: 'auto'
        },
        // {
        //     field: 'forename',
        //     headerName: 'First Name',
        //     minWidth: 150,
        //     flex: 1,
        //     editable: false,
        // },
        // {
        //     field: 'surname',
        //     headerName: 'Surname',
        //     minWidth: 150,
        //     flex: 1,
        //     editable: false,
        // },
        {
            field: 'name',
            headerName: 'Name',
            // minWidth: 150,
            flex: 1,
            editable: false,
        },
        // {
        //     field: 'title',
        //     headerName: 'Title',
        //     minWidth: 150,
        //     flex: 2,
        //     editable: false,
        // },
        {
            field: 'action',
            headerName: 'Action',
            // minWidth: 150,
            flex: 'auto',
            editable: false,
            renderCell: (params) => {
                console.log("params", params)
                return (
                    <div>
                        <BtnContent color={'primary'} click={() => {
                            let teamId = props.teamId
                            let personId = params.row.personId
                            var team_id = props.teamId
                            dispatch(addExistingTeamMemServer({ usertoken, teamId, personId })).then((res) => {
                                console.log("added successfully", res)
                                dispatch(teamsMemServer({ usertoken, team_id })).then((res) => {
                                    console.log('props.close', res)
                                    // props.close()
                                })
                            }).catch((err) => {
                                console.log("added failed", err)
                            })
                        }}>add</BtnContent>
                    </div>
                )
            }
        }

    ];
    const dialogRows = [
        { id: 1, firstName: 'Alex Pan', surName: '', title: '', action: "Add" },
        { id: 2, firstName: 'James Pan', surName: '', title: '', action: "Add" },
        { id: 3, firstName: 'Dinesh Devarajan', surName: '', title: '', action: "Add" },
        { id: 4, firstName: 'James Wan', surName: '', title: '', action: "Add" },
        { id: 5, firstName: 'John Victor', surName: '', title: '', action: "Add" },
        { id: 6, firstName: 'Victor', surName: '', title: '', action: "Add" },
        { id: 7, firstName: 'Dave', surName: '', title: '', action: "Add" },
        { id: 8, firstName: 'Henry', surName: '', title: '', action: "Add" },
        { id: 9, firstName: 'Lawrence', surName: '', title: '', action: "Add" },
        { id: 10, firstName: 'Nicholas', surName: '', title: '', action: "Add" },
        { id: 11, firstName: 'Pooran', surName: '', title: '', action: "Add" },
        { id: 12, firstName: 'Carlsen', surName: '', title: '', action: "Add" },
        { id: 13, firstName: 'Jonty', surName: '', title: '', action: "Add" },
        { id: 14, firstName: 'Rhodes', surName: '', title: '', action: "Add" },

    ];

    function clickBtnNameOne() {
        window.alert("button clicked one")
    }

    function clickBtnNameTwo() {
        window.alert("button clicked two")
    }

    const rowClick = (params, event) => {
        window.alert(params, event)
    }

    const checkBoxSelected = (id) => {
        setGridDataChecked(gridDataChecked => id)
    }

    const addNewPerson = () => {
        dispatch(addNewMemServer({ firstNameValue, surNameValue, emailValue, usertoken, tableCheckedId })).then((res) => {
            var team_id = props.teamId
            console.log("teamId from add new member", team_id)
            dispatch(teamsMemServer({ usertoken, team_id })).then((res) => {
                console.log('props.close', res)
                props.close()
            })
            // props.close()
        })
    }
    const addMemServer = () => {
        console.log('addMemServer', firstNameValue, surNameValue, emailValue, usertoken, tableCheckedId)
        if (firstNameValue == '' || surNameValue == '' || emailValue == '') {
            setCheckBox({ messageCondition: true, message: 'Enter all the required fields', messageType: 'error' })
            setTimeout(() => {
                setCheckBox({ messageCondition: false, message: '', messageType: '' })
            }, 3000);


        }
        else {
            dispatch(addNewMemServer({ firstNameValue, surNameValue, emailValue, usertoken, tableCheckedId })).then((res) => {
                console.log('checkresres', res)
                var team_id = props.teamId
                console.log("teamId from add new member", team_id)
                dispatch(teamsMemServer({ usertoken, team_id })).then((res) => {
                    console.log('props.close', res)
                    // props.close()
                })
            })
        }
    }
    return (
        <>
            <DialogLayoutWrapper open={props.open} title={"add team member"} show={false} clickBtnNameTwo={props.close} btnNameTwo={"cancel"} close={props.close}>
                <Grid container rowSpacing={3}>
                    <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container alignItems={"center"} sx={{ display: "flex", alignItems: "center" }}>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                <label for="name" className="text-capitalize">Name</label>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={9} xl={4}>
                                <TextField size="small" autoComplete="off" onChange={(e) => {
                                    let criteria = e.target.value
                                    dispatch(setCriteriaValue({ criteria }))
                                    dispatch(listAllTeamMemServer({ usertoken, criteria })).then((res) => {
                                        console.log("isAllTeamMemServer", res)
                                    }).catch((err) => {
                                        console.log("isAllTeamMemServer err", err)
                                    })
                                }} className='ipt-size' id="name" autocomplete="off" variant="outlined" />
                            </Grid>
                            {/* <Grid item xs={12} sm = {12} md = {4} lg = {3} xl = {1} className="">
                                        <SearchIcon className="ml-10" onClick = {() => {
                                            dispatch(listAllTeamMemServer({usertoken,criteria})).then((res) => {
                                                console.log("isAllTeamMemServer",res)
                                            }).catch((err) => {
                                                console.log("isAllTeamMemServer err",err)
                                            })
                                        }}/>
                                    </Grid> */}
                            <Grid item xs={12} sm={12} md={8} lg={9} xl={6} pl={2}>
                                <p className="disabled-color">Search for people to add to this team <a href="#user-section" target='_self'>or Add New Person</a></p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CommonDataGrid columns={dialogColumns} rows={allTeamMemList} checkBoxSelected={checkBoxSelected} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className='mar-0 text-capitalize font-weight-600 pd-10px'>or add new person</p>
                    </Grid>
                    {/* <Grid sx = {{display : "flex", alignItems : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Grid container alignItems={"center"}>
                                    <Grid sx = {{display : "flex", alignItems : "center", justifyContent : "center"}} item xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                    <div>
                                        <BtnIconContent btnIcon={<PersonAddAltOutlinedIcon />} btnName={"Add New Person"} click={addNewPerson} />
                                    </div>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                    <Grid id="user-section" sx={{ display: "flex", alignItems: "flex-start" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={1} alignItems={"flex-start"} sx={{ display: "flex", alignItems: "flex-start" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container alignItems={"center"} rowSpacing={1} sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <label for="firstName" className="text-capitalize">first name*</label>
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <TextField size="small" autoComplete="off" className='ipt-size' id="firstName" onChange={(event) => dispatch(firstName(event.target.value))} variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: "center" }}>
                                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign={{ xs: 'start', sm: 'start', md: 'start', lg: 'end', xl: 'end' }} pr={3}>
                                            <label for="surname" className="text-capitalize">surname*</label>
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <TextField size="small" autoComplete="off" className='ipt-size' id="surname" onChange={(event) => dispatch(surName(event.target.value))} variant="outlined" />
                                        </Grid>
                                        {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign={{ xs: 'start', sm: 'start', md: 'start', lg: 'end', xl: 'end' }} pr={3}>
                                            <label for="surname" className="text-capitalize">surname*</label>
                                        </Grid> */}

                                    </Grid>
                                    {/* <Grid mt = {{ xs: 2, sm: 2, md: 0, lg : 0, xl : 0 }} container alignItems={"center"} justifyContent = "flex-start" sx = {{display : "flex", alignItems : "center", justifyContent : "flex-start"}}> */}

                                    {/* </Grid> */}
                                    {/* <Grid item  xs={4} sm = {4} md = {4} lg = {4} xl = {4}>
                                                <label for = "email" className="text-capitalize">email</label>
                                            </Grid>
                                            <Grid item xs={8} sm = {8} md = {8} lg = {8} xl = {8}>
                                                <TextField size="small" autoComplete = "off" className='ipt-size' id="email" onChange={(event) => dispatch(email(event.target.value))} variant="outlined" />
                                            </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container alignItems={"center"} justifyContent="flex-start" sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                    {/* <Grid item xs={4} sm = {4} md = {4} lg = {4} xl = {4}>
                                                <label for = "surname" className="text-capitalize">surname</label>
                                            </Grid>
                                            <Grid item xs={8} sm = {8} md = {8} lg = {8} xl = {8}>
                                                <TextField size="small" autoComplete = "off" className='ipt-size' id="surname" onChange={(event) => dispatch(surName(event.target.value))} variant="outlined" /> 
                                            </Grid> */}
                                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <label for="email" className="text-capitalize">email*</label>
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <TextField size="small" autoComplete="off" className='ipt-size' id="email" onChange={(event) => dispatch(email(event.target.value))} variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display = 'flex' justifyContent = 'end'>
                                            <BtnIconContent startIcon={PersonAddAltOutlinedIcon} btnName={'add new person'} click={addMemServer} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <SnackBar condition={CheckBox.messageCondition} message={CheckBox.message} type={CheckBox.messageType} />
        </DialogLayoutWrapper>
        </>
    )
}

export default AddTeamMemberTestDialog