import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function BreadCrumb(props) {
  const navigate = useNavigate()
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" style = {{cursor : 'pointer'}} onClick = {() => navigate('/dashboard')}>
      Home
    </Link>,
    `${props.breadcrumbTwo != '' && <Link
    underline="hover"
    key="2"
    color="inherit"
    href="/material-ui/getting-started/installation/"
    // onClick={handleClick}
  >
  {props.breadcrumbTwo}
  </Link>}`
    ,
    <Typography key="1" color="text.primary">
    {props.breadCrumbName}
    </Typography>,
  ];

  return (
    <Stack spacing={2} >
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
