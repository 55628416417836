import React from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import '../CompletedSurveyContainer/completed-survey-container.css';
import BtnIconContent from "../../components/common-icon-content-btn/BtnIconContent";
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

const CompletedSurveyContainer = () => {
    function close_window() {
        // if (window.confirm("Close Window?")) {
          window.close();
        // }
      }
    return(
        <>  
                    <Container maxWidth = "xl">
                        <Grid
                            container
                            style = {{height : '100vh', width : '100vw'}}
                            justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'center', xl: 'center' }}
                            alignItems="center">
                                <Grid item alignItems={'center'}>
                                    <BtnIconContent click = {() => window.close()} btnIcon={<TaskAltOutlinedIcon />} btnName={"Questionnaire completed successfully. Thank you for your response."}/>
                                    <h4 className="text-center txt-underline">You may now close this webpage.</h4>
                                </Grid>
                        </Grid>
                    </Container>
        </>
    )
}

export default CompletedSurveyContainer