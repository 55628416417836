import React from 'react'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import '../common-content-btn/BtnContent.css';
const BtnIconContent = (props) => {
    console.log('buttonProps',props)
    return(
        <>
            <Stack direction="row" spacing={2}>
                <Button className = {props.align == true ? (props.teamStatus == true ? 'alignbtn text-capitalize btn-statusclr' : 'alignbtn text-capitalize') : (props.teamStatus == true ? 'text-capitalize btn-statusclr' : 'text-capitalize')}  variant="contained" color = {props.color} onClick = {props.click}  size={props.size}>
                    {props.children}
                </Button>
            </Stack>
        </>
    )
}

export default BtnIconContent