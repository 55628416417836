import React from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import '../common-icon-content-card/icon-content-card.css';

const IconContentCard = (props) => {
    return(
        <>
                        {/* <Grid 
                        container 
                        justifyContent="center"
                        alignItems="center" 
                        rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg : 4, xl : 5 }}> */}
                            <Grid               
                            item 
                            xs={12} sm = {12} md = {12} lg = {12} xl = {12}>
                                <Card onClick = {props.click} className={props.class} sx={{ color:`${props.clrwhite}`,display: 'flex', minWidth : '300px', justifyContent : 'center', background : `${props.bgColor}` }}
                                className={props.cursor ? "cursor-pointer" : ""}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <CardContent className = "icn-position" sx={{ flex: '50%' }}>
                                            <div>
                                                {props.icon}
                                            </div>
                                        </CardContent>
                                        <CardContent sx={{ flex: '70%', textAlign : 'start' }}>
                                            <div>
                                                <div>                                                    
                                                    <h3>{props.cardTitle}</h3>
                                                </div>
                                                <div>                                                    
                                                    <p>{props.description}</p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Grid>
                        {/* </Grid> */}
        </>
    )
}

export default IconContentCard