import React, { useState, useEffect, useCallback } from 'react';
import MessageIndication from '../../components/common-message-indication/MessageIndication';
import IconContentCard from '../../components/common-icon-content-card/IconContentCard';
import Grid from '@mui/material/Grid';
import { addRecipientFromStatus} from '../../features/issueSurvey/issueSurveySlice';

import Container from '@mui/material/Container';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CommonDataGrid from '../../components/common-data-grid/CommonDataGrid';
import BtnContent from '../../components/common-content-btn/BtnContent';
import BreadCrumb from '../../components/common-breadcrumb-section/BreadCrumb';
import Stack from '@mui/material/Stack';
import AddOutlined from '@mui/icons-material/AddOutlined';
import IconBtn from '../../components/common-icon-btn/IconBtn';
import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined';
import CommonSelect from '../../components/common-select/CommonSelect';
import DialogLayoutWrapper from '../../components/Dialog-layout-wrapper/DialogLayoutWrapper';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import BtnIconContent from '../../components/common-icon-content-btn/BtnIconContent';
import '../TeamContainer/teams.css'
import AddTeamMemberTestDialog from '../../components/add-team-member-test-dialog/AddTeamMemberTestDialog';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { selectedGridDataChecked } from '../../features/teamManagement/teamManagementSlice'
import BackDropLoader from '../../components/common-backdrop-loader/BackdropLoader';
import { teamsMemServer } from '../../features/teams/teamsSlice';
import { removeTeamMemServer } from '../../features/teams/teamsSlice';
import { setTeamMemId, setPersonId, setForeName, setEmail, setSurName } from '../../features/teams/teamsSlice';
import SnackBar from '../../components/snackBar-layout-wrapper/snackBar-layout-wrapper';
import EditTeamMemberDialog from '../../components/edit-team-member-dialog/EditTeamMemberDialog';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router';

const Teams = (params) => {
    const { teamsList } = useSelector((store) => store.dashboard)
    const {tableCheckedId} = useSelector((store) => store.teamManageMent)
    const {usertoken} = useSelector((store) => store.login)
    const {teamMemList, isLoadingTeamMem, team_id, messageCondition, message, messageType} = useSelector((store) => store.teams)
    console.log('taketeamId',team_id)
    const [gridDataChecked, setGridDataChecked] = useState([])
    const [showIssueSurvey, setShowIssueSurvey] = useState(false)
    const [open, setOpen] = useState(false)
    const [editTeamMemOpen, setEditTeamMemOpen] = useState(false)
    const [tableRows, setTableRows] = useState([]);
    const location = useLocation();
    const dispatch = useDispatch();
    const [isOpenTeamToggle,setIsOpenTeamToggle] = useState(true);
    const [isOpenInfoIcon,setIsOpenInfoIcon] = useState(false);
    const [filterArray, setFilterArray] = useState([])
    const navigate = useNavigate()

    console.log("team list",teamsList)
    useEffect(() => {
        console.log("gridData checked info",gridDataChecked)
        if (gridDataChecked.length != 0) {
            setShowIssueSurvey(true)
        }
        else {
            setShowIssueSurvey(false)
        }
    }, [gridDataChecked])
    // useEffect(() => {
    //     if (teamsList.length != 0 && teamsList[0].Team_Id) {
    //         dispatch(setTeamMemId(teamsList[0].Team_Id))
    //     }
    // }, [teamsList]);
    
    useEffect(() => {
        if (teamsList.length != 0 && teamsList[0].Team_Id) {
            if(location.state.Team_Id==''){
            dispatch(setTeamMemId(teamsList[0].Team_Id))
        }else{
            console.log('elseCase')
            dispatch(setTeamMemId(location.state.Team_Id))
        }
        }
    }, [teamsList]);

    useEffect(() => {
        console.log('initialCheck','Teams')
        if(team_id != ''){
            dispatch(teamsMemServer({ usertoken, team_id })).then((res) => {
                console.log("res from team mem server",teamMemList)
            })
        }
    },[team_id])


    const addEditTeam = () => {
        window.alert("add edit team triggered")
    }

    const columns = [
        // {
        //     field: 'id', headerName: 'id', minWidth: 100,
        //     flex: 1,
        //     hidden: true
        // },
        // {
        //     field: 'personId', headerName: 'Person Id', minWidth: 100,
        //     flex: 1,
        //     hidden: true
        // },
        // {
        //     field: 'forename',
        //     headerName: 'First Name',
        //     minWidth: 150,
        //     flex: 1,
        //     editable: false,
        // },
        // {
        //     field: 'surname',
        //     headerName: 'Sure Name',
        //     minWidth: 150,
        //     flex: 1,
        //     editable: false,
        // },
        {
            field: 'name',
            headerName: 'Name',
            // minWidth: 150,
            flex: 1,
            editable: false,
        },
        {
            field: 'emailAddress',
            headerName: 'Email',
            // minWidth: 250,
            flex: 2,
            editable: false,
        },
        // {
        //     field : 'teamName',
        //     headerName : 'Team Name',
        //     minWidth: 250,
        //     flex: 2,
        //     editable: false,
        // },
        // {
        //     field : 'teamId',
        //     headerName : 'Team Id',
        //     flex: 2,
        //     editable: false,
        //     hidden: true
        // },
        // {
        //     field: 'dateSent',
        //     headerName: 'Date Sent',
        //     minWidth: 150,
        //     flex: 2,
        //     editable: false,
        // },
        // {
        //     field: 'dateSubmitted',
        //     headerName: 'Date Submitted',
        //     minWidth: 150,
        //     flex: 2,
        //     editable: false,
        // },
        // {
        //     field:'status',
        //     headerName: 'Status',
        //     minWidth: 150,
        //     flex: 2,
        //     editable: false,
        //     renderCell : (params) => {
        //         console.log("params",params)
        //         return(
        //             <div style = {{flex : "auto"}}>
        //                 <BtnContent  teamStatus={true} color = {params.row.dateSubmitted != '' ? 'success' : 'warning'}>{params.row.dateSubmitted != '' ? 'completed' : 'pending' }</BtnContent>
        //             </div>
        //         )
        //     }
        // },
        {
            field:'action',
            headerName: '',
            // minWidth: 150,
            flex: 'auto',
            editable: false,
            renderCell : (params) => {
                console.log("params",params)
                return(
                    <div>
                        {/* <span id = {"pdf"+params.row.id} className = "pd-r-15px cursor-pointer" onClick = {() => window.alert("share")}>
                            <PictureAsPdfOutlinedIcon/>
                        </span> */}
                        <span id = {"edit"+params.row.id} className = "pd-r-15px cursor-pointer" onClick = {() => {
                            dispatch(setPersonId(params.row.personId))
                            dispatch(setForeName(params.row.forename))
                            dispatch(setSurName(params.row.surname))
                            dispatch(setEmail(params.row.emailAddress))
                            setEditTeamMemOpen(true)
                        }}>
                            <CreateOutlinedIcon/>
                        </span>
                        <span id = {"delete"+params.row.id} className = "pd-r-15px cursor-pointer" onClick = {() => {
                            let personId = params.row.personId
                            let team_id = params.row.teamId
                            console.log("params.row.personId",params.row.personId)
                            dispatch(removeTeamMemServer({usertoken,team_id,personId})).then((res) => {
                                dispatch(teamsMemServer({usertoken,team_id})).then((res) => {
                                })
                            })
                            }}
                            >
                            <DeleteOutlineOutlinedIcon/>
                        </span>
                    </div>
                )
            }
        }

    ];

    const addEditTeams = () => {
        setOpen(!open)
    }

    const removeTeam = () => {
        gridDataChecked.map(x => {
            let personId = teamMemList[x].personId
            console.log("personId from multiple remove team",team_id)
            dispatch(removeTeamMemServer({ usertoken, team_id, personId })).then((res) => {
                dispatch(teamsMemServer({usertoken, team_id})).then((res) => {
                })
            }
            ).catch(err => {
                window.alert(err)
            })
        })
        let checkBoxItems = document.getElementsByClassName('PrivateSwitchBase-input');
        Array.prototype.forEach.call(checkBoxItems, function(box, item){
            if(box.checked){
                box.click()
            }
            box.checked = false
        })
    }
    const rowClick = (params, event) => {
        // window.alert(params, event)
    }

    const checkBoxSelected = (id) => {
        console.log('checkid',id)
        setGridDataChecked(gridDataChecked => id)
    }

    const clickBtnNameOne = () => {
        window.alert("triggered 1")
    }

    const clickBtnNameTwo = () => {
        window.alert("triggered 2")
    }

    const addNewPerson = () => {
        window.alert("add new person")
    }
    const handlCloseiconview = (value) =>{
        if(value == "closeicon"){
            setIsOpenTeamToggle(false);
            setIsOpenInfoIcon(true);
        }
        else if(value == "infoicon"){
            setIsOpenTeamToggle(true);
            setIsOpenInfoIcon(false);
        }
    }

    const searchTeamMem = (e) => {
        let searchKey = e.target.value
        if(searchKey == ''){
            setFilterArray([])
        }
        else{
            let arrayFiltered = teamMemList.filter((v,index) => {
                if(v['forename'].toLowerCase().includes(searchKey) || v['surname'].toLocaleLowerCase().includes(searchKey) || v['emailAddress'].toLocaleLowerCase().includes(searchKey)){
                    return v
                }
            })
            setFilterArray(arrayFiltered)
        }
    }


    // if(!isLoadingdrpdwnMem){
    return (
        <div>
            <Container maxWidth="xl">
                <Grid
                    justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }}
                    alignItems="center"
                    rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    className="text-center">
                    <Stack
                        direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
                        spacing={2}
                        justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }}
                        alignItems="start" mb={1}>
                        <BreadCrumb breadcrumbTwo="Team Management" breadCrumbName="Team Members" />
                    </Stack>
                </Grid>
                <Divider/>
                {teamMemList.length == 0 && <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <MessageIndication icon={<ErrorOutlineIcon className="message-icn-size" />} message={"Oh uh, You don’t have any team members, Add your team members"} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6} mb={3} sx={{ display: "flex", justifyContent: "center" }}>
                        <IconContentCard cursor = {true} clrwhite={"#fff"} bgColor={"#1bc5bd"} click={addEditTeams} icon={<GroupAddOutlinedIcon className="message-icn-size" />} cardTitle={"Add/Edit Team Members"} description={"Add team member to an existing Team"} />
                    </Grid>
                </Grid>}
                <Grid
                        container
                        // justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'center', xl: 'center' }}
                        alignItems="center"
                        // rowSpacing={3} 
                        // columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                        columnSpacing={1}
                        className="">    
                    <Grid style = {{display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'baseline'}} className="openvalue" item xs={12} sm={12} md={12} lg={teamMemList.length == 0 ? "coll" : (isOpenInfoIcon == true ? 'col': '7')} xl={teamMemList.length == 0 ? 'col' : (isOpenInfoIcon == true ? 'col':'8')}>
                        <Grid style = {{display : 'flex', flexDirection : 'row', alignItems : 'baseline'}} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }} container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="space-between" alignItems="start">
                            <Grid item xs={12} sm={12} md={10} lg={12} xl={12} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "start" }}>
                                <div>
                                    <h1 className="header_title">{teamMemList.length != 0 && teamMemList[0].teamName} - Members</h1>
                                </div>
                                {/* <Grid item sm={{display:"none"}} sm={{display:"none"}} lg={{display: "block"}} xl={{display: "block"}}> 
                                <div className='pd-l-10px' style={{ minWidth: "220px" }}>
                                    <CommonSelect list={teamsList} label={team_id.toString()} />
                                </div>
                                </Grid> */}
                               
                                {/* {isOpenInfoIcon == true && <p className="teammemebertoggleinfo" onClick={() => handlCloseiconview('infoicon')}><InfoOutlinedIcon /></p>} */}
                            </Grid>
                            <Grid  item xs={12} sm={12} md={5} lg={5} xl={5} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "start" , display:"block"}} sm={{display:"block"}} lg={{display: "none"}} xl={{display: "none"}}>
                            <div className="teammemeberdd" >
                                    <CommonSelect list={teamsList} label={team_id.toString()} />
                                </div>
                            </Grid>
                            {/* <Grid item xl = {2} sx = {{display : 'flex', justifyContent : 'flex-end'}}> */}
                                    {/* <span><TextField size = "small" id="outlined-basic" autoComplete='off' placeholder='Search Team member' onChange= {(e) => searchTeamMem(e)} variant="outlined" /></span> */}
                            {/* </Grid> */}
                            <Grid item xs={12} sm={12} md={2} lg xl sx={{ display: "flex", justifyContent: "flex-end", alignItems : 'flex-end', marginBottom: '10px',marginTop:"0px" }} className="searchtm">
                                {/* <span className='pd-r-10px'>{showIssueSurvey && <BtnContent><p className = "mar-0">Issue Survey</p></BtnContent>}</span> */}
                                <span className='pd-r-10px'><TextField size = "small" id="outlined-basic" autoComplete='off' placeholder='Search Team Member' onChange= {(e) => searchTeamMem(e)} variant="outlined" className="searctm"/></span>
                                {gridDataChecked.length > 1 && <span className='pd-r-10px' onClick = {() => {
                                    gridDataChecked.map(x => {
                                        let personId = teamMemList[x].personId
                                        dispatch(removeTeamMemServer({ usertoken, team_id, personId })).then((res) => {
                                            dispatch(teamsMemServer({usertoken, team_id})).then((res) => {
                                            })
                                        }
                                        ).catch(err => {
                                            window.alert(err)
                                        })
                                    })
                                    let checkBoxItems = document.getElementsByClassName('PrivateSwitchBase-input');
                                    Array.prototype.forEach.call(checkBoxItems, function(box, item){
                                        if(box.checked){
                                            box.click()
                                        }
                                        box.checked = false
                                    })
                                }}>{showIssueSurvey && <IconBtn icon={<DeleteOutlineOutlinedIcon />} />}</span>}
                                <span><IconBtn click={addEditTeams} icon={<AddOutlined />} /></span>
                                {/* {teamMemList.TeamStatus.length != 0 && <IconBtn click={addEditTeams} icon={<AddOutlined />} />} */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {console.log('commonDataGrid',teamMemList)}
                            <CommonDataGrid columns={columns} rows={filterArray.length == 0 ? teamMemList : filterArray} rowClick={rowClick} checkBoxSelected={checkBoxSelected} />
                        </Grid>
                    </Grid>
                   {teamMemList.length != 0 && <Grid item display={{ xs: "none",sm:"none", md:"block", lg: "block" }}>
                            <span>{isOpenTeamToggle == true &&<IconBtn   toggle={true} click={() => handlCloseiconview('closeicon')} icon={<ArrowForwardIosIcon />} />}</span>
                            <span >{isOpenInfoIcon == true &&<IconBtn   toggle={true} click={() => handlCloseiconview('infoicon')} icon={<ArrowBackIosIcon className="backwardicon" />} />}</span>
                        </Grid>}
                    {teamMemList.length != 0 && isOpenTeamToggle == true && <Grid className="togglebtn" item xs={12} sm={12} md={12} lg xl mt={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }} display={{ xs: "none",sm:"none", md:"block", lg: "block" }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={2} >
                            <IconContentCard cursor = {true} click={() => {
                                dispatch(addRecipientFromStatus([]))
                                navigate('/issuesurvey',{state: { Team_Id: team_id },replace : false})}} clrwhite={"#fff"} bgColor={'#ffa800'} icon={<SendIcon className="message-icn-size" />} panelcolor={true} cardTitle={"Survey & Report"} description={"Provide a unique link for individual to complete the 48-question survey."} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={2} >
                            <IconContentCard cursor = {true} clrwhite={"#fff"} bgColor={"#1bc5bd"} click={addEditTeams} icon={<GroupAddOutlinedIcon className="message-icn-size" />} cardTitle={"Add/Edit Team Members"} description={"Add team member to an existing Team"} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <IconContentCard cursor = {true} clrwhite={"#fff"} bgColor={"#ea6060"} click={removeTeam} icon={<GroupRemoveOutlinedIcon className="message-icn-size" />} cardTitle={"Remove Team Member"} description={"Can Remove an existing person or someone new "} />
                        </Grid>
                    </Grid>}
                </Grid>
            </Container>
            <AddTeamMemberTestDialog teamId={team_id} open={open} close={() => {
                setOpen(false)
                }} />
            <EditTeamMemberDialog teamId={team_id} open={editTeamMemOpen} close={() => {
                setEditTeamMemOpen(false)
                }}/>
            <BackDropLoader loaderBool = {isLoadingTeamMem}/>
            <SnackBar condition={messageCondition} message={message} type={messageType} />
        </div>
    )
}

export default Teams