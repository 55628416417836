import React,{useState, useEffect} from "react";
import '../common-layout-wrapper/layout-wrapper.css';
import Header from "../../components/common-header/Header";
import { addRecipientFromStatus} from '../../features/issueSurvey/issueSurveySlice';

import Navbar from "../common-navbar/Navbar";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Container from '@mui/material/Container';
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import { userInformation } from "../../features/login/loginSlice";
import { useSelector, useDispatch } from "react-redux";
import SnackBar from "../snackBar-layout-wrapper/snackBar-layout-wrapper";

const LayoutWrapper = (props) => {
    const {  team_id} = useSelector((store) => store.teams)

    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const [layoutActivation, setLayoutActivation] = useState(false)
    const [alternateHeader, setAlternateHeader] = useState(false)
    const {accInforUserName, lastLoggedOn, message, Type, messageCondition} = useSelector((store) => store.login)

    useEffect(() => {
      if(location.pathname == "/" || location.pathname == '/surveyForm/' || location.pathname == '/surveyQuestions' || location.pathname == '/surveyCompleted' || location.pathname == '/pdf' || location.pathname=='/forgotPsd'){
        setLayoutActivation(true)
      }
      else{
        setLayoutActivation(false)
      }
      if(location.pathname == '/surveyForm/' || location.pathname == '/surveyQuestions' || location.pathname == '/surveyCompleted'){
        setAlternateHeader(true)
      }
      else{
        setAlternateHeader(false)
      }
    },[location])
    const handleDrawerOpen = () => {
    setOpen(true);
    };

    const handleDrawerClose = () => {
    setOpen(false);
    };

    const navigateOne = () => {
        navigate('dashboard',{replace : false})
        handleDrawerClose()
        // window.history.replaceState(null, "", "/")
    }

    const navigateTwo = () => {
        navigate('teamManagement',{replace : false})
        handleDrawerClose()
        // window.history.replaceState(null, "", "/")
    }

    const navigateTeamMembers = () => {
        navigate('/teams',{state : {Team_Id : team_id}})
        handleDrawerClose()
    }

    const navigatethree = () => {
        // navigate('issuesurvey',{replace : false})
        dispatch(addRecipientFromStatus([]))
        navigate('issuesurvey',{state: { Team_Id: team_id },replace : false})
        handleDrawerClose()
        // window.history.replaceState(null, "", "/")
    }

    const navigationFive = () => {
        navigate('settings',{replace : false})
        handleDrawerClose()
        // window.history.replaceState(null, "", "/")
        // window.history.replaceState(null, "", "/issuesurvey")

    }
    const navigatefour = () => {
        navigate('status',{replace : false})
        handleDrawerClose()
        // window.history.replaceState(null, "", "/")
    }

    const navigationSix = () => {
        window.open('https://academy.ourteamsynergy.com/helpgettingstarted','_blank')
    }

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      }));
    
    let Title = () => {
        return(
                <div className="line-height-normal">
                  <p className="mar-0 text-uppercase">team</p>
                  <p className="mar-0 pd-l-2px font-size-12px">workology</p>
                </div>
        )
    }

    let UserInformation = () => {
        return (
            <>
                <p className='text-capitalize mar-0 text-right bg-primary'>Welcome {accInforUserName}</p>
                <p className='text-capitalize mar-0'>Last Logged On - {lastLoggedOn}</p>
            </>
        )
    }
      
    return(
        <Box sx={{ display: 'flex' }}>
            {(!layoutActivation && !alternateHeader) && <Header notificationTrigger = {() => {
                navigatefour()
            }} showMenu = {true} draweOpen = {handleDrawerOpen} title = {<Title/>} userInformation = {<UserInformation/>} notificationIcon = {<NotificationsIcon/>} personIcon = {<PersonIcon/>} vertIcon = {<MoreVertIcon/>}  open = {open}/>}
            {alternateHeader && <Header showMenu = {false} draweOpen = {handleDrawerOpen} title = {<Title/>}  open = {open}/>}
            {!layoutActivation && <Navbar drawerClose = {handleDrawerClose} navigateOne = {navigateOne} navigateTwo = {navigateTwo} navigateTeamMembers = {navigateTeamMembers} navigateThree = {navigatethree} navigateFour={navigatefour} navigationFive = {navigationFive} navigationSix = {navigationSix} open = {open}/>}
            <Box component="main" sx={!layoutActivation ? { flexGrow: 1, p: 3 } : {flexGrow: 0, p: 0}} className="w-100">       
                {!layoutActivation && <DrawerHeader />}   
                <div maxWidth="xl" className = {!layoutActivation ? 'p-0' : 'css-19r6kue-MuiContainer-root p-0'}>      
                {props.children}
                <SnackBar condition={messageCondition} message={message} type={Type} />
                </div>
            </Box>
        </Box>
    )
}

export default LayoutWrapper