import React from 'react';
import { Grid, Paper, Typography } from '@mui/material/';
import TextField from '@mui/material/TextField';
import '../loginContainer/LoginContainer.css';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import SnackBar from '../../components/snackBar-layout-wrapper/snackBar-layout-wrapper'
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import { loginFunction, changeUserName, changePassword, userInformation, notificationInformation } from '../../features/login/loginSlice';
import teamlogo from '../../assets/teamlogo.jpg';
import loginimage from '../../assets/loginimg.png';
import loginimageone from '../../assets/two.jpg';
import loginimagetwo from '../../assets/completed-task.png';
import loginimagethree from '../../assets/online-survey.png';
import loginimagefour from '../../assets/checklist.png';
import youtubeImg from '../../assets/youtube.png'
import { styled } from '@mui/material/styles';

export default function LoginContainer() {

    const [isOpenForgotView, setIsOpenForgotView] = React.useState(true);



    const navigate = useNavigate()
    const navigateToDashboard = () => {
        navigate('dashboard', { replace: false })
        // window.history.replaceState(null, "", "/")
    }
    // const classes = useStyles();
    const dispatch = useDispatch();
    const { userName, password, isLoading, message, Type, messageCondition } = useSelector((store) => store.login);
    console.log('loginprops', useSelector((store) => store.login));
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#3699ff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: 'auto',
        boxShadow: 'none'
    }));

    return (
        <div className="" component="main">
            {/* <SnackBar condition={messageCondition} message={message} type={Type} /> */}
            <Grid container spacing={0}>
                <Grid item xs={5} md={7} display={{ xs: "none", sm: "none", md: "block", lg: "block" }} className="leftside">
                    <Item>
                        <div className='pt-23'>
                            <h2 className='lg-h2-clr'>Welcome to Our Team Synergy</h2>
                            {/* <img src={loginimage} alt={loginimage} className="login m-t--110px" width="80%" /> */}
                            <div>
                                <iframe width="100%" height="420" style={{borderRadius: '10px'}} src="https://www.youtube.com/embed/2cVcXPUO1ys?si=I_D0p8jubEmjFayv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                            <p className="text txt-start p-rl-25">A team performance measurement and reporting tool, aim at providing a tangible understanding of what derives team effectiveness. Please save this link to your favourites. When you login, please go to the top right 'person' icon and click the drop down. You will see a menu item called "Help". Click the link and it will open a resource page to help you navigate the platform. Always have both the feedback link and the platform link open at the same time when you are using Our Team Synergy
                            </p>
                            {/* <div className = 'd-flex justify-content-center p-rl-25 m-t-70px'>
                                <a href = "https://youtu.be/eRjp1sTxlAg" className='guide-link' target = '_blank'>Watch Quick Guide</a>
                            </div> */}
                            {/* <div className="footerpart">
                                <div className="footvalue">
                                    <span className="">www.teamworkology.com</span>
                                    <span className="">Contact Us</span>
                                    <span className="">Introduction</span>
                                </div>
                            </div> */}
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={12} md={5} textAlign={{ xs: 'center', sm: 'center', md: 'center', lg: 'center', xl: 'center' }} sx={{ margin: "auto" }}>
                    {/* <div className='d-flex justify-content-center align-items-center p-rl-25'>
                            <span className = "pointer"><img src = {youtubeImg} onClick={() => window.open('https://youtu.be/eRjp1sTxlAg','_blank')} alt = "Logo" className='youtube-icn'/></span><a href="https://youtu.be/eRjp1sTxlAg" className='guide-link' target='_blank'>Watch Quick Guide</a>
                    </div> */}
                    <div className="loginusernamecontainer">
                        <div className="logo">
                            <span><img src={teamlogo} alt="logo" /></span>
                        </div>
                        <div className="pb-10">
                            <TextField
                                size='small'
                                className="width-1"
                                required
                                id="filled-required"
                                label="User Name"
                                defaultValue={userName}
                                fullWidth
                                onChange={(e) => dispatch(changeUserName(e.target.value))}

                            />
                        </div>
                        <div className="pb-10">
                            <TextField
                                size='small'
                                className="width-1"
                                required
                                type="password"
                                id="filled-required"
                                label="Password"
                                defaultValue={password}
                                fullWidth
                                onChange={(e) => dispatch(changePassword(e.target.value))}
                            />
                        </div>
                        <div className="submit-cont">
                            <p> <a href="#" onClick={() => navigate('/forgotPsd')}>Forgot Password </a></p>
                            <Button onClick={() => dispatch(loginFunction({ userName, password })).then((res) => {
                                console.log('res', res)
                                if (res.payload.hasOwnProperty('Token')) {
                                    dispatch(userInformation(res.payload.Token)).then((res) => {
                                        navigateToDashboard()
                                    })
                                    dispatch(notificationInformation(res.payload.Token)).then((res) => {
                                        // setNotificationCount(res)
                                        console.log('res for notification count', res)
                                    })
                                }
                                else {

                                }
                            })} variant="contained" className="float-right">
                                {isLoading ? <CircularProgress color="inherit" /> : 'Submit'}
                            </Button>
                        </div>
                    </div>

                </Grid>

            </Grid>

        </div>
    )
}

